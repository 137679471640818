import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "../chain/config";
import { JsonRpcProvider } from "@ethersproject/providers";
import {
  CasperClient,
  CasperServiceByJsonRPC,
  CLI32,
  CLPublicKey,
  CLString,
  CLValue,
  DeployUtil,
  RuntimeArgs,
  CLStringBytesParser,
} from "casper-js-sdk";
import { EChainId, EChainType, SignerOrProvider } from "@/types/chain";
import { useWalletStore } from "@/store/wallet";
import { Chain, EvmChain } from "./chains";
import { EVMToken } from "./token/evm";
import { tokenAddress } from "./getters";
import { notify } from "@kyvg/vue3-notification";
import { BaseCasper } from "./casper";

const casperService = new CasperServiceByJsonRPC(import.meta.env.VITE_CSPR_RPC);

const web3Modal = new Web3Modal({
  theme: "dark",
  cacheProvider: true,
  providerOptions,
});

export async function resetApp() {
  const walletStore = useWalletStore();
  walletStore.setConnected(false);
  walletStore.setSigner(null);
  web3Modal.clearCachedProvider();
}

export async function subscribeProvider(provider: JsonRpcProvider) {
  if (!provider.on) {
    return;
  }
  provider.on("close", () => resetApp());
  provider.on("accountsChanged", async (accounts: any[]) => {
    // walletAddress.value = accounts[0];
    // await setBthBalance();
  });
  // provider.on("chainChanged", async (_chainId: number) => {
  // await setBthBalance();
  // });
  provider.on("disconnect", () => {
    resetApp();
  });
}

export async function onConnect(chainId: EChainId): Promise<void> {
  const walletStore = useWalletStore();
  const previousChainId = walletStore.chainId;
  walletStore.setBalance(0);
  const chain = new Chain(chainId);
  try {
    if (chain.type == EChainType.EVM) {
      return await handleEvmConnect(chainId);
    } else if (chain.type == EChainType.CASPER) {
      return await handleCasperConnect();
    }
    walletStore.setShowChainModal(false);
    throw new Error(`Unknown chain type`);
  } catch (e) {
    notify({
      type: "error",
      text: e.message,
    });
    if (previousChainId && walletStore.connected) {
      return await onConnect(previousChainId);
    }
  }
}

async function handleCasperConnect() {
  const walletStore = useWalletStore();
  walletStore.setChainId(EChainId.CSPR);
  const CasperWalletProvider = window.CasperWalletProvider;
  let provider;
  try {
    provider = CasperWalletProvider();
  } catch (e) {
    notify({
      text: "Install Casper Wallet first! <a href='https://www.casperwallet.io/' target='_blank'>Download here</a>",
      type: "warning",
      duration: 20_000,
    });
    throw new Error();
  }
  const connected = await provider.requestConnection();
  if (connected) {
    const publicKey = await provider.getActivePublicKey();
    walletStore.setWallet(publicKey);
    try {
      const accountHash = BaseCasper.accountHashFromPublicKey(publicKey);
      const balance = await BaseCasper.balanceOf(accountHash);
      walletStore.setBalance(+balance);
    } catch (e) {
      walletStore.setBalance(0);
    }
    walletStore.setConnected(true);
  }
}

async function handleEvmConnect(chainId: EChainId) {
  const walletStore = useWalletStore();
  const chain = new EvmChain(chainId);

  const modalProvider = await web3Modal.connect();
  const _provider = new ethers.providers.Web3Provider(modalProvider);
  const _signer = _provider.getSigner();
  const _network = await _provider.getNetwork();

  // if connected network does not match requested network
  if (_network.chainId !== chain.chainId) {
    const hexChainId = `0x${chain.chainId.toString(16)}`;
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: hexChainId }],
      });
    } catch {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: hexChainId,
            chainName: chain.name,
            rpcUrls: [chain.rpcUrl],
            blockExplorerUrls: [chain.explorerUrl],
            nativeCurrency: chain.currency,
          },
        ],
      });
    }
    return onConnect(chainId);
  }
  walletStore.setChainId(chainId);

  const token = new EVMToken(tokenAddress(), new SignerOrProvider(_signer));
  const balance = await token.getBalance();

  const address = await _signer.getAddress();
  walletStore.setBalance(balance);
  walletStore.setSigner(_signer);
  walletStore.setWallet(address);
  walletStore.setConnected(true);
  walletStore.setShowChainModal(false);
  await subscribeProvider(_provider);
}
