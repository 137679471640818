<template>
  <div class="overlay" v-if="visible">
    <div class="container">
      <div class="close-container">
        <img class="cross" @click="hide()" src="@/assets/svg/cross-white.svg" />
      </div>
      <!-- Of vanaf hier ModalContent of onder de text -->
      <!-- Bijvoorbeeld dit allemaal vervangen met een slot -->
      <!-- Of alleen de buttons vervange met slot -->
      <div class="circle" v-if="!hideCircle">
        <img class="camera" :src="icon" :style="`width:${iconWidth}`" />
      </div>
      <!-- title kan een slot zijn? vslot:title -->
      <span class="title" v-html="title"></span>
      <!-- vslot:text -->
      <p class="text" v-html="text"></p>
      <!-- Voorbeeld slot in een simpele vorm -->

      <!-- Dit https://stackoverflow.com/questions/48267129/get-template-as-string-in-vue  -->
      <!-- dit is een probeersel zodat je de template als string ophaalt van een component -->
      <!-- en dat mee kan geven aan showmodal. -->
      <div v-if="form" class="form">
        {{ select }}
        <label>
          Address
          <input v-model="address" placeholder="0x.." />
          <!-- v-modal="address" -->
        </label>
        <label>
          Token ID
          <input v-model="tokenId" type="number" placeholder="123" />
          <!-- v-modal="tokenId" -->
        </label>
        <button class="submit" @click="onSubmit">
          Upload
          <!-- <img
            src="@/assets/book.png"
            width="50"
            style="position: absolute; filter: invert(1); left: 5px"
          /> -->
          <div class="fade"></div>
        </button>
        <button class="back" @click="form = false">Change network</button>
      </div>
      <div v-else-if="select" class="buttons">
        <ModalWideButton
          v-for="select in selectFrom"
          :key="select"
          @click="choose(select)"
          >{{ select }}
        </ModalWideButton>
      </div>
      <!-- Button confirm and cancel of select 1 button met dezelfde functionaliteit -->
      <div class="buttons btn-abs" v-else>
        <button v-if="displayLink" class="done">
          <a
            rel="external"
            target="_blank"
            style="color: white; text-decoration: none"
            :href="link"
            >{{ linkLabel }}</a
          >
        </button>
        <div v-else style="display: flex; justify-content: space-between">
          <button @click="confirm" class="done">
            {{ rightButtonText }}
          </button>
          <button @click="cancel">
            {{ leftButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalWideButton from "@/components/plugin/ModalWideButton.vue";
const initialState = () => {
  return {
    visible: false,
    icon: new URL("../../assets/svg/check3.svg", import.meta.url),
    iconWidth: "100px",
    hideCircle: false,
    title:
      "Nice <span class='blue'>Modal</span> <span class='green'>NFT</span>", // Rendered as HTML due to v-html directive
    text: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",

    // 1
    leftButtonText: "close",
    rightButtonText: "done",
    leftButtonFunc: () => {},
    rightButtonFunc: () => {},
    callBackFunc: "",
    //2
    select: false,
    selectFrom: ["BSC", "ETH", "MATIC"],

    //3
    form: false,
    inputAndLabels: [],
    address: "",
    tokenId: "",

    displayLink: false,
    link: null,
    linkLabel: null,
    allowedLinks: ["https://frontend.bithotel.io"],
  };
};

export default {
  name: "Modal",
  props: ["params"],
  components: {
    ModalWideButton,
  },
  data: () => {
    return initialState();
  },
  methods: {
    init(params) {
      // setting title and text
      this.title = params?.title || this.title;
      this.text = params?.text || this.text;
      this.leftButtonText = params?.leftButtonText || this.leftButtonText;
      this.rightButtonText = params?.rightButtonText || this.rightButtonText;
      this.leftButtonFunc = params?.leftButtonFunc || this.leftButtonFunc;
      this.rightButtonFunc = params?.rightButtonFunc || this.rightButtonFunc;
      this.callBackFunc = params?.callBackFunc || this.callBackFunc;
      this.icon = params?.icon || this.icon;
      this.iconWidth = params?.iconWidth || this.iconWidth;
      this.select = params?.select || this.select;
      this.hideCircle = params?.hideCircle || this.hideCircle;
      this.selectFrom = params?.selectFrom || this.selectFrom;
      this.form = false;
      this.displayLink = params?.displayLink || this.displayLink;
      this.link = params?.link || this.link;
      this.linkLabel = params?.linkLabel || this.linkLabel;
    },
    show() {
      this.visible = true;
    },
    async confirm() {
      if (this.callBackFunc && typeof this.callBackFunc === "function") {
        await this.callBackFunc(true);
      }
      this.visible = false;
      this.rightButtonFunc();
    },
    async hide() {
      if (this.callBackFunc && typeof this.callBackFunc === "function") {
        await this.callBackFunc(false);
      }
      this.visible = false;
      this.resetState();
    },
    async cancel() {
      this.leftButtonFunc();
      this.hide();
    },
    choose(selected) {
      this.form = true;
      this.select = selected;
    },
    onSubmit() {
      // TODO: validation
      this.emitter.emit("submitModalForm", {
        chain: this.select,
        address: this.address,
        tokenId: this.tokenId,
      });
    },
    resetState() {
      Object.assign(this.$data, initialState());
    },
    validateLink(link) {
      if (this.allowedLinks.includes(link)) {
        return link;
      } else {
        console.log("Link not allowed");
        return false;
      }
    },
  },
  beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    this.emitter.on("showGlobalModal", (params) => {
      const { visible } = params;
      if (!visible) {
        this.hide();
      } else {
        this.init(params);
        this.show();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
$base-color: #fff;
$gradient: linear-gradient(
  87.46deg,
  #3cc4d7 -3.04%,
  #6df3d3 92.88%,
  #61fea0 172.86%
);

.overlay {
  background: rgba(31, 31, 31, 0.335);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5555;
  top: 0;
  left: 0;

  display: flex;
  align-content: center;
  justify-content: center;

  .container {
    background: linear-gradient(90deg, #2e2e3c 55.71%, #23232f 73.93%);
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // max-height: 530px;
    // height: auto;
    height: fit-content;
    margin-top: 165px;
    padding: 30px 40px 30px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    max-width: 500px;
    .circle {
      background: $gradient;
      $circle-size: 200px;
      border-radius: $circle-size;
      width: $circle-size;
      height: $circle-size;
      min-height: $circle-size;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

      .icon {
        color: $base-color;
      }

      .camera {
        width: 100px;
      }
    }

    .circle:hover {
      box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    }

    .title {
      margin-top: 30px;
      font-size: 40px;
      font-weight: 900;
      color: #fff;
    }

    .title ::v-deep(.green) {
      color: #63fca3;
      text-shadow: 0px 4px 25px rgba(113, 218, 180, 0.5);
    }

    .title ::v-deep(.blue) {
      color: #3cc4d7;
      text-shadow: 0px 4px 25px #6df3d454;
    }

    p {
      width: 300px;
      margin: 40px 0px;
      font-weight: 300px;
      color: #ffff;
    }

    .form {
      width: 100%;
      float: left;
      color: #fff;

      label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: auto;
        color: grey;
        font-size: 18px;
        margin-bottom: 20px;

        input {
          margin-top: 5px;
          font-size: 18px;
          color: #fff;
          background: black;
          border: grey 1px solid;
          border-radius: 7px;
          padding: 10px 10px 5px 10px;
          width: 91%;
        }
      }

      button {
        cursor: pointer;
        font-weight: 900;
      }

      .submit {
        margin: auto;
        display: block;
        width: 80%;
        position: relative;
        padding: 10px 10px 5px 10px;
        color: #fff;
        background: linear-gradient(
            87.46deg,
            #3cc4d7 -3.04%,
            #6df3d3 92.88%,
            #61fea0 172.86%
          ),
          linear-gradient(87.46deg, #3cc4d7 0%, #6df3d3 54.53%, #61fea0 100%);

        border: 0;
        border-radius: 7px;
        font-size: 20px;

        .fade {
          height: 45px;
          width: 102%;
          position: absolute;
          top: 0px;
          left: 0;
          background: linear-gradient(
            7.72deg,
            #3cc4d7 -44.04%,
            #6df3d3 12.94%,
            rgba(112, 186, 255, 0) 60.45%
          );
          border-radius: 10px;
        }
      }

      .back {
        margin-top: 20px;
        background: transparent;
        border: 0;
        color: grey;
      }
    }

    .buttons {
      .btn-abs {
        position: absolute;
        bottom: 30px;
      }

      button {
        margin: 0px 10px;
        position: block;
        float: right;
        padding: 10px 20px;
        background: $base-color;
        // color: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        padding: 15px 20px 15px 25px;
        text-align: center;
        min-width: 120px;
        height: 35px;
        cursor: pointer;
        background: $gradient;
        border-radius: 5px;
        z-index: 5;
        text-align: center;
        border: 0;
        color: #fff;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 400;
        cursor: poiner;
        z-index: 555;
        // margin-bottom: 30px;
      }

      button.dark {
        width: 90%;
        background: #23232f;
        border: rgb(66, 66, 66) 1px solid;
        padding: 20px 10px;
        font-weight: 900;
        margin-bottom: 5px;
      }

      button.done {
        background: linear-gradient(
          264.06deg,
          #c63b3b -68.99%,
          #d174e0 40.01%,
          #70baff 130.88%
        );
      }

      button:hover {
        background: transparent;
        border: 1px solid #fff;
        color: #fff;
      }

      button:hover {
        cursor: pointer;
        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
          rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      }

      button.dark:hover {
        border: 1px solid #fff;
      }
    }
  }

  .close-container {
    margin: auto;
    color: #fff;
    position: absolute;
    top: 20px;
    right: 20px;

    .close {
      z-index: 5555;
      // float: right;
    }

    .close:hover {
      cursor: pointer;
      // box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
      //   rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      //   rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }

    .cross {
      width: 30px;
      margin-left: 270px;
      cursor: pointer;
    }
  }

  .close-container:hover {
    color: lightgrey;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    height: 60vh !important;
    width: 60vw;
  }

  .circle {
    width: 100px !important;
    height: 100px !important;
    min-height: 100px !important;
    margin-bottom: -20px !important;
    .camera {
      width: 50px !important;
    }
  }

  button {
    min-width: 75px !important;
  }

  .text {
    width: 100% !important;
  }
}
</style>
