<script setup lang="ts"></script>

<template>
  <footer>
    <ul>
      <li><a href="/#/kyc-policy">KYC Policy</a></li>
      <li><a href="/#/terms">Terms of Service</a></li>
      <li><a href="/#/eula">EULA</a></li>
      <li><a href="/#/privacy-policy">Privacy Policy</a></li>
      <li><a href="/#/job-overview">Jobs</a></li>
      <li><a href="/#/tokenomics">Tokenomics</a></li>
    </ul>
  </footer>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
footer {
  width: 100%;
  height: 80px;
  float: left;
  margin-top: 100px;
  line-height: 80px;
  border-top: 1px solid rgba(113, 113, 133, 0.5);

  ul {
    height: 50px;
    float: right;
    margin-right: 100px;
    li {
      float: left;
      margin-right: 20px;
      a {
        color: #fff;
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  footer ul {
    margin-right: 20px;
  }
}
</style>
