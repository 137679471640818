<template>
  <div class="busd-balance">
    <img :src="imagePath" style="top: 0" height="24" alt="Contact" />
    <span> {{ numberWithDots(balance) }} {{ chain.marketplaceCurrency }}</span>
  </div>
</template>
<script setup lang="ts">
import { useWalletStore } from "@/store/wallet";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { CasperMarketplace } from "@/chain";
import { CLPublicKey } from "casper-js-sdk";

const walletStore = useWalletStore();
const { chain } = storeToRefs(walletStore);

defineProps({
  balance: {
    type: Number,
    required: true,
  },
});

function numberWithDots(x: number): string {
  if (x) {
    const res = x.toFixed(2);
    const parts = res.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join();
  }
  return "0";
}

const imagePath = computed(() => {
  let file = "";
  if (chain.value.marketplaceCurrency == "BTH") {
    file = "BTH.svg";
  } else if (chain.value.marketplaceCurrency == "wKCS") {
    file = "wKCS.png";
  } else if (chain.value.marketplaceCurrency == "wGLMR") {
    file = "GLMR.png";
  } else {
    file = `${chain.value.marketplaceCurrency}.png`;
  }

  return new URL(`../../../assets/wallet-info/${file}`, import.meta.url).href;
});
</script>
<style lang="scss" scoped>
.busd-balance {
  z-index: 55;
  color: white;
  cursor: pointer;
  float: left;
  margin-top: 20px;
  margin-left: 10px;

  span {
    width: 85px;
    /* can be 100% ellipsis will happen when contents exceed it */
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    line-height: 24px;
    margin-left: 10px;
  }

  span::hover {
    white-space: normal;
  }
}

@media only screen and (max-width: 1024px) {
  // .busd-balance {
  //   top: 185px;
  //   // display: none;
  // }
}
</style>
