<script setup lang="ts">
import { onConnect } from "@/chain/wallet";
import { useWalletStore } from "@/store/wallet";
import { EChainId, TChain } from "@/types/chain";
import { PropType } from "vue";
import { Chain } from "@/chain/chains";

const { setShowChainModal } = useWalletStore();

defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  chains: {
    type: Array as PropType<Array<TChain>>,
    default: [],
  },
});

function getChain(chainId: EChainId): Chain {
  return new Chain(chainId);
}
</script>
<template>
  <div class="chain-options-modal" v-if="show">
    <h2>Choose your chain</h2>
    <div class="options">
      <img
        class="cross"
        src="@/assets/svg/cross-white.svg"
        @click="setShowChainModal(false)"
      />
      <div
        v-for="chain of chains"
        @click="
          onConnect(chain.id);
          setShowChainModal(false);
        "
      >
        <img class="logo" :src="getChain(chain.id).icon" />
        <span>{{ chain.name }}</span>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.chain-options-modal {
  z-index: 999999;
  width: 70%;
  height: fit-content;
  top: 30%;
  left: 15%;
  background: linear-gradient(90deg, #2e2e3c 55.71%, #23232f 73.93%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 7px;
  border: 2px solid white;
  position: absolute;
  padding-top: 50px;
  .options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-self: center;
    row-gap: 16px;
    padding: 12px;
    div {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      text-align: center;
      span {
        color: white;
        margin: auto;
        padding-bottom: 20px;
        font-weight: 700;
        font-size: 24px;
      }
    }
  }
  h2 {
    font-size: 26px;
    width: 100%;
    text-align: center;
  }
  .logo {
    margin: auto;
    height: 100px;
    width: 100px;
    padding-bottom: 20px;
  }
  .cross {
    width: 25px;
    right: 20px;
    top: 20px;
    position: absolute;
    cursor: pointer;
  }
}
</style>
