import { BigNumber, ethers, utils } from "ethers";
import { toRaw } from "vue";

export * from "./marketplace";
export * from "./nft";
export * from "./getters";

export class EthersUtils {
  private readonly _signer: ethers.Signer;
  constructor(signer?: ethers.Signer) {
    this._signer = toRaw(signer);
  }

  checkAddresses(addressList: Array<string>): void {
    for (const address of addressList) {
      if (!utils.isAddress(address)) {
        throw new Error(`Invalid address provided`);
      }
    }
  }

  toWei(ethAmount: number): BigNumber {
    return utils.parseEther(ethAmount.toString());
  }

  fromWei(weiAmount: BigNumber): number {
    return +utils.formatEther(weiAmount);
  }

  hashData(data: string): string {
    return utils.hashMessage(data);
  }

  async signMessage(data: string): Promise<string> {
    if (!this._signer) {
      throw new Error(`No signer given`);
    }
    const hash = this.hashData(data);
    return await this._signer.signMessage(hash);
  }
}
