import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Modal } from "./plugins";
import VueDragscroll from "vue-dragscroll";
import { MotionPlugin } from "@vueuse/motion";
import Notifications from "@kyvg/vue3-notification";
import mitt from "mitt";
import { createI18n } from "vue-i18n";
import VueGtag from "vue-gtag";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const emitter = mitt();
const app = createApp(App).use(router);
const i18n = createI18n({
  legacy: false,
  fallbackLocale: "en",
  locale: "en",
});

app.use(
  VueGtag,
  {
    appName: "Bithotel Leon",
    pageTrackerScreenviewEnabled: true,
    pageTrackerTemplate(to) {
      return {
        page_title: to.name,
        page_path: to.path,
      };
    },
    config: {
      id: "UA-212200440-1",
      send_page_view: true,
    },
  },
  router
);

// Allow access in template to window object
app.config.globalProperties.window = window;

app.use(pinia);
app.use(i18n);
app.use(VueDragscroll);
app.config.globalProperties.emitter = emitter;
app.provide("emitter", emitter);
app.use(Modal);
app.use(Notifications);
app.use(MotionPlugin);
app.mount("#app");
