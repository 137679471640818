<script setup lang="ts">
import { partners } from "@/constants/Partners";
function getImagePath(type: string, file: string) {
  return new URL(`../../assets/partners/${type}/${file}`, import.meta.url).href;
}

function openTab(link: string) {
  window.open(link, "_blank");
}
</script>
<template>
  <div class="partners">
    <h2>Our partners</h2>

    <!-- v-motion-pop-visible -->
    <div v-for="partner of Object.keys(partners)">
      <div class="bar">
        <h4>{{ partner.replace("_", " ") }}</h4>
        <div class="img-container">
          <img
            v-for="item of partners[partner]"
            @click="item.link && openTab(item.link)"
            style="
              -webkit-filter: grayscale(100%);
              filter: grayscale(100%) brightness(0) invert(1);
            "
            :src="getImagePath(partner, item.file)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.partners {
  width: calc(100% - 270px);
  height: auto;
  float: left;
  position: relative;
  z-index: 1;
  left: 270px;
  margin-bottom: 100px;

  h2 {
    font-size: 50px;
    line-height: 65px;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    margin-left: 40px;
    font-family: "ProximaNova" !important;
    text-align: center;
  }

  .bar {
    padding-top: 30px;
    min-height: 140px;
    height: auto;
    width: 98%;
    background: linear-gradient(90deg, #2e2e3c 55.71%, #23232f 73.93%);
    box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    // right: -10px;
    position: relative;
    float: right;
    margin-top: 30px;

    h4 {
      color: white;
      font-family: "ProximaNova";
      font-size: 36px;
      color: #63fca3;
    }

    .img-container {
      padding: 25px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      column-gap: 40px;
      row-gap: 40px;
      justify-content: center;
      align-items: center;

      // height: 140px;
      img {
        max-width: 70%;
        max-height: 70%;
        padding-top: auto;
        display: block;
      }

      img.link:hover {
        -webkit-filter: grayscale(0%) !important;
        filter: grayscale(0%) !important;
        cursor: pointer;
        border-radius: 10px;
      }

      a {
        text-decoration: none !important;
      }
    }

    img {
      margin-top: 40px;
      margin-left: 50px;
      margin-right: 30px;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .partners .bar {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .partners .bar .img-container {
    width: 90% !important;
    float: left !important;
  }
}

@media only screen and (max-width: 1024px) {
  .partners {
    width: 100%;
    left: 0;
  }

  .partners .bar {
    width: 95%;
    margin-right: 2.5%;
    height: 500px;
    overflow-y: scroll;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .partners .bar .img-container {
    width: 90% !important;
    float: left !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 10px;
    column-gap: 10px;
    padding: 5px;
  }

  .partners h2 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 800px) {
  .partners .bar {
    height: 180px;
    overflow-x: hidden;
  }

  .partners .bar .img-container {
    width: 100% !important;
    float: left !important;
    grid-template-columns: 1fr 1fr 1fr;

    img {
      float: none !important;
      margin: 0 auto !important;
    }
  }

  .partners {
    height: auto !important;
    margin-bottom: 100px;
  }

  .partners .bar .img-container img {
    float: none;
    margin: 0 auto;
    display: block;
  }
}
</style>
