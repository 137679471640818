<script setup>
function openInNewTab(url) {
  window.open(url, "_blank").focus();
}
</script>
<template>
  <div class="staking">
    <div class="left">
      <router-link to="/buy-rooms">
        <div class="circle-holder">
          <div class="big-circle" style="disply: none"></div>
          <div class="big-circle-fade" style="disply: none"></div>
          <!-- <div class="circle-1"></div> -->
          <!-- <div class="circle-2"></div> -->
          <!-- <div class="circle-3"></div> -->
          <div class="bottom-round" v-motion-slide-visible-once-left>
            <img style="margin-top: -20px" src="@/assets/hotel.png" />
          </div>
        </div>
      </router-link>
    </div>
    <div class="right" v-motion-slide-visible-once-right>
      <h2 class="h2-mobile">
        Adventure through a<br /><span class="green robotic">2D universe</span>
      </h2>
      <p>
        Sign up, play with your friends and socialize while earning leaderboard
        rewards in-game. Whether you’re solely trading your characters and
        rooms, or you’re fighting it out in the mini-games, you can be any
        player you want in the Bit Hotel!
      </p>
      <div class="button-holder">
        <button
          class="button"
          @click="openInNewTab('https://bithotel.io/socialannouncements')"
        >
          <img src="@/assets/apple_icon.png" class="icon" />
          <span class="title">iOS</span>
        </button>
        <button
          class="button"
          @click="openInNewTab('https://bithotel.io/socialannouncements')"
        >
          <img src="@/assets/android-logo.svg" class="icon android" />
          <span class="title">Android</span>
        </button>
        <button
          class="button"
          @click="openInNewTab('https://play.bithotel.io')"
        >
          <img src="@/assets/laptop.svg" class="icon android" />
          <span class="title">Desktop</span>
        </button>
      </div>
    </div>
    <div class="left mobile-show">
      <div class="circle-holder">
        <div class="big-circle"></div>
        <div class="big-circle-fade"></div>
        <!-- <div class="circle-1"></div>
        <div class="circle-2"></div>
        <div class="circle-3"></div> -->
        <div class="bottom-round">
          <img src="@/assets/hotel.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.staking {
  width: calc(100% - 270px);
  height: 645px;
  float: left;
  position: relative;
  z-index: 1;
  left: 270px;
  top: 0;
  margin-top: 140px;

  .mobile-show {
    display: none;
  }

  .left {
    width: 45%;
  }

  .right {
    width: 55%;
  }

  .left,
  .right {
    height: auto;
    float: left;
    text-align: left;

    .button-holder {
      width: auto;
      height: auto;
      float: left;
      margin-left: 33px;
      margin-top: 20px;
    }

    button {
      width: 150px;
      height: 40px;
      line-height: 40px;
      background: linear-gradient(
        87.46deg,
        #3cc4d7 -3.04%,
        #6df3d3 92.88%,
        #61fea0 172.86%
      );
      box-shadow: 0px 4px 25px rgb(113 218 180 / 50%);
      color: #fff;
      border-radius: 10px;
      border: 0;
      position: relative;
      margin-right: 15px;
      margin-bottom: 15px;
      cursor: pointer;

      animation: scale10Back 400ms ease forwards;
      &:hover {
        opacity: 0.8;
        animation: scale10 400ms ease forwards;
      }

      .android {
        filter: brightness(0) invert(1);
        width: 20px;
      }

      img {
        position: absolute;
        width: 16px;
        top: 9px;
        left: 10px;
      }

      .comming-soon {
        position: absolute;
        left: 40px;
        font-size: 9px;
        color: #fff;
        top: 10px;
      }

      .title {
        position: absolute;
        left: 40px;
        color: #fff;
        top: 0px;
        font-size: 16px;
      }
    }

    h2 {
      font-size: 50px;
      line-height: 65px;
      font-weight: 900;
      color: #fff;
      text-transform: uppercase;
      margin-left: 40px;
      font-family: "ProximaNova" !important;
    }

    .green {
      color: #63fca3;
      text-shadow: 0px 4px 25px rgba(113, 218, 180, 0.5);
    }

    .robotic {
      font-family: "Press Start 2P", cursive !important;
      font-size: 40px !important;
      line-height: 55px !important;
      display: inline-block;
      margin-left: 5px;
    }

    p {
      margin-top: 20px;
      font-style: normal;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      color: #fff;
      margin-left: 40px;
      font-family: "ProximaNova" !important;
      width: 70%;
    }
  }

  .circle-holder {
    width: auto;
    height: auto;
    margin-top: 0px;
    margin-left: 80px;
    position: relative;

    animation: scale05Back 400ms ease forwards;
    &:hover {
      animation: scale05 400ms ease forwards;
    }

    .bottom-round {
      width: 330px;
      height: auto;
      overflow: hidden;
      border-bottom-left-radius: 165px;
      border-bottom-right-radius: 165px;
      position: absolute;
      top: -90px;
      left: 3px;
      z-index: 5;
      padding-top: 30px;
    }

    img {
      // top: -60px; left: -10px;
      // position: absolute;
      width: 330px;
      margin-top: 25px;
      position: relative;
      float: left;
    }

    .big-circle {
      width: 336px;
      height: 336px;
      border-radius: 50%;
      display: inline-block;
      background: linear-gradient(
        180deg,
        #3cc4d7 0%,
        #6df3d3 54.53%,
        #61fea0 100%
      );
      position: relative;
      z-index: 5;
    }

    .big-circle-fade {
      width: 432px;
      height: 432px;
      border-radius: 50%;
      display: inline-block;
      background: linear-gradient(
        180deg,
        #3cc4d7 0%,
        #6df3d3 54.53%,
        #61fea0 100%
      );
      opacity: 0.5;
      filter: blur(200px);
      position: absolute;
      top: -20px;
      left: -20px;
      animation: lightup infinite;
      animation-duration: 4s;
    }

    .circle-1 {
      position: absolute;
      top: -80px;
      margin-left: 220px;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      background: linear-gradient(
        180deg,
        #3cc4d7 0%,
        #6df3d3 54.53%,
        #61fea0 100%
      );
    }

    .circle-2 {
      position: absolute;
      top: -60px;
      margin-left: 150px;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background: linear-gradient(
        180deg,
        #3cc4d7 0%,
        #6df3d3 54.53%,
        #61fea0 100%
      );
      filter: blur(10px);
    }

    .circle-3 {
      position: absolute;
      top: -10px;
      margin-left: 290px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: linear-gradient(
        180deg,
        #3cc4d7 0%,
        #6df3d3 54.53%,
        #61fea0 100%
      );
      filter: blur(15px);
    }
  }

  @keyframes lightup {
    0% {
      filter: blur(200px);
    }
    50% {
      filter: blur(100px);
      opacity: 0.8;
    }
    100% {
      filter: blur(200px);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .staking .circle-holder {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .staking .right h2 {
    width: 90% !important;
  }
  .staking {
    width: 100%;
    left: 0;
  }

  .staking .circle-holder img {
    width: 350px;
  }

  .staking .circle-holder .bottom-round {
    top: -116px;
  }
}

@media only screen and (max-width: 800px) {
  .staking {
    height: auto;
  }

  .h2-mobile {
    margin-top: 180px;
  }

  .staking .left {
    width: 100%;
    float: right;
  }

  .staking .right {
    width: 100%;
    height: auto;
    float: left;
    margin-bottom: 200px;
  }

  .staking .circle-holder .bottom-round {
    top: -162px;
  }

  .staking .left {
    display: none;
    margin-bottom: 100px;
  }

  .mobile-show {
    display: block !important;
  }

  .staking .right .robotic {
    font-size: 30px !important;
    line-height: 46px !important;
  }
}
</style>
