import {
  CasperClient,
  CLKey,
  CLKeyParameters,
  CLPublicKey,
  CLString,
  CLStringBytesParser,
  CLValueBuilder,
  Contracts,
  DeployUtil,
  Keys,
} from "casper-js-sdk";
import { Buffer } from "buffer";

export abstract class BaseCasper {
  public readonly address: string;
  public readonly nodeUrl: string;
  protected readonly _contractClient: Contracts.Contract;
  protected readonly _casperClient: CasperClient;
  protected readonly _publicKey: CLPublicKey;

  constructor(contractHash: string, publicKey: string) {
    this.address = contractHash;
    this.nodeUrl = import.meta.env.VITE_CSPR_RPC;
    this._casperClient = new CasperClient(this.nodeUrl);
    this._contractClient = new Contracts.Contract(this._casperClient);
    this._contractClient.setContractHash(this.address);
    this._publicKey = CLPublicKey.fromHex(publicKey.toLowerCase());
  }

  async signDeploy(deploy: DeployUtil.Deploy): Promise<DeployUtil.Deploy> {
    const provider = await this.getCasperProvider();
    if (provider) {
      const publicKey = await provider.getActivePublicKey();
      const deployJson = DeployUtil.deployToJson(deploy);

      const sigRes = await provider.sign(JSON.stringify(deployJson), publicKey);
      if (!sigRes.cancelled) {
        return DeployUtil.setSignature(
          deploy,
          // @ts-ignore
          sigRes.signature,
          CLPublicKey.fromHex(publicKey)
        );
      }
    }
    throw new Error(`No signing method found`);
  }

  static async balanceOf(accountHash: string): Promise<number> {
    const nodeUrl = import.meta.env.VITE_CSPR_RPC;
    const casperClient = new CasperClient(nodeUrl);
    const balance = await casperClient.balanceOfByAccountHash(accountHash);
    return balance.div(1_000_000_000).toNumber();
  }

  get contractHash(): string {
    return this.address;
  }

  stringToKey(string: string): CLKey {
    return CLValueBuilder.key(this.stringToKeyParameter(string));
  }

  stringToKeyParameter(string: string): CLKeyParameters {
    return CLValueBuilder.byteArray(this.convertHashStrToHashBuff(string));
  }

  convertHashStrToHashBuff(hashStr: string) {
    if (hashStr.startsWith("hash-")) {
      hashStr = hashStr.slice(5);
    }
    return Buffer.from(hashStr, "hex");
  }

  static stringToUint8Array(string: string): Uint8Array {
    const clStringParser = new CLStringBytesParser();
    const result = clStringParser.toBytes(new CLString(string)).unwrap();
    return result;
  }

  protected getChainName(): string {
    const nodeEnv = import.meta.env.VITE_ENV;
    if (nodeEnv == "dev") {
      return "casper-test";
    }
    return "casper";
  }

  protected async putDeploy(
    deploy: DeployUtil.Deploy,
    waitForDeploy = true
  ): Promise<string> {
    const tx = await this._casperClient.putDeploy(deploy);
    if (waitForDeploy) {
      await this._casperClient.nodeClient.waitForDeploy(tx, 60_000);
    }
    return tx;
  }

  static accountHashFromPublicKey(publicKeyStr: string): string {
    const publicKey = CLPublicKey.fromHex(publicKeyStr.toLowerCase());
    return publicKey.toAccountRawHashStr();
  }

  async getCasperProvider() {
    try {
      const CasperWalletProvider = window.CasperWalletProvider;
      const provider = CasperWalletProvider();
      if (await provider.isConnected()) {
        return provider;
      }
    } catch (e) {
      return null;
    }
    return null;
  }
}
