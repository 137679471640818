export type Character = {
  hasGif: boolean,
  address: {
    testnet?: string,
    mainnet?: string,
  },
  price: number;
  metadata: {
    data: {
      name: string;
      description: string;
      image?: string;
      external_link?: string;
      attributes: Array<{ trait_type: string; value: string }>
    }
  }
  quantity: number;
}

export const season1: Array<Character> = [
  {
    hasGif: true,
    address: {
      mainnet: "0xA4011E3C0D80E152721E477AE387338E6a6C1a98",
      testnet: "0x51656D36E6E43e797eAB0BeC815e0D4cd776034d",
    },
    price: 20,
    metadata: {
      data: {
        name: "Janitor Jim",
        description:
          "One of the oldest occupants of the Bit Hotel, been there since day one. Knows the management by name and his way through the secret corridors of the hotel. A gray but wise man.",
        image:
          "https://ipfs.infura.io/ipfs/QmZP9xQDba9GWd5JWXimwoDevjmH5ydEUsecTv72ALrVwy",
        external_link: "https://www.bithotel.io/#/",
        attributes: [
          {
            trait_type: "Rarity",
            value: "Common",
          },
          {
            trait_type: "Replicas",
            value: "500",
          },
          {
            trait_type: "Drop",
            value: "Season 1",
          },
        ],
      },
    },
    quantity: 0,
  },
  {
    hasGif: true,
    address: {
      mainnet: "0x851Fea1272Bf2C47c6b2253B17fb83A40Acca92e",
      testnet: "0xc610aF3D83afDF9FDF8dF2a342e551BEc52a8E65",
    },
    price: 40,
    metadata: {
      data: {
        name: "Piccolo Bimbo",
        description:
          "The know-it-all picollo that helps all guests to their rooms. Don't let appearances be deceiving, a sharp young kid. Has a keen ear for gossip and knows things before they happen...",
        image:
          "https://ipfs.infura.io/ipfs/QmS5c3aa4W9kUkzuA4imerQXy1NYPKXNG9rmDLXb4Ut2uT",
        external_link: "https://www.bithotel.io/#/",
        attributes: [
          {
            trait_type: "Rarity",
            value: "Common",
          },
          {
            trait_type: "Replicas",
            value: "400",
          },
          {
            trait_type: "Drop",
            value: "Season 1",
          },
        ],
      },
    },
    quantity: 0,
  },
  {
    hasGif: true,
    address: {
      mainnet: "0x9F07ca343dee11519522b84Dc97E6271163dC465",
      testnet: "0xC65a1cb4891fcc058f3fB088a7566c8A07700a87",
    },
    price: 60,
    metadata: {
      data: {
        name: "Lady McBitmain",
        description:
          "The main supplier of hardware on which the Bit Hotel runs, a true monopolist. She loves to spend time at the fanciest restaurants and bars as well as cuddle her dog.",
        image:
          "https://ipfs.infura.io/ipfs/QmbqpjbcZbEDe22p9JCBKCuB3DEEpkWwwtM5924sXvz9SW",
        external_link: "https://www.bithotel.io/#/",
        attributes: [
          {
            trait_type: "Rarity",
            value: "Common",
          },
          {
            trait_type: "Replicas",
            value: "300",
          },
          {
            trait_type: "Drop",
            value: "Season 1",
          },
        ],
      },
    },
    quantity: 0,
  },
  {
    hasGif: true,
    address: {
      mainnet: "0xc03358513051dece7441B9c358CAEfdfeE25CDb4",
      testnet: "0x7B00e4507a0c053E4ea369e5AeA889c6eed8c066",
    },
    price: 80,
    metadata: {
      data: {
        name: "Wally Bets",
        description:
          "The pinnacle of degenerates, a true mascot for the short squeeze movement and thereby flamboyantly against short-sellers. Apes into projects but never sells, hodl's his bags.",
        image:
          "https://ipfs.infura.io/ipfs/QmRD6NxiGA3iwuUgiMbWKUBz8eygquycwGn9MsZWk2WGcu",
        external_link: "https://www.bithotel.io/#/",
        attributes: [
          {
            trait_type: "Rarity",
            value: "Uncommon",
          },
          {
            trait_type: "Replicas",
            value: "200",
          },
          {
            trait_type: "Drop",
            value: "Season 1",
          },
        ],
      },
    },
    quantity: 0,
  },
  {
    hasGif: true,
    address: {
      mainnet: "0x30A96e1313d7eaAFac2F29E18128F8FF1695e384",
      testnet: "0x187176419701a7F1f343B0DBE9d8a0931603a88d",
    },
    price: 100,
    metadata: {
      data: {
        name: "When Lamboy",
        description:
          "A big fan of high-end luxury brands and fast cars. Pump and dumped his way to the top where he now resides. Has a vast network of connections in the Bit Hotel and flexes his bling whenever he can.",
        image:
          "https://ipfs.infura.io/ipfs/QmVWtR1xgJvwmMM6aQRMUfWMWckYF5QnPKTLxtkMdNf2iC",
        external_link: "https://www.bithotel.io/#/",
        attributes: [
          {
            trait_type: "Rarity",
            value: "Uncommon",
          },
          {
            trait_type: "Replicas",
            value: "150",
          },
          {
            trait_type: "Drop",
            value: "Season 1",
          },
        ],
      },
    },
    quantity: 0,
  },
  {
    hasGif: true,
    address: {
      mainnet: "0xE27CbD36F6F4138a42071341AD00aA7f5cb2a73c",
    },
    price: 150,
    metadata: {
      data: {
        name: "Moon Moon",
        description:
          "The most ambitious dog the hotel has seen in a while. Moon Moon is an astronaut by profession and owns the title of the first dog in space. Fully capable of conversation and always aims for the moon.",
        image:
          "https://ipfs.infura.io/ipfs/QmPDuuEqn9WVoCKfiJ4LnrfyZA8yd7oXjBPsZjAtoiXyPy",
        external_link: "https://www.bithotel.io/#/",
        attributes: [
          {
            trait_type: "Rarity",
            value: "Uncommon",
          },
          {
            trait_type: "Replicas",
            value: "100",
          },
          {
            trait_type: "Drop",
            value: "Season 1",
          },
        ],
      },
    },
    quantity: 0,
  },
  {
    hasGif: true,
    address: {
      mainnet: "0xb95f3d56a3d5E44202DC06647E196460E5E3E644",
      testnet: "0xd936982c4b9D26D67fe37EFA9cfcC7f347529F28",
    },
    price: 200,
    metadata: {
      data: {
        name: "Mr. Anonymous",
        description:
          "Hacks into hotel security systems for sport to spy on the ladies locker room. Believes in decentralization and shared governance of the hotel amenities. Uses his hotel influence from the shadows.",
        image:
          "https://ipfs.infura.io/ipfs/QmXPi5ep7gAZg6Wzc8h1eVypACL7raQtDrAVg2mtYF9AAa",
        external_link: "https://www.bithotel.io/#/",
        attributes: [
          {
            trait_type: "Rarity",
            value: "Rare",
          },
          {
            trait_type: "Replicas",
            value: "60",
          },
          {
            trait_type: "Drop",
            value: "Season 1",
          },
        ],
      },
    },
    quantity: 0,
  },
  {
    hasGif: true,
    address: {
      mainnet: "0x9c6f6c6dBb9919aE976ADE3697f229CEcF79c3A5",
      testnet: "0x6Ca84adD607717F54d3dfa74927dA76908260425",
    },
    price: 250,
    metadata: {
      data: {
        name: "Shibasketballer",
        description:
          "The most feared yet animal-loving sportsman of the Bit Hotel. Has been breezing through competitions on his way to the top of the leaderboards and shows no signs of losing.",
        image:
          "https://ipfs.infura.io/ipfs/QmXsuKJiQTy1zMThXA8KfzxRE568GpX6D1DYRSz92qzwaQ",
        external_link: "https://www.bithotel.io/#/",
        attributes: [
          {
            trait_type: "Rarity",
            value: "Rare",
          },
          {
            trait_type: "Replicas",
            value: "50",
          },
          {
            trait_type: "Drop",
            value: "Season 1",
          },
        ],
      },
    },
    quantity: 0,
  },
  {
    hasGif: true,
    address: {
      mainnet: "0x3de112038A25c5F29217513C3aAff5F1847e6c9f",
      testnet: "0x0e422DF817c39DD3aB7164f0781992Af1a51a045",
    },
    price: 300,
    metadata: {
      data: {
        name: "Ms. Kitty",
        description:
          "The most adorable character walking the Bit Hotel universe. Had an early win with her cryptokitty designs and now lives in the suites of the hotel. Has never been seen without her onesie.",
        image:
          "https://ipfs.infura.io/ipfs/QmcwHc7t65gQzYTsR3NXYvTGCGAAwAF4RiBxAngezXXtBp",
        external_link: "https://www.bithotel.io/#/",
        attributes: [
          {
            trait_type: "Rarity",
            value: "Super rare",
          },
          {
            trait_type: "Replicas",
            value: "40",
          },
          {
            trait_type: "Drop",
            value: "Season 1",
          },
        ],
      },
    },
    quantity: 0,
  },
  {
    hasGif: true,
    address: {
      mainnet: "0x2bEAc18cAabb176C2488F16ee5601ae18bD71144",
      testnet: "0xbB499417A2246a854e7C931d4d471aD3A5945685",
    },
    price: 400,
    metadata: {
      data: {
        name: "Elon Dusk",
        description:
          "One of the most brilliant and forward thinking hotel guests. Works at least 100 hours per week and does not care what the world thinks. Uses the Bit Hotel rocket launchpad for his commute to work.",
        image:
          "https://ipfs.infura.io/ipfs/QmPY4bAT94vdLr9mfMHtkZs6Q5v8x8jKzzQwMy2tYeWJtK",
        external_link: "https://www.bithotel.io/#/",
        attributes: [
          {
            trait_type: "Rarity",
            value: "Super rare",
          },
          {
            trait_type: "Replicas",
            value: "30",
          },
          {
            trait_type: "Drop",
            value: "Season 1",
          },
        ],
      },
    },
    quantity: 0,
  },
  {
    hasGif: true,
    address: {
      mainnet: "0x1336f49D554A456AdD6bf9Ef2cd8c16Cfc976Ecc",
      testnet: "0xb69464d7B9EbE1abdB26C66D88DbcEA8f3b18dD9",
    },
    price: 500,
    metadata: {
      data: {
        name: "Mr. Diamondhands",
        description:
          "The guy that only uses buy-orders and doesn't lose a sweat. Truly has the most rock-steady attitude of all the hotel guests as he likes to short-squeeze his competitors for sport.",
        image:
          "https://ipfs.infura.io/ipfs/QmawgEtUNtuTBzU6wwvMXCtAT1nXBWizYhe2FHAcSbaJcY",
        external_link: "https://www.bithotel.io/#/",
        attributes: [
          {
            trait_type: "Rarity",
            value: "Super rare",
          },
          {
            trait_type: "Replicas",
            value: "20",
          },
          {
            trait_type: "Drop",
            value: "Season 1",
          },
        ],
      },
    },
    quantity: 0,
  },
  {
    hasGif: true,
    address: {
      mainnet: "0x855eC76e3d9305fE449eD0f116BAa4Cfff8D2bd3",
      testnet: "0xcf8EA64EAa19C9EAC788c3186c8c1Db3435789E1",
    },
    price: 1000,
    metadata: {
      data: {
        name: "Crypto Punkhead",
        description:
          "Traveled back in time from the future to join the Bit Hotel metaverse. One of the first to join the NFT space, a feisty character that sets the trends inside of Bit Hotel and helps govern the hotel to make sure all are having a good time.",
        image:
          "https://ipfs.infura.io/ipfs/QmNmoays4HXA59SFYGoXAg5TP33vNw8KaFRkXc7xzhvRbw",
        external_link: "https://www.bithotel.io/#/",
        attributes: [
          {
            trait_type: "Rarity",
            value: "Epic",
          },
          {
            trait_type: "Replicas",
            value: "10",
          },
          {
            trait_type: "Drop",
            value: "Season 1",
          },
        ],
      },
    },
    quantity: 0,
  },
  {
    hasGif: true,
    address: {
      mainnet: "0xd40E468F8860c40577F9F8886bb5cCdB962f75Cd",
      testnet: "0xe5BF8DEdE17bD8EdFB3719A6754f41A85dEE03f3",
    },
    price: 5000,
    metadata: {
      data: {
        name: "The Dogefather",
        description:
          "A doge that makes offers people can't refuse. Controls parts of the Bit Hotel through his pawns. The dogefather doesn't get sniffed on. He does the sniffing. Truly a character you don't want to run into at night. Will have a significant influence within the Bit Hotel governance model.",
        image:
          "https://ipfs.infura.io/ipfs/QmV9N8dnrREya9MeNukqYcaW4y7SoohZVqf8yQpFTxm6FD",
        external_link: "https://www.bithotel.io/#/",
        attributes: [
          {
            trait_type: "Rarity",
            value: "Epic",
          },
          {
            trait_type: "Replicas",
            value: "5",
          },
          {
            trait_type: "Drop",
            value: "Season 1",
          },
        ],
      },
    },
    quantity: 0,
  },
];

export const season2: Array<Character> = [
  // {
  //   quantity: 400,
  //   hasGif: true,
  //   address: {
  //     mainnet: "0x86ab08ee1cd4be3a2d171dc3e6c2b6dfea0d4dd7",
  //   },
  //   price: 20,
  //   metadata: {
  //     data: {
  //       name: "Picollo Pepe",
  //       description:
  //         "One of the few know-it-all piccolo's of the Hotel, having access to the service elevator for quick transport. Knows all Hotel Guests by name and makes sure he takes care of the other guests in the best way possible.",
  //       attributes: [
  //         { trait_type: "Rarity", value: "Common" },
  //         { trait_type: "Replicas", value: "500" },
  //         { trait_type: "Drop", value: "Season 2" },
  //       ],
  //     },
  //   },
  // },
  {
    quantity: 400,
    hasGif: true,
    price: 40,
    address: {
      testnet: "0x553b191b7B039101A6db8514AF23969057f116C3",
      mainnet: "0xd909F24C2F3E26EF5C947f043992FfF692a1CC1F",
    },
    metadata: {
      data: {
        name: "Degen Daytrader",
        description:
          "A true candlestick warrior that buys the tops and sells the bottoms while hanging around rugpull discords and subreddits to get rekt. Is one of the only hotel guests with access to the trading room and tools inside the hotel.",
        attributes: [
          { trait_type: "Rarity", value: "Common" },
          { trait_type: "Replicas", value: "400" },
          { trait_type: "Drop", value: "Season 2" },
        ],
      },
    },
  },
  {
    quantity: 260,
    hasGif: true,
    address: {
      mainnet: "0xba182Ef4b4525ddef30EC36BD5E80388c7E3E6A1",
    },
    price: 60,
    metadata: {
      data: {
        name: "Innkeeper Kermit",
        description:
          "The always enthusiastic and funny inn-keeper of the Hotel that makes everyone feel at home. Has the keys to the bar and loves to make some tokens by selling his home-brewed craft beers.",
        attributes: [
          { trait_type: "Rarity", value: "Common" },
          { trait_type: "Replicas", value: "300" },
          { trait_type: "Drop", value: "Season 2" },
        ],
      },
    },
  },
  {
    quantity: 200,
    hasGif: true,
    price: 80,
    address: {
      testnet: "0xAE7Ee05b56996457a6DdE3Bc2f164314c9fd1d68",
      mainnet: "0x4E1f43211E402f420c15916866Fd5fcEdD23123A",
    },
    metadata: {
      data: {
        name: "Kawaii Keyboardwarrior",
        description:
          "Her kawaii looks and fiery character is what makes this e-girl a Bit Hotel celebrity. As an avid streamer of Bit Hotel mini-games she often ends top 10% in the leaderboards and makes sure her followers follow along. An absolute sphinx behind a keyboard, so don't let appearances be deceiving.",
        attributes: [
          { trait_type: "Rarity", value: "Uncommon" },
          { trait_type: "Replicas", value: "200" },
          { trait_type: "Drop", value: "Season 2" },
        ],
      },
    },
  },
  {
    quantity: 100,
    hasGif: true,
    price: 150,
    address: {
      testnet: "0xd698DeB67A512E94d4f4A6A1BDD7E8947C81F743",
      mainnet: "0x5eeF2200bBdc29c81AF88AD62A6aEB665b90C94A",
    },
    metadata: {
      data: {
        name: "The Monopolist Manager",
        description:
          "A true monopolist of the Hotel, having deep pockets and owning large chunks of floors that he rents out for income. A power player that makes sure he draws the cards, monopolizing asset classes so he can set the price. Don't step on his squares, that's a warning.",
        attributes: [
          { trait_type: "Rarity", value: "Uncommon" },
          { trait_type: "Replicas", value: "100" },
          { trait_type: "Drop", value: "Season 2" },
        ],
      },
    },
  },
  {
    quantity: 80,
    hasGif: true,
    price: 175,
    address: {
      testnet: "0xfe63537406f9d1B7E5cc1c23FF08762c713e4b8c",
      mainnet: "0x970E431881575905B5CB19300356184ce393D743",
    },
    metadata: {
      data: {
        name: "Maitre Morty",
        description:
          "Jeez Rick, a-a-are you sure this Bit Hotel thing is going to be a good idea? W-w-what if I get bored of doing this maid café thing, is there a way out? Oh man, I hope this plays out alright.",
        attributes: [
          { trait_type: "Rarity", value: "Uncommon" },
          { trait_type: "Replicas", value: "80" },
          { trait_type: "Drop", value: "Season 2" },
        ],
      },
    },
  },
  {
    quantity: 60,
    hasGif: true,
    price: 200,
    address: {
      testnet: "0xfe478cEd381B2DAFd5d25b2Eb450bBF2f6652703",
      mainnet: "0xA8555377f94624d9d3Dde11A544506F98A3fAa40",
    },
    metadata: {
      data: {
        name: "Meta Masked Fox",
        description:
          "The fox that knows his way into all the Hotel Guests' wallets. Somehow has access to all the private and public keys and never gets caught. A mischievous and rarely seen guest that lurks in the shadows.",
        attributes: [
          { trait_type: "Rarity", value: "Rare" },
          { trait_type: "Replicas", value: "60" },
          { trait_type: "Drop", value: "Season 2" },
        ],
      },
    },
  },
  {
    quantity: 50,
    hasGif: true,
    price: 250,
    address: {
      testnet: "0x8A0d0B44a5C24DE377A5cFA6c991C12c751e79da",
      mainnet: "0x8d806D63C0827B8AAf8bD4D3CB87c0ca5af74eCf",
    },
    metadata: {
      data: {
        name: "Astronaut CZ",
        description:
          "Your celebrity astronaut that works 80 hours per week and has a yellow themed room in the Hotel for some reason. Loves to centralize assets for efficiency and sponsors the Hotel team behind the scenes to boost the hotel growth to the moon!",
        attributes: [
          { trait_type: "Rarity", value: "Rare" },
          { trait_type: "Replicas", value: "50" },
          { trait_type: "Drop", value: "Season 2" },
        ],
      },
    },
  },
  {
    quantity: 40,
    hasGif: true,
    price: 300,
    address: {
      testnet: "0xF4B3Ca7513FE574e55537b23594bd92D1FBc0051",
      mainnet: "0xe01B551484765335b908C8A20c5380eFc5C00052",
    },
    metadata: {
      data: {
        name: "Director Dolan",
        description:
          "One of the directors of the Hotel, the degen cousin of Donald Duck, a memelord first class. Loves to patrol the corridors looking for any cash that was dropped on the floor to give to his small nephews. Has access to the director board rooms and loves to work on comics in his spare time.",
        attributes: [
          { trait_type: "Rarity", value: "Rare" },
          { trait_type: "Replicas", value: "40" },
          { trait_type: "Drop", value: "Season 2" },
        ],
      },
    },
  },
  {
    quantity: 30,
    hasGif: true,
    price: 400,
    address: {
      testnet: "0x1Ff75a0D30BFc2eda721936992e106A4fC9122F3",
      mainnet: "0x79b50C9eE81c2878E3f2aa440F0F5d612ff09539",
    },
    metadata: {
      data: {
        name: "The Zuckerclerk",
        description:
          "The pinnacle of centralized control in the Hotel. Monitoring and censoring the bad actors in the Bit Hotel ecosystem from his ivory tower on the top floor, the Zuckerclerk is far more than meets the eye. Takes care of the Hotel management's social accounts in his spare time as he's shown affinity for it. Hosts social gatherings with exclusions and loves to update the Hotel's economy algorithms when he's bored.",
        attributes: [
          { trait_type: "Rarity", value: "Super rare" },
          { trait_type: "Replicas", value: "30" },
          { trait_type: "Drop", value: "Season 2" },
        ],
      },
    },
  },
  {
    quantity: 15,
    hasGif: true,
    price: 750,
    address: {
      testnet: "0x55B96113aB1c5750111Ca82Da5d99bdaa2F61495",
      mainnet: "0xE3E539b0A1eb97256c59f1c1FD13A59367e2B55A",
    },
    metadata: {
      data: {
        name: "Karaoke Kim",
        description:
          "One of the very few North Korean Hotel guests in the Bit Hotel. Has a very explosive character on his bad days and never seems to quite get the power he wants in the Hotel even though he controls a lot of floors with an iron fist. A regular customer at the Hotel's karaoke bar.",
        attributes: [
          { trait_type: "Rarity", value: "Super rare" },
          { trait_type: "Replicas", value: "15" },
          { trait_type: "Drop", value: "Season 2" },
        ],
      },
    },
  },
  {
    quantity: 5,
    hasGif: true,
    price: 1500,
    address: {
      testnet: "0xEA984ec84f8bF5a051C72Be4754b23Eaee6c612f",
      mainnet: "0xAf8B0F134F7Fc66407cc35F2AA287b450D782043",
    },
    metadata: {
      data: {
        name: "Notorious Neo",
        description:
          "A native resident of the metaverse, knows exactly when there is a glitch in the Bit Hotel matrix and when it's time to log out. Knows the game architects by their first names and exerts control over them if things don't go his way. Offer Hotel guests that feel stuck a way out sometimes too.",
        attributes: [
          { trait_type: "Rarity", value: "Epic" },
          { trait_type: "Replicas", value: "5" },
          { trait_type: "Drop", value: "Season 2" },
        ],
      },
    },
  },
  {
    quantity: 2,
    hasGif: true,
    price: 10000,
    address: {
      testnet: "0x09e47A6625864b160B0eaf619AB0cc7efc2fE4eD",
      mainnet: "0x605cc7D6e77A75D6d171d373D772CA3CF3048930",
    },
    metadata: {
      data: {
        name: "Grumpy Nyan Cat",
        description:
          "The rarest, most colorful and legendary Hotel guest to date. With abundant colors and a sparkly aura seeing Grumpy Nyan cat in the lobby is a true sight to behold. With never seen before perks and utilities this rainbow cat is more than a viral sensation, he's a movement in itself and renowned throughout the Hotel. Leaves a trail of rainbows wherever he goes and somehow earns massive bonuses by competing in the Hotel's arcade. A true mystery only few will be able to understand.",
        attributes: [
          { trait_type: "Rarity", value: "Legendary" },
          { trait_type: "Replicas", value: "2" },
          { trait_type: "Drop", value: "Season 2" },
        ],
      },
    },
  },
];
