<template>
  <div>
    <ConnectWallet v-if="isActive" />
    <div class="bar">
      <p>
        Floor
        <a href="/#/floor-range/5419e0fa-3583-4faa-af3a-31154358b3bf/2">2</a>
        and
        <a href="/#/floor-range/5419e0fa-3583-4faa-af3a-31154358b3bf/4">4</a>
        are live for sale!!
      </p>
    </div>
    <div class="wrapper rel auto-h" :style="{ background: backgroundColor }">
      <Navigation class="navigation auto-wh" :socials="false" />
      <div style="margin-top: 100px">
        <div
          v-if="!isActive && (loadingImage || loadingAPI) && width > 800"
          class="loading-text"
        >
          <h2 class="rel">
            Loading The <span class="green">Bit Hotel</span>...
          </h2>
        </div>
        <div
          class="abs nft-toggle mauto"
          :class="{ 'nft-toggle-room': !isActive }"
        >
          <router-link class="characters rel" to="/buy-characters">
            <span class="holder" :class="{ activeRight: isActive }"
              >Characters</span
            >
            <span class="abs mauto" :class="{ dotLeft: isActive }"></span>
          </router-link>
          <router-link to="/buy-rooms" class="rooms rel">
            <span class="holder" :class="{ active: !isActive }">Rooms</span>
            <span class="abs mauto" :class="{ dotRight: !isActive }"></span>
          </router-link>
        </div>
        <div class="pre-order rel mobile">
          <div
            class="room-view rel auto-h"
            v-if="!isActive && Object.keys(building).length > 0"
          >
            <!-- Clouds -->
            <img
              v-if="width > 800"
              src="@/assets/room_sale/cloud_1.svg"
              class="cloud cloud1 mauto"
            />
            <img
              v-if="width > 800"
              src="@/assets/room_sale/small_cloud.svg"
              class="cloud small-cloud"
            />
            <img
              v-if="width > 800"
              src="@/assets/room_sale/small_cloud.svg"
              class="cloud small-cloud2"
            />
            <img
              v-if="width > 800"
              src="@/assets/room_sale/small_cloud.svg"
              class="cloud small-cloud3"
            />
            <img
              v-if="width > 800"
              src="@/assets/room_sale/cloud_1.svg"
              class="cloud cloud3"
            />
            <img
              v-if="width > 800"
              src="@/assets/room_sale/cloud_1.svg"
              class="cloud cloud4"
            />
            <img
              v-if="width > 800"
              src="@/assets/room_sale/cloud_solid.svg"
              class="cloud cloud5 mauto"
            />
            <img
              v-if="width > 800"
              src="@/assets/room_sale/cloud_solid.svg"
              class="cloud cloud6 mauto"
            />
            <img
              v-if="width > 800"
              src="@/assets/room_sale/cloud_solid.svg"
              class="cloud cloud7"
            />
            <img
              v-if="width > 800"
              src="@/assets/room_sale/cloud_solid.svg"
              class="cloud cloud8 mauto"
            />

            <img src="@/assets/room_sale/blimp.svg" class="abs blimp" />

            <img
              v-if="width < 800"
              src="@/assets/room_sale/buyNFT-background.png"
              class="abs mobile-background mauto"
            />

            <img
              v-if="width > 800"
              src="@/assets/room_sale/bitcoin_cloud.svg"
              class="cloud bitcoin-cloud"
            />
            <img
              v-if="width > 800"
              src="@/assets/room_sale/helipad.svg"
              class="abs helipad auto-wh mauto"
            />
            <img
              v-if="width > 800"
              src="@/assets/room_sale/car_balcony.svg"
              class="abs carBalcony auto-wh mauto"
            />
            <img
              v-if="width > 800"
              src="@/assets/room_sale/pool.svg"
              class="abs pool auto-wh mauto"
            />
            <img
              v-if="width > 800"
              src="@/assets/room_sale/helicopter.gif"
              class="abs chopper auto-wh mauto"
            />

            <!-- Hover effect overlay -->
            <div v-if="width > 800">
              <router-link
                class="item item1"
                :class="{
                  deactiveRouterLink: !building.floorRanges[8].isActive,
                }"
                :to="{
                  name: 'Floor Range',
                  params: { id: building.floorRanges[8].id },
                }"
              ></router-link>
              <router-link
                class="item item2"
                :class="{
                  deactiveRouterLink: !building.floorRanges[7].isActive,
                }"
                :to="{
                  name: 'Floor Range',
                  params: { id: building.floorRanges[7].id },
                }"
              ></router-link>
              <router-link
                class="item item3"
                :class="{
                  deactiveRouterLink: !building.floorRanges[6].isActive,
                }"
                :to="{
                  name: 'Floor Range',
                  params: { id: building.floorRanges[6].id },
                }"
              ></router-link>
              <router-link
                class="item item4"
                :class="{
                  deactiveRouterLink: !building.floorRanges[5].isActive,
                }"
                :to="{
                  name: 'Floor Range',
                  params: { id: building.floorRanges[5].id },
                }"
              ></router-link>
              <router-link
                class="item item5"
                :class="{
                  deactiveRouterLink: !building.floorRanges[4].isActive,
                }"
                :to="{
                  name: 'Floor Range',
                  params: { id: building.floorRanges[4].id },
                }"
              ></router-link>
              <router-link
                class="item item6"
                :class="{
                  deactiveRouterLink: !building.floorRanges[3].isActive,
                }"
                :to="{
                  name: 'Floor Range',
                  params: { id: building.floorRanges[3].id },
                }"
              ></router-link>
              <router-link
                class="item item7"
                :class="{
                  deactiveRouterLink: !building.floorRanges[2].isActive,
                }"
                :to="{
                  name: 'Floor Range',
                  params: { id: building.floorRanges[2].id },
                }"
              ></router-link>
              <router-link
                class="item item8"
                :class="{
                  deactiveRouterLink: !building.floorRanges[1].isActive,
                }"
                :to="{
                  name: 'Floor Range',
                  params: { id: building.floorRanges[1].id },
                }"
              ></router-link>
              <router-link
                class="item item9"
                :class="{
                  deactiveRouterLink: !building.floorRanges[0].isActive,
                }"
                :to="{
                  name: 'Floor Range',
                  params: { id: building.floorRanges[0].id, level: 1 },
                }"
              ></router-link>
            </div>
            <div class="floor top-floor floor5">
              <div class="holder">
                <div class="floor-range-box box1">
                  <div class="title">
                    <h3>Top floors</h3>
                    <RarityDots rarity="Epic" />
                  </div>
                  <div class="floor-range">
                    <span class="label">Floor range</span>
                    <div class="range-holder">
                      <img src="@/assets/room_sale/stairs.svg" />
                      <span class="range">41 - 45</span>
                    </div>
                  </div>
                  <!-- Deactive link -->
                  <router-link
                    class="view-floors"
                    :class="{
                      deactiveRouterLink: !building.floorRanges[8].isActive,
                    }"
                    :disabled="!building.floorRanges[8].isActive"
                    :event="building.floorRanges[8].isActive ? 'click' : ''"
                    :to="{
                      name: 'Floor Range',
                      params: { id: building.floorRanges[8].id },
                    }"
                  >
                    View floors
                    <img
                      v-if="!building.floorRanges[8].isActive"
                      src="@/assets/room_sale/forbidden.svg"
                    />
                  </router-link>
                </div>
                <div v-if="width > 800">
                  <div class="connection-line line1"></div>
                  <div class="mauto anchor anchor1"></div>
                  <img
                    class="floor-image"
                    src="@/assets/room_sale/middle_exterior.svg"
                  />
                </div>
              </div>
            </div>
            <div class="floor top-floor floor4">
              <div class="floor-range-box box2">
                <div class="title">
                  <h3 class="business">Business floors</h3>
                  <RarityDots rarity="Epic" />
                </div>
                <div class="floor-range">
                  <span class="label">Floor range</span>
                  <div class="range-holder">
                    <img src="@/assets/room_sale/stairs.svg" />
                    <span class="range">36 - 40</span>
                  </div>
                </div>
                <router-link
                  class="view-floors"
                  :class="{
                    deactiveRouterLink: !building.floorRanges[7].isActive,
                  }"
                  :disabled="!building.floorRanges[7].isActive"
                  :event="building.floorRanges[7].isActive ? 'click' : ''"
                  to="/contact"
                >
                  Apply now
                  <img
                    v-if="!building.floorRanges[7].isActive"
                    src="@/assets/room_sale/forbidden.svg"
                  />
                </router-link>
              </div>
              <div v-if="width > 800">
                <div class="connection-line line2"></div>
                <div class="mauto anchor anchor2"></div>
                <img
                  class="floor-image"
                  src="@/assets/room_sale/middle_exterior_2.svg"
                />
              </div>
            </div>
            <div
              v-if="width > 800"
              class="abs floor top-floor plateau plateau1"
            >
              <img class="floor-image" src="@/assets/room_sale/plateau.svg" />
            </div>
            <div class="floor top-floor floor3">
              <div class="floor-range-box box1">
                <div class="title">
                  <h3>Upper floors</h3>
                  <RarityDots class="characterRarity" rarity="Super Rare" />
                </div>
                <div class="floor-range">
                  <span class="label">Floor range</span>
                  <div class="range-holder">
                    <img src="@/assets/room_sale/stairs.svg" />
                    <span class="range">31- 35</span>
                  </div>
                </div>
                <router-link
                  class="view-floors"
                  :class="{
                    deactiveRouterLink: !building.floorRanges[6].isActive,
                  }"
                  :disabled="!building.floorRanges[6].isActive"
                  :event="building.floorRanges[6].isActive ? 'click' : ''"
                  :to="{
                    name: 'Floor Range',
                    params: { id: building.floorRanges[6].id },
                  }"
                >
                  View floors
                  <img
                    v-if="!building.floorRanges[6].isActive"
                    src="@/assets/room_sale/forbidden.svg"
                  />
                </router-link>
              </div>
              <div v-if="width > 800">
                <div class="connection-line line1"></div>
                <div class="mauto anchor anchor1"></div>
                <img
                  class="floor-image"
                  src="@/assets/room_sale/middle_exterior_3.svg"
                />
              </div>
            </div>
            <div v-if="width > 800" class="floor top-floor plateau2 abs">
              <img class="floor-image" src="@/assets/room_sale/plateau.svg" />
            </div>
            <div class="floor top-floor floor2">
              <div class="floor-range-box box2">
                <div class="title">
                  <h3>Middle floors</h3>
                  <RarityDots rarity="Super Rare" />
                </div>
                <div class="floor-range">
                  <span class="label">Floor range</span>
                  <div class="range-holder">
                    <img src="@/assets/room_sale/stairs.svg" />
                    <span class="range">26 - 30</span>
                  </div>
                </div>
                <router-link
                  class="view-floors"
                  :class="{
                    deactiveRouterLink: !building.floorRanges[5].isActive,
                  }"
                  :disabled="!building.floorRanges[5].isActive"
                  :event="building.floorRanges[5].isActive ? 'click' : ''"
                  :to="{
                    name: 'Floor Range',
                    params: { id: building.floorRanges[5].id },
                  }"
                >
                  View floors
                  <img
                    v-if="!building.floorRanges[5].isActive"
                    src="@/assets/room_sale/forbidden.svg"
                  />
                </router-link>
              </div>
              <div v-if="width > 800">
                <div class="connection-line line2"></div>
                <div class="mauto anchor anchor2"></div>
                <img
                  class="floor-image"
                  src="@/assets/room_sale/middle_exterior_4.svg"
                />
              </div>
            </div>
            <div class="floor top-floor floor1">
              <div class="floor-range-box box1">
                <div class="title">
                  <h3>Middle floors</h3>
                  <RarityDots rarity="Rare" />
                </div>
                <div class="floor-range">
                  <span class="label">Floor range</span>
                  <div class="range-holder">
                    <img src="@/assets/room_sale/stairs.svg" />
                    <span class="range"> 21 - 25 </span>
                  </div>
                </div>
                <router-link
                  class="view-floors"
                  :class="{
                    deactiveRouterLink: !building.floorRanges[4].isActive,
                  }"
                  :disabled="!building.floorRanges[4].isActive"
                  :event="building.floorRanges[4].isActive ? 'click' : ''"
                  :to="{
                    name: 'Floor Range',
                    params: { id: building.floorRanges[4].id },
                  }"
                >
                  View floors
                  <img
                    v-if="!building.floorRanges[4].isActive"
                    src="@/assets/room_sale/forbidden.svg"
                  />
                </router-link>
              </div>
              <div v-if="width > 800">
                <div class="connection-line line1"></div>
                <div class="mauto anchor anchor1"></div>
                <img
                  class="floor-image"
                  src="@/assets/room_sale/middle_exterior_5.svg"
                />
              </div>
            </div>
            <div v-if="width > 800" class="floor top-floor plateau3 abs">
              <img class="floor-image" src="@/assets/room_sale/plateau.svg" />
            </div>
            <div class="floor top-floor lowerFloor1">
              <div class="floor-range-box box2">
                <div class="title">
                  <h3>Middle floors</h3>
                  <RarityDots rarity="Rare" />
                </div>
                <div class="floor-range">
                  <span class="label">Floor range</span>
                  <div class="range-holder">
                    <img src="@/assets/room_sale/stairs.svg" />
                    <span class="range"> 16 - 20 </span>
                  </div>
                </div>
                <router-link
                  class="view-floors"
                  :class="{
                    deactiveRouterLink: !building.floorRanges[3].isActive,
                  }"
                  :disabled="!building.floorRanges[3].isActive"
                  :event="building.floorRanges[3].isActive ? 'click' : ''"
                  :to="{
                    name: 'Floor Range',
                    params: { id: building.floorRanges[3].id },
                  }"
                >
                  View floors
                  <img
                    v-if="!building.floorRanges[3].isActive"
                    src="@/assets/room_sale/forbidden.svg"
                  />
                </router-link>
                <div class="powered-by">
                  <span class="text">Powered by</span>
                  <br />
                  <img
                    style="width: 75px"
                    src="@/assets/room_sale/lunapad.png"
                  />
                  <img
                    style="width: 75px; margin-left: 5px"
                    src="@/assets/room_sale/game_station.png"
                  />
                </div>
              </div>
              <div v-if="width > 800">
                <div class="connection-line line2"></div>
                <div class="mauto anchor anchor2"></div>
                <img
                  class="floor-image"
                  src="@/assets/room_sale/lower_floor_1.svg"
                />
              </div>
            </div>
            <div v-if="width > 800" class="floor top-floor plateau4 abs">
              <img class="floor-image" src="@/assets/room_sale/plateau.svg" />
            </div>
            <div class="floor top-floor lowerFloor2">
              <div class="floor-range-box box1">
                <div class="title">
                  <h3>Middle floors</h3>
                  <RarityDots rarity="Uncommon" />
                </div>
                <div class="floor-range">
                  <span class="label">Floor range</span>
                  <div class="range-holder">
                    <img src="@/assets/room_sale/stairs.svg" />
                    <span class="range"> 11 - 15 </span>
                  </div>
                </div>
                <router-link
                  class="view-floors"
                  :class="{
                    deactiveRouterLink: !building.floorRanges[2].isActive,
                  }"
                  :disabled="!building.floorRanges[2].isActive"
                  :event="building.floorRanges[2].isActive ? 'click' : ''"
                  :to="{
                    name: 'Floor Range',
                    params: { id: building.floorRanges[2].id },
                  }"
                >
                  View floors
                  <img
                    v-if="!building.floorRanges[2].isActive"
                    src="@/assets/room_sale/forbidden.svg"
                  />
                </router-link>
                <div class="powered-by">
                  <span class="text">Powered by</span>
                  <img
                    style="margin-bottom: -5px; margin-left: 4px; width: 65px"
                    src="@/assets/room_sale/spores.png"
                  />
                </div>
              </div>
              <div v-if="width > 800">
                <div class="connection-line line1"></div>
                <div class="mauto anchor anchor1"></div>
                <img
                  class="floor-image"
                  src="@/assets/room_sale/lower_floor_2.svg"
                />
              </div>
            </div>
            <div v-if="width > 800" class="floor top-floor plateau5 abs">
              <img class="floor-image" src="@/assets/room_sale/plateau.svg" />
            </div>
            <div class="floor top-floor lowerFloor3">
              <div class="floor-range-box box2" style="height: 190px">
                <div class="title">
                  <h3>Lowers floors</h3>
                  <RarityDots rarity="Uncommon" />
                </div>
                <div class="floor-range">
                  <span class="label">Floor range</span>
                  <div class="range-holder">
                    <img src="@/assets/room_sale/stairs.svg" />
                    <span class="range"> 6 - 10 </span>
                  </div>
                </div>
                <router-link
                  class="view-floors"
                  :class="{
                    deactiveRouterLink: !building.floorRanges[1].isActive,
                  }"
                  :disabled="!building.floorRanges[1].isActive"
                  :event="building.floorRanges[1].isActive ? 'click' : ''"
                  :to="{
                    name: 'Floor Range',
                    params: { id: building.floorRanges[1].id },
                  }"
                >
                  View floors
                  <img
                    v-if="!building.floorRanges[1].isActive"
                    src="@/assets/room_sale/forbidden.svg"
                  />
                  <div class="powered-by">
                    <span class="text">Powered by</span>
                    <br />
                    <img src="@/assets/room_sale/binance_partner.svg" />
                  </div>
                </router-link>
              </div>
              <div v-if="width > 800">
                <div class="connection-line line2"></div>
                <div class="mauto anchor anchor2"></div>
                <img
                  class="floor-image"
                  src="@/assets/room_sale/lower_floor_3.svg"
                />
              </div>
            </div>
            <div v-if="width > 800" class="floor top-floor plateau6 abs">
              <img class="floor-image" src="@/assets/room_sale/plateau.svg" />
            </div>
            <div class="floor top-floor lowerFloor4">
              <div class="floor-range-box box1">
                <div class="title">
                  <h3>Lower floors</h3>
                  <RarityDots rarity="Common" />
                </div>
                <div class="floor-range">
                  <span class="label">Floor range</span>
                  <div class="range-holder">
                    <img src="@/assets/room_sale/stairs.svg" />
                    <span class="range">1 - 5</span>
                  </div>
                </div>
                <router-link
                  class="view-floors"
                  :class="{
                    deactiveRouterLink: !building.floorRanges[0].isActive,
                  }"
                  :disabled="!building.floorRanges[0].isActive"
                  :event="building.floorRanges[0].isActive ? 'click' : ''"
                  :to="{
                    name: 'Floor Range',
                    params: { id: building.floorRanges[0].id, level: 1 },
                  }"
                >
                  View floors
                  <img
                    v-if="!building.floorRanges[0].isActive"
                    src="@/assets/room_sale/forbidden.svg"
                  />
                </router-link>
                <div class="powered-by">
                  <span class="text">Powered by</span>
                  <br />
                  <img
                    style="margin-bottom: -9px; width: 45px; margin-left: 10px"
                    src="@/assets/room_sale/babylons-logo-big.svg"
                  />
                  <img
                    style="
                      margin-bottom: -10px;
                      width: 80px;
                      margin-left: 10px;
                      filter: invert(1);
                    "
                    src="@/assets/ferrum.png"
                  />
                </div>
              </div>
              <div v-if="width > 800">
                <div class="connection-line line1"></div>
                <div class="mauto anchor anchor1"></div>
                <img
                  class="floor-image"
                  src="@/assets/room_sale/lower_floor_4.svg"
                />
              </div>
            </div>
          </div>
          <div class="characters-view" v-if="isActive">
            <h2>Buy Characters</h2>
            <div
              v-if="nfts.length > 0"
              class="abs season-toggle mauto"
              :class="{ 'nft-toggle-room': !isActive }"
            >
              <div class="season rel" @click="season = 1">
                <span class="holder" :class="{ activeRight: season == 1 }"
                  >Season 1</span
                >
              </div>
              <div class="season rel" @click="season = 2">
                <span class="holder" :class="{ active: season == 2 }"
                  >Season 2</span
                >
              </div>
            </div>

            <p class="responsive-text">
              There will be limited NFT releases each month, be the first to own
              one of these unique NFTs!
            </p>

            <div class="utility">
              <a
                href="https://medium.com/@bithotelnftgame/what-do-hotel-guests-actually-do-and-why-are-people-spending-up-to-5k-on-them-abbc97b77aed"
                class="button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="@/assets/book.png"
                  width="50"
                  style="position: absolute; filter: invert(1); left: 5px"
                />
                Utility
              </a>
              <div class="fade"></div>
            </div>
            <div style="clear: both"></div>

            <div
              class="pre-order-overview"
              v-if="nfts.length > 0 && !loadingAPI"
            >
              <Card
                v-for="nft in filteredNfts"
                :key="nft.address"
                :nft="nft"
                :quantity="nft.quantity"
              />
            </div>
            <div v-else>
              <!-- <span>test</span> -->
              <Loading class="loading" :width="50" :height="50" />
            </div>
          </div>
        </div>
        <div
          v-if="!isActive && width > 800"
          class="abs entrance-exterior auto-wh"
        >
          <img class="abs kart" src="@/assets/room_sale/kart.gif" />
          <img class="abs kartLeft" src="@/assets/room_sale/kart_left.gif" />
          <img
            @load="onImageLoaded"
            class="floor-image"
            src="@/assets/room_sale/entrance_exterior.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import Navigation from "@/components/base/Navigation.vue";
import RarityDots from "@/components/landsale/RarityDots.vue";
import Card from "@/components/BuyCharacter/Card.vue";
import ConnectWallet from "@/components/base/ConnectWallet.vue";
import { getBuilding } from "@/api";
import Loading from "@/components/base/Loading.vue";
import {
  season1 as seasonOneCharacters,
  season2 as seasonTwoCharacters,
} from "../constants/Characters";
import { restructureNftData } from "../plugins/helpers";
import { Nft } from "@/types";
import { EVMNFT } from "@/chain";
import { computed, onMounted, ref, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useWalletStore } from "@/store/wallet";

let nfts = reactive([]);
const building = reactive<{ floorRanges: Array<any> }>({} as any);
const width = ref<number>(window.innerWidth);
const loadingImage = ref<boolean>(true);
const loadingAPI = ref<boolean>(true);
const season = ref<number>(2);
const season2 = reactive(seasonTwoCharacters);

const router = useRouter();
const route = useRoute();

const store = useWalletStore();

onMounted(async () => {
  await readNfts();
  const _building = await getBuilding();
  Object.assign(building, _building);
  if (!building) {
    Object.assign(building, { floorRanges: [] });
  }
  if (!building.floorRanges.length) {
    for (let i = 0; i < 9; i++) {
      // Set to false
      building.floorRanges.push({ id: false, isActive: false });
    }
  }
  loadingAPI.value = false;
});

const backgroundColor = computed(() => {
  return isActive.value ? "#23232f" : "#C1EBF9";
});
const filteredNfts = computed(() => {
  if (loadingAPI.value) return [];
  return nfts.filter((nft: Nft) => +nft.season.match(/\d+/)[0] == season.value);
});

function onImageLoaded() {
  loadingImage.value = false;
}

const isActive = computed(() => {
  if (route.path == "/buy-rooms") {
    return false;
  }
  return true;
});
// function setCharactersActive(character) {
//   if (character) {
//     router.push("/buy-characters");
//   } else {
//     router.push("/buy-rooms");
//   }

//   window.scrollTo(0, 0);
//   isActive.value = character;
// }
async function readNfts() {
  const env = import.meta.env.VITE_ENV == "dev" ? "testnet" : "mainnet";
  let season1 = seasonOneCharacters;
  season1 = season1.map((c: any) => {
    if (c.address && c.address[env]) {
      c.address = c.address[env];
      return {
        ...c,
        quantity: 0, // set quantity for all season 1 on 0 (sold out)
      };
    }
    return null;
  });
  season1 = season1.filter((c) => c); // remove undefined elements in array
  let season2 = seasonTwoCharacters;
  season2 = await Promise.all(
    season2.map(async (c: any) => {
      let quantity = c.quantity;
      if (c.address && c.address[env]) {
        c.address = c.address[env];
        let supply = 0;
        try {
          const nft = new EVMNFT(c.address, store.useSignerOrProvider());
          supply = await nft.getTotalSupply();
        } catch (_) {
          // assuming it fails because collection does not exists yet.
          // also the collection could be deployed on another chain then the provider
        }
        // get total supply of collection and subtract it from quantity
        // to get available amount for presale
        quantity -= supply;
        return {
          ...c,
          quantity,
        };
      }
    })
  );
  season2 = season2.filter((c) => c); // remove undefined elements in array
  Object.assign(nfts, restructureNftData(season1.concat(season2)));
}
</script>
<style lang="scss" scoped>
.abs {
  position: absolute;
}

.rel {
  position: relative;
}

.auto-h {
  height: auto;
}

.auto-wh {
  width: auto;
  height: auto;
}

.mauto {
  margin-right: auto;
  margin-left: auto;
}

.navigation {
  display: block;
  float: right;
  margin-top: 50px;
}

.utility {
  width: 198px;
  position: relative;
  left: 420px;
  a {
    display: block;
    height: 50px;
    width: 98%;
    margin-left: 1%;
    margin-top: 7px;
    margin-bottom: -15px;
    text-transform: uppercase;
    text-align: center;
    // font-size: 15px;
    font-weight: 600;
    background: linear-gradient(
        264.06deg,
        #c63b3b -68.99%,
        #d174e0 40.01%,
        #70baff 130.88%
      ),
      linear-gradient(180deg, #c63b3b 0%, #d174e0 54.53%, #70baff 100%);
    border-radius: 10px;
    border: 0;
    color: #fff;
    position: relative;
    z-index: 55555;
    line-height: 50px;
    text-decoration: none;
    cursor: pointer;

    animation: scale10Back 400ms ease forwards;
    &:hover {
      animation: scale10 400ms ease forwards;
    }
  }

  .fade {
    height: 55px;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0;
    background: linear-gradient(
      7.72deg,
      #c63b3b -44.04%,
      rgba(209, 116, 224, 0.5) 12.94%,
      rgba(112, 186, 255, 0) 60.45%
    );
    border-radius: 10px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    width: 100vw;
  }

  .pre-order-overview {
    margin-top: 0px;
  }
  .utility {
    margin: auto;
    margin-top: 100px;
    left: inherit;
    // float: left;
    img {
      margin-right: 10px;
    }
  }
}
.bar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  height: 35px;
  background: linear-gradient(
    264.06deg,
    #c63b3b -68.99%,
    #d174e0 40.01%,
    #70baff 130.88%
  );
  p {
    a {
      color: #fff;
    }
  }
}
.wrapper {
  overflow-x: hidden !important;
  overflow-y: hidden;
  background: #c1ebf9;
}

.loading {
  position: relative;
  margin-top: 50px;
  margin-bottom: 10px;
}

.deactiveRouterLink {
  cursor: not-allowed;
  pointer-events: none;
}

.mobile-background {
  display: none;
}

.entrance-exterior {
  left: calc(50% + 200px);
  transform: translateX(-50%);
  bottom: -150px;

  .kart {
    left: 0;
    right: -895px;
    top: 965px;
    margin: auto;
    width: 410px;
  }

  .kartLeft {
    right: 0;
    left: -945px;
    top: 1325px;
    margin: auto;
    width: 700px;
  }

  .floor-image {
    width: 2300px !important;
  }
}

// Cloud
.cloud {
  position: absolute;
  z-index: 55;
}

.cloud1 {
  width: 400px;
  top: 120px;
  left: 0;
  right: -190px;
}

.small-cloud {
  top: 120px;
  right: 100px;
}

.small-cloud2 {
  top: 80px;
  left: 100px;
}

.small-cloud3 {
  top: 80px;
  right: 400px;

  img {
    width: 80px !important;
  }
}

.cloud2 {
  width: 400px;
  top: 200px;
  left: 110px;
  z-index: 1 !important;
}

.cloud3 {
  width: 650px;
  top: -200px;
  right: -170px;
  img {
    width: 400px;
  }

  animation: floatingCloud3 10s infinite;
  animation-timing-function: linear;
  // z-index: 1 !important;
}

@keyframes floatingCloud3 {
  0% {
    right: -170px;
  }
  50% {
    right: -130px;
  }
  100% {
    right: -170px;
  }
}

.cloud4 {
  width: 400px;
  top: -150px;
  left: 170px;
  img {
    width: 500px;
  }

  animation: floatingCloud4 10s infinite;
  animation-timing-function: linear;
  // z-index: 1 !important;
}

@keyframes floatingCloud4 {
  0% {
    left: 170px;
  }
  50% {
    left: 230px;
  }
  100% {
    left: 170px;
  }
}

.cloud5 {
  width: 550px;
  top: 180px;
  left: 0;
  right: -100px;

  img {
    width: 500px;
  }

  animation: floatingCloud5 8s infinite;
  animation-timing-function: linear;
  // z-index: 1 !important;
}

@keyframes floatingCloud5 {
  0% {
    right: -100px;
  }
  50% {
    right: -160px;
  }
  100% {
    right: -100px;
  }
}

.cloud6 {
  width: 400px;
  top: 100px;
  left: -40px;
  right: 0;
  img {
    width: 500px;
  }

  animation: floatingCloud6 8s infinite;
  animation-timing-function: linear;
}

@keyframes floatingCloud6 {
  0% {
    left: -40px;
  }
  50% {
    left: -90px;
  }
  100% {
    left: -40px;
  }
}

.cloud7 {
  width: 400px;
  top: 0px;
  left: -340px;

  img {
    width: 500px;
  }
}

.cloud8 {
  width: 400px;
  top: 150px;
  left: -110px;
  right: 0;

  img {
    width: 500px;
  }

  animation: floatingCloud8 8s infinite;
  animation-timing-function: linear;
  // z-index: 1 !important;
}

@keyframes floatingCloud8 {
  0% {
    left: -110px;
  }
  50% {
    left: -90px;
  }
  100% {
    left: -110px;
  }
}
.blimp {
  left: -1400px;
  top: 900px;

  animation: floatingBlimp 14s;
  animation-timing-function: linear;
}

@keyframes floatingBlimp {
  from {
    left: 300px;
    top: -400px;
  }

  to {
    left: -1400px;
    top: 900px;
  }
}

.helipad {
  top: 220px;
  left: -700px;
  right: 0;
  width: 300px !important;

  img {
    width: 300px !important;
  }
}

.carBalcony {
  top: 1850px;
  left: -600px;
  right: 0;

  img {
    width: 350px;
  }
}

.pool {
  top: 2250px;
  left: -600px;
  right: 0;

  img {
    width: 350px;
  }
}

.chopper {
  top: 180px;
  right: 0;
  left: -710px;
  z-index: 555;
  width: 200px;
}

.bitcoin-cloud {
  width: 400px;
  top: 570px;
  right: 50px;
  img {
    width: 200px !important;
  }
  z-index: 1 !important;
  animation: floatingBitcoinCloud 8s infinite;
  animation-timing-function: linear;
}

@keyframes floatingBitcoinCloud {
  0% {
    right: 50px;
  }
  50% {
    right: 0px;
    transform: rotate(20deg);
  }
  100% {
    right: 50;
  }
}

// Interaction hover floor
@keyframes rightTransition {
  from {
    margin-right: 0;
  }
  to {
    margin-right: -150px;
  }
}

.item1:hover ~ .floor4 img,
.item1:hover ~ .floor3 img,
.item1:hover ~ .floor2 img,
.item1:hover ~ .floor1 img,
.item1:hover ~ .lowerFloor1 img,
.item1:hover ~ .lowerFloor2 img,
.item1:hover ~ .lowerFloor3 img,
.item1:hover ~ .lowerFloor4 img,
.item1:hover ~ .plateau1 img,
.item1:hover ~ .plateau2 img,
.item1:hover ~ .plateau3 img,
.item1:hover ~ .plateau4 img,
.item1:hover ~ .plateau5 img,
.item1:hover ~ .plateau6 img {
  opacity: 0.5;
}

.item1:hover ~ .floor5 .floor-image {
  animation-name: rightTransition;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.item2:hover ~ .floor5 img,
.item2:hover ~ .floor3 img,
.item2:hover ~ .floor2 img,
.item2:hover ~ .floor1 img,
.item2:hover ~ .plateau1 img,
.item2:hover ~ .plateau2 img,
.item2:hover ~ .plateau3 img,
.item2:hover ~ .lowerFloor1 img,
.item2:hover ~ .lowerFloor2 img,
.item2:hover ~ .lowerFloor3 img,
.item2:hover ~ .lowerFloor4 img,
.item2:hover ~ .plateau1 img,
.item2:hover ~ .plateau2 img,
.item2:hover ~ .plateau3 img,
.item2:hover ~ .plateau4 img,
.item2:hover ~ .plateau5 img,
.item2:hover ~ .plateau6 img {
  opacity: 0.5;
}

.item2:hover ~ .floor4 .floor-image {
  animation-name: rightTransition;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.item3:hover ~ .floor5 img,
.item3:hover ~ .floor4 img,
.item3:hover ~ .floor2 img,
.item3:hover ~ .floor1 img,
.item3:hover ~ .plateau1 img,
.item3:hover ~ .plateau2 img,
.item3:hover ~ .plateau3 img,
.item3:hover ~ .lowerFloor1 img,
.item3:hover ~ .lowerFloor2 img,
.item3:hover ~ .lowerFloor3 img,
.item3:hover ~ .lowerFloor4 img,
.item3:hover ~ .plateau1 img,
.item3:hover ~ .plateau2 img,
.item3:hover ~ .plateau3 img,
.item3:hover ~ .plateau4 img,
.item3:hover ~ .plateau5 img,
.item3:hover ~ .plateau6 img {
  opacity: 0.5;
}

.item3:hover ~ .floor3 .floor-image {
  animation-name: rightTransition;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.item4:hover ~ .floor5 img,
.item4:hover ~ .floor4 img,
.item4:hover ~ .floor3 img,
.item4:hover ~ .floor1 img,
.item4:hover ~ .plateau1 img,
.item4:hover ~ .plateau2 img,
.item4:hover ~ .plateau3 img,
.item4:hover ~ .lowerFloor1 img,
.item4:hover ~ .lowerFloor2 img,
.item4:hover ~ .lowerFloor3 img,
.item4:hover ~ .lowerFloor4 img,
.item4:hover ~ .plateau1 img,
.item4:hover ~ .plateau2 img,
.item4:hover ~ .plateau3 img,
.item4:hover ~ .plateau4 img,
.item4:hover ~ .plateau5 img,
.item4:hover ~ .plateau6 img {
  opacity: 0.5;
}

.item4:hover ~ .floor2 .floor-image {
  animation-name: rightTransition;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.item5:hover ~ .floor5 img,
.item5:hover ~ .floor4 img,
.item5:hover ~ .floor3 img,
.item5:hover ~ .floor2 img,
.item5:hover ~ .plateau1 img,
.item5:hover ~ .plateau2 img,
.item5:hover ~ .plateau3 img,
.item5:hover ~ .lowerFloor1 img,
.item5:hover ~ .lowerFloor2 img,
.item5:hover ~ .lowerFloor3 img,
.item5:hover ~ .lowerFloor4 img,
.item5:hover ~ .plateau1 img,
.item5:hover ~ .plateau2 img,
.item5:hover ~ .plateau3 img,
.item5:hover ~ .plateau4 img,
.item5:hover ~ .plateau5 img,
.item5:hover ~ .plateau6 img {
  opacity: 0.5;
}

.item5:hover ~ .floor1 .floor-image {
  animation-name: rightTransition;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.item6:hover ~ .floor5 img,
.item6:hover ~ .floor4 img,
.item6:hover ~ .floor3 img,
.item6:hover ~ .floor2 img,
.item6:hover ~ .floor1 img,
.item6:hover ~ .plateau1 img,
.item6:hover ~ .plateau2 img,
.item6:hover ~ .plateau3 img,
.item6:hover ~ .lowerFloor2 img,
.item6:hover ~ .lowerFloor3 img,
.item6:hover ~ .lowerFloor4 img,
.item6:hover ~ .plateau1 img,
.item6:hover ~ .plateau2 img,
.item6:hover ~ .plateau3 img,
.item6:hover ~ .plateau4 img,
.item6:hover ~ .plateau5 img,
.item6:hover ~ .plateau6 img {
  opacity: 0.5;
}

.item6:hover ~ .lowerFloor1 .floor-image {
  animation-name: rightTransition;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.item7:hover ~ .floor5 img,
.item7:hover ~ .floor4 img,
.item7:hover ~ .floor3 img,
.item7:hover ~ .floor2 img,
.item7:hover ~ .floor1 img,
.item7:hover ~ .plateau1 img,
.item7:hover ~ .plateau2 img,
.item7:hover ~ .plateau3 img,
.item7:hover ~ .lowerFloor1 img,
.item7:hover ~ .lowerFloor3 img,
.item7:hover ~ .lowerFloor4 img,
.item7:hover ~ .plateau1 img,
.item7:hover ~ .plateau2 img,
.item7:hover ~ .plateau3 img,
.item7:hover ~ .plateau4 img,
.item7:hover ~ .plateau5 img,
.item7:hover ~ .plateau6 img {
  opacity: 0.5;
}

.item7:hover ~ .lowerFloor2 .floor-image {
  animation-name: rightTransition;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.item8:hover ~ .floor5 img,
.item8:hover ~ .floor4 img,
.item8:hover ~ .floor3 img,
.item8:hover ~ .floor2 img,
.item8:hover ~ .floor1 img,
.item8:hover ~ .plateau1 img,
.item8:hover ~ .plateau2 img,
.item8:hover ~ .plateau3 img,
.item8:hover ~ .lowerFloor1 img,
.item8:hover ~ .lowerFloor2 img,
.item8:hover ~ .lowerFloor4 img,
.item8:hover ~ .plateau1 img,
.item8:hover ~ .plateau2 img,
.item8:hover ~ .plateau3 img,
.item8:hover ~ .plateau4 img,
.item8:hover ~ .plateau5 img,
.item8:hover ~ .plateau6 img {
  opacity: 0.5;
}

.item8:hover ~ .lowerFloor3 .floor-image {
  animation-name: rightTransition;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.item9:hover ~ .floor5 img,
.item9:hover ~ .floor4 img,
.item9:hover ~ .floor3 img,
.item9:hover ~ .floor2 img,
.item9:hover ~ .floor1 img,
.item9:hover ~ .plateau1 img,
.item9:hover ~ .plateau2 img,
.item9:hover ~ .plateau3 img,
.item9:hover ~ .lowerFloor1 img,
.item9:hover ~ .lowerFloor2 img,
.item9:hover ~ .lowerFloor3 img,
.item9:hover ~ .plateau1 img,
.item9:hover ~ .plateau2 img,
.item9:hover ~ .plateau3 img,
.item9:hover ~ .plateau4 img,
.item9:hover ~ .plateau5 img,
.item9:hover ~ .plateau6 img {
  opacity: 0.5;
}

.item9:hover ~ .lowerFloor4 .floor-image {
  animation-name: rightTransition;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.nft-toggle,
.season-toggle {
  width: 299px;
  height: 80px;
  background: linear-gradient(180deg, #2e2e3c 55.66%, #23232f 90.9%);
  border-radius: 50px;
  line-height: 40px;
  left: 0;
  right: 0;
  top: 40px;
  cursor: pointer;

  .characters,
  .rooms {
    height: 100%;
    width: 50%;
    float: left;
    text-align: center;
  }

  .season {
    height: 100%;
    width: 50%;
    float: left;
    text-align: center;
  }

  .holder {
    height: 40px;
    width: 110px;
    display: block;
    margin: 15px auto;
    font-weight: bold;
    font-size: 18px;
    color: #9393a4;
    padding: 0 10px;
  }

  .dotLeft,
  .dotRight {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: 0;
    right: 0;
    bottom: 10px;
    background: linear-gradient(
      110.41deg,
      #3cc4d7 -379.49%,
      #6df3d3 106.91%,
      #61fea0 512.46%
    );
    box-shadow: 0px 4px 25px rgba(113, 218, 180, 0.5);
  }

  .dotLeft {
    right: -35px;
  }

  .dotRight {
    left: -35px;
  }

  .active,
  .activeRight {
    float: left;
    color: #9393a4;
    color: #fff;
    background: linear-gradient(
      91.98deg,
      #3cc4d7 -30.49%,
      #6df3d3 191.13%,
      #61fea0 375.91%
    );
    box-shadow: 0px 4px 25px rgba(113, 218, 180, 0.5);
    border-radius: 50px;
  }

  .activeRight {
    float: right !important;
  }
}

.season-toggle {
  width: 230px;
  margin-left: 40px;
  margin-top: 90px;
  height: 50px;
  .holder {
    width: 80px;
    height: 40px;
    margin-top: 6px;
  }
  .dotLeft {
    right: -20px;
  }
  .dotRight {
    left: -20px;
  }
}
.nft-toggle-room {
  position: fixed;
  z-index: 555;
}

.loading-text {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #2e2e3c 55.66%, #23232f 90.9%);
  z-index: 5555;
  display: block;

  h2 {
    text-align: center !important;
    margin: 0 !important;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  }
}

.pre-order {
  width: calc(100% - 270px);
  height: auto;
  float: left;
  z-index: 1;
  left: 270px;
  text-align: center;

  .item {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 55;
  }

  .item1 {
    height: 350px;
    top: 200px;
    transition: 1s;
  }

  .item2 {
    height: 275px;
    top: 525px;
  }

  .item3 {
    height: 400px;
    top: 800px;
  }

  .item4 {
    height: 600px;
    top: 1200px;
  }

  .item5 {
    height: 250px;
    top: 1800px;
  }

  .item6 {
    height: 230px;
    top: 2050px;
  }

  .item7 {
    height: 230px;
    top: 2280px;
  }

  .item8 {
    height: 230px;
    top: 2510px;
  }

  .item9 {
    height: 230px;
    top: 2740px;
  }

  .room-view {
    width: 100%;
    min-height: 100vh;
    float: left;
    padding-top: 150px;

    .floor-image {
      width: 700px;
      margin: 0 auto;
    }

    .plateau {
      width: auto !important;
      height: auto !important;
    }

    .floor {
      position: relative;

      .floor-image {
        transition: 1s;
        z-index: 5;
        position: relative;
      }

      .holder {
        width: 100%;
        height: auto;
        position: relative;
      }

      .anchor1 {
        left: -590px;
        right: 0;
        top: 265px;
        z-index: 1;
      }

      .anchor2 {
        right: -590px;
        left: 0;
        top: 265px;
      }

      .anchor {
        position: absolute;
        width: 36px;
        height: 36px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          #ffffff 0%,
          #ffffff 10.42%,
          rgba(255, 255, 255, 0) 88.54%
        );
      }

      .connection-line {
        height: 3px;
        width: 11%;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
      }

      .line1 {
        top: 280px;
        left: -700px;
        right: 0;
        background: linear-gradient(90deg, #f8f8f8 43.4%, #c1ebf9 100%);
      }

      .line2 {
        top: 280px;
        right: -700px;
        left: 0;
        background: linear-gradient(270deg, #f8f8f8 43.4%, #c1ebf9 100%);
      }

      .floor-range-box {
        width: 210px;
        height: auto;
        position: absolute;
        background: rgba(227, 245, 251, 0.5);
        box-shadow: 0px 4px 45px #c8f1ff;
        backdrop-filter: blur(4px);
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        z-index: 5;
        border: 3px solid #e3f5fb80;
        padding-bottom: 10px;

        .title {
          width: 120px;
          height: 50px;
          line-height: 40px;
          background: linear-gradient(90deg, #2e2e3c 33.58%, #23232f 85.82%);
          border: 2px solid #2e2e3c;
          border-radius: 5px;
          margin: -25px auto;

          h3 {
            font-size: 16px;
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
          }

          h3.business {
            font-size: 15px !important;
          }

          .rarity-holder {
            width: 78px;
            margin: -5px auto;
          }
        }

        .view-floors {
          width: 80%;
          height: 40px;
          line-height: 40px;
          text-decoration: none;
          color: #fff;
          border-radius: 10px;
          float: left;
          margin-left: 10%;
          margin-top: 15px;
          text-transform: uppercase;
          font-weight: 600;
          position: relative;

          background: linear-gradient(
            90deg,
            #3cc4d7 9.56%,
            #6df3d3 109.69%,
            #61fea0 193.17%
          ) !important;

          img {
            margin-bottom: -5px;
            margin-left: 5px;
          }
        }

        .deactiveRouterLink {
          cursor: not-allowed;
          opacity: 0.6;
          pointer-events: none;
        }

        .floor-range {
          width: auto;
          height: 35px;
          line-height: 35px;
          margin-top: 50px;
          margin-left: 20px;

          .label {
            color: #23232f;
            font-weight: 800;
            float: left;
            display: block;
            margin-right: 10px;
            font-size: 15px;
          }

          .range-holder {
            width: 70px;
            height: 30px;
            background: linear-gradient(90deg, #2e2e3c -49.24%, #23232f 85.82%);
            border-radius: 5px;
            float: left;
            border: 1px solid #717185;

            img {
              width: 15px;
            }

            .range {
              color: #fff;
              margin-left: 5px;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }

        .powered-by {
          width: 100%;
          height: auto;
          text-align: center;
          font-family: Dosis;
          font-style: normal;
          font-weight: 800;
          font-size: 12px;
          line-height: 152.4%;
          letter-spacing: 0.035em;
          color: #9393a4;
          position: relative;
          float: left;
          margin-top: 10px;

          img {
            position: relative;
            margin-bottom: -7px;
            margin-left: 2px;
          }
        }
      }

      .box1 {
        left: -950px;
        right: 0;
        top: 200px;
      }

      .box2 {
        right: -960px;
        left: 0;
        top: 200px;
      }
    }

    .floor6 {
      z-index: 21;
      margin-right: -80px;
      .floor-image {
        width: 600px;
      }
    }

    .floor5 {
      z-index: 20;
      margin-top: 0px;
      .floor-image {
        width: 650px;
        transition: 1s;
      }
    }

    .floor4 {
      z-index: 19;
      margin-top: -310px;
    }

    .plateau1 {
      z-index: 17;
      margin-top: -320px;
      position: absolute !important;
      width: 680px !important;
      height: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
      left: 0;
      right: 0px;

      img {
        width: 680px !important;
      }
    }

    .floor3 {
      z-index: 16;
      margin-top: -280px;
      margin-left: 25px;

      .floor-image {
        width: 680px !important;
      }
    }

    .plateau2 {
      z-index: 15;
      margin-top: -320px;
      width: 700px !important;
      position: absolute !important;
      height: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
      left: 0;
      right: 0px;

      img {
        width: 700px !important;
      }
    }

    .floor2 {
      z-index: 14;
      margin-top: -305px;
      margin-left: 25px;

      .floor-image {
        width: 705px !important;
      }
    }

    .floor1 {
      z-index: 13;
      margin-top: -325px;
      margin-left: 15px;

      .floor-image {
        width: 705px !important;
      }
    }

    .plateau3 {
      z-index: 12;
      margin-top: -320px;
      position: absolute !important;
      width: 710px !important;
      height: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
      left: 0;
      right: 0px;

      img {
        width: 710px !important;
        z-index: 5;
      }
    }

    .lowerFloor1 {
      z-index: 11;
      margin-top: -270px;
      margin-left: 10px;

      .floor-image {
        width: 705px !important;
      }
    }

    .plateau4 {
      z-index: 10;
      position: absolute !important;
      margin-top: -350px;
      width: 710px !important;
      height: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
      left: 0;
      right: 0px;

      img {
        width: 710px !important;
        z-index: 5;
      }
    }

    .lowerFloor2 {
      z-index: 9;
      margin-top: -310px;
      margin-left: 10px;

      .floor-image {
        width: 705px !important;
      }
    }

    .plateau5 {
      z-index: 8;
      position: absolute !important;
      margin-top: -350px;
      width: 710px !important;
      height: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
      left: 0;
      right: 0px;

      img {
        width: 710px !important;
        z-index: 5;
      }
    }

    .lowerFloor3 {
      z-index: 7;
      margin-top: -300px;
      margin-left: -30px;

      .floor-image {
        width: 705px !important;
      }
    }

    .plateau6 {
      z-index: 6;
      margin-top: -350px;
      position: absolute !important;
      width: 710px !important;
      height: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
      left: 0;
      right: 0px;

      img {
        width: 710px !important;
        z-index: 5;
      }
    }

    .lowerFloor4 {
      z-index: 5;
      margin-top: -305px;
      margin-left: -20px;
      margin-bottom: 850px;

      .floor-image {
        width: 705px !important;
      }
    }
  }

  .mobile {
    margin-top: 50px;
  }

  h2 {
    font-size: 50px;
    line-height: 65px;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    margin-left: 40px;
    font-family: "ProximaNova" !important;
    margin-top: 0px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 40px;
  }
  .green {
    color: #63fca3;
    text-shadow: 0px 4px 25px rgba(113, 218, 180, 0.5);
  }
  .pre-order-overview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, auto));
    row-gap: 20px;
    column-gap: 20px;
    margin-left: 40px;
    margin-top: 75px;
    margin-right: 40px;

    .green {
      background: #63fca3;
    }

    img {
      bottom: 40px;
      left: -60px;
      position: absolute;
      width: 400px;
      height: 400px;
    }
  }

  @keyframes customZoom {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.25);
    }
    100% {
      transform: scale(1);
    }
  }
}
@media only screen and (max-width: 1420px) {
  .floor5 {
    .floor-image {
      width: 450px !important;
    }
  }

  .floor-range-box {
    display: block !important;
    z-index: 5000 !important;
    left: 0 !important;
    right: 0 !important;

    .floor-range {
      display: none;
    }
  }

  .box2 {
    top: 270px !important;
  }

  .floor3 .box1 {
    top: 270px !important;
  }

  .floor2 .box2 {
    top: 320px !important;
  }

  .floor1 .box1 {
    top: 270px !important;
  }

  .lowerFloor2 .box1 {
    top: 270px !important;
  }

  .lowerFloor3 .box2 {
    top: 265px !important;
  }

  .lowerFloor4 .box1 {
    top: 270px !important;
  }

  .floor4 {
    margin-top: -240px !important;
    .floor-image {
      width: 500px !important;
    }
  }

  .pre-order .room-view .plateau1 {
    margin-top: -240px;
    img {
      width: 520px !important;
    }
  }

  .pre-order .room-view .floor3 {
    margin-top: -190px !important;
    .floor-image {
      width: 480px !important;
    }
  }

  .pre-order .room-view .plateau2 {
    margin-top: -230px;
    left: 20px;

    img {
      width: 520px !important;
    }
  }

  .pre-order .room-view .floor2 {
    margin-top: -210px !important;
    margin-left: 20px !important;

    .floor-image {
      width: 505px !important;
    }
  }

  .pre-order .room-view .floor1 {
    margin-top: -245px !important;
    margin-left: 15px;

    .floor-image {
      width: 505px !important;
    }
  }

  .pre-order .room-view .plateau3 {
    margin-top: -235px;
    left: 20px;

    img {
      width: 530px !important;
    }
  }

  .pre-order .room-view .lowerFloor1 {
    margin-top: -190px !important;
    margin-left: 10px;

    .floor-image {
      width: 505px !important;
    }
  }

  .pre-order .room-view .plateau4 {
    margin-top: -260px;
    left: 20px;

    img {
      width: 540px !important;
    }
  }

  .pre-order .room-view .lowerFloor2 {
    margin-top: -210px !important;
    margin-left: 10px;

    .floor-image {
      width: 505px !important;
    }
  }

  .pre-order .room-view .plateau5 {
    margin-top: -265px;
    left: 10px;

    img {
      width: 540px !important;
    }
  }

  .pre-order .room-view .lowerFloor3 {
    margin-top: -200px;
    margin-left: -20px;

    .floor-image {
      width: 505px !important;
    }
  }

  .pre-order .room-view .plateau6 {
    margin-top: -265px;
    left: -20px;

    img {
      width: 550px !important;
    }
  }

  .pre-order .room-view .lowerFloor4 {
    margin-top: -205px;
    margin-left: -20px;
    margin-bottom: 650px !important;

    .floor-image {
      width: 505px !important;
    }
  }

  .entrance-exterior {
    left: calc(50% + 180px) !important;

    .floor-image {
      width: 1800px !important;
    }
  }

  .helipad {
    width: 240px;
    left: -500px;
  }

  .chopper {
    left: -510px;
    width: 180px;
  }

  .carBalcony {
    width: 200px;
    top: 1500px;
    left: -460px;
  }

  .pool {
    width: 180px;
    top: 1000px;
    left: -450px;
  }

  .floor-range-box,
  .connection-line,
  .anchor {
    display: none;
  }

  .cloud5 {
    margin-top: -15px;
    width: 400px;
  }

  .cloud6 {
    width: 350px;
  }

  .cloud8 {
    margin-top: -50px;
  }

  .item1 {
    height: 250px !important;
  }

  .item2 {
    top: 450px !important;
    height: 180px !important;
  }

  .item3 {
    height: 260px !important;
    top: 630px !important;
  }

  .item4 {
    height: 435px !important;
    top: 890px !important;
  }

  .item5 {
    height: 180px !important;
    top: 1300px !important;
  }

  .item6 {
    height: 180px !important;
    top: 1465px !important;
  }

  .item7 {
    top: 1630px !important;
    height: 170px !important;
  }

  .item8 {
    top: 1810px !important;
    height: 160px !important;
  }

  .item9 {
    top: 1990px !important;
    height: 180px !important;
  }

  .kart,
  .kartLeft {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .chopper {
    display: none;
  }
  .pre-order {
    width: 100%;
    left: 0;
  }

  .pre-order {
    margin-left: 0;
    width: 98%;
    margin-left: 40px;
  }

  .button-holder {
    width: 100%;
    .whitepaper {
      float: none !important;
      margin: 0 auto !important;
      margin-top: 10px;
    }
  }

  .entrance-exterior {
    display: none;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

@media only screen and (max-width: 800px) {
  .season-toggle {
    margin-left: auto;
    margin-top: 110px;
  }
  .mobile-background {
    width: 1500px;
    top: -700px;
    left: -450px;
    right: 0;
    display: block;
  }

  .pre-order {
    // background: rgb(193, 235, 249) !important;

    .room-view {
      padding-top: 0px !important;
      padding-bottom: 40px;
    }
  }

  .pre-order .room-view .floor5,
  .pre-order .room-view .floor4,
  .pre-order .room-view .floor3,
  .pre-order .room-view .floor2,
  .pre-order .room-view .floor1,
  .pre-order .room-view .lowerFloor1,
  .pre-order .room-view .lowerFloor2,
  .pre-order .room-view .lowerFloor3,
  .pre-order .room-view .lowerFloor4 {
    width: 100%;
    height: 240px;
    float: left;
    margin: 0px !important;

    .box1,
    .box2 {
      top: 0 !important;
    }

    .floor-range-box {
      width: 80% !important;

      .floor-range {
        width: 158px !important;
        margin: 40px auto;
        margin-bottom: 0;
        display: block;
      }
    }
  }

  .pre-order {
    margin-left: 0;
  }

  .pre-order {
    width: 350px;
    float: inherit;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .pre-order {
    width: 100%;
    margin-left: 0px;
  }
  .pre-order {
    height: auto !important;
  }
  .pre-order {
    margin-top: 140px !important;
  }

  h2,
  p {
    margin-left: 0;
    width: 80% !important;
    text-align: center !important;
    margin: 0 auto;
  }

  .nft-toggle {
    top: 50px;
  }
}
</style>
