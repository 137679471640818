export default {
  56: {
    presaleAddressCharacter: "0xF80581785dB1f766d523b8C5308d21ecC95210Ef",
    presaleAddressRoom: "0x6B2A25a352A0E292E8294B81df04f967E99AD342",
    lpContract: "0xa5a1ad6dc1eb8689a96eb0eac0a8e9f104e5e682",
    tokenContract: "0x57Bc18F6177cDafFb34aCE048745bc913a1B1b54",
    marketplaceContract: "0x3aA031c38afec6cb3c7AA905A0A0A332F980eD06",
    rpc: "https://bsc-dataseed.binance.org/",
  },
  97: {
    presaleAddressCharacter: "0xF80581785dB1f766d523b8C5308d21ecC95210Ef",
    presaleAddressRoom: "0xfC1831C48479DcA2ef5F00Afb4C1ac7964a47845",
    lpContract: "0x6a88e23cf75c62926ab28c4fac8a923e2dcece0b",
    tokenContract: "0x3c268E859abE250016646BE0de15e7aa88ED7666",
    marketplaceContract: "0x6bd42Bd10C50C0caA31c883dB75E1fb0c139c42C",
    rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  },
  1284: {
    presaleAddressCharacter: "0xF80581785dB1f766d523b8C5308d21ecC95210Ef",
    presaleAddressRoom: "0xfC1831C48479DcA2ef5F00Afb4C1ac7964a47845",
    tokenContract: "0xAcc15dC74880C9944775448304B263D191c6077F",
    marketplaceContract: "0x5B86740e6FD7804C8Aa2969cD3F590f8C353d8c1",
    rpc: "https://rpc.api.moonbeam.network",
  },
};
