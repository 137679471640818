import { ethers } from "ethers";
import { toRaw } from "vue";

export enum EChainId {
  "CSPR",
  "BSC",
  "GLMR",
}

export enum EChainType {
  "EVM",
  "CASPER",
}

export enum ENetworkType {
  "TESTNET",
  "MAINNET",
}

export type TChain = {
  id: EChainId;
  network: ENetworkType;
  type: EChainType;
  name: string;
  chainId?: number;
  rpcUrl: string;
  explorerUrl: string;
  nativeCurrency: TChaincurrency;
  marketplaceCurrency: string;
};

export type TChaincurrency = {
  symbol: string;
  name: string;
  decimals: number;
};

export class SignerOrProvider {
  private readonly _signerOrProvider: ethers.Signer | ethers.providers.Provider;
  constructor(signerOrProvider: ethers.Signer | ethers.providers.Provider) {
    this._signerOrProvider = toRaw(signerOrProvider);
  }

  isSigner(): boolean {
    if (this._signerOrProvider instanceof ethers.Signer) {
      return true;
    }
    return false;
  }

  get signer(): ethers.Signer {
    if (this._signerOrProvider instanceof ethers.Signer) {
      return toRaw(this._signerOrProvider);
    }
    throw new Error(`No signer available`);
  }

  get provider(): ethers.providers.Provider {
    if (this._signerOrProvider instanceof ethers.Signer) {
      return toRaw(this._signerOrProvider).provider;
    } else if (this._signerOrProvider instanceof ethers.providers.Provider) {
      return toRaw(this._signerOrProvider);
    }
    throw new Error(`No valid type found`);
  }

  get value(): ethers.providers.Provider | ethers.Signer {
    return toRaw(this._signerOrProvider);
  }
}
