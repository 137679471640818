<template>
  <div>
    <button
      v-show="!walletStore.connected"
      class="connect-wallet"
      @click="handleWalletConnect"
    >
      Connect wallet
    </button>
    <div class="web3-info" v-show="walletStore.connected">
      <button class="disconnect" @click="handleResetApp">
        <img src="@/assets/wallet-info/log-in.svg" />
      </button>
      <div v-show="walletStore.connected" class="wallet-info">
        <chain-icon />
        <div class="vertical-line"></div>
        <busd-balance :balance="balance" />
        <div class="vertical-line"></div>
        <wallet-address :address="walletStore.wallet" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import WalletAddress from "@/components/base/wallet/WalletAddress.vue";
import BusdBalance from "@/components/base/wallet/BusdBalance.vue";
import ChainIcon from "@/components/base/wallet/ChainIcon.vue";
import { onMounted } from "vue";
import { useWalletStore } from "@/store/wallet";
import { onConnect, resetApp } from "@/chain/wallet";
import { storeToRefs } from "pinia";

const walletStore = useWalletStore();
const { setShowChainModal } = walletStore;
const { balance, chainId, connected } = storeToRefs(walletStore);

const emit = defineEmits(['close']);

onMounted(() => {
  if (connected.value) {
    onConnect(chainId.value);
  }
});

function handleWalletConnect() {
  setShowChainModal(true);
  emit('close');
}

async function handleResetApp() {
  resetApp();
}
</script>

<style scoped lang="scss">
.mobile-connect .connect-wallet {
  display: block;
}

.web3-info {
  width: 410px;
  height: 60px;
  background: grey;
  position: absolute;
  z-index: 500;
  right: 30px;
  top: 30px;
  background: linear-gradient(132.91deg, #2e2e3c 32.03%, #23232f 140.7%);
  border-radius: 10px;
  border: 1px solid rgba(113, 113, 133, 0.3);

  .vertical-line {
    height: 60px;
    width: 1px;
    float: left;
    margin-left: 10px;
    margin-right: 15px;
    background: #454558;
  }

  .disconnect {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    color: #fff;
    border: 1px solid #454558;
    background: #23232f;
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;

    img {
      margin-left: -4px;
      margin-top: 4px;
    }
  }
}

@keyframes scale10 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes scale10Back {
  0% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.connect-wallet {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px 15px 25px;
  text-align: center;
  position: absolute;
  width: 198px;
  height: 50px;
  right: 50px;
  top: 22px;
  cursor: pointer;

  background: linear-gradient(
    87.46deg,
    #3cc4d7 -3.04%,
    #6df3d3 92.88%,
    #61fea0 172.86%
  );
  border-radius: 10px;
  z-index: 5;
  text-align: center;
  border: 0;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  cursor: poiner;
  z-index: 555;
  animation: scale10Back 400ms ease forwards;

  &:hover {
    animation: scale10 400ms ease forwards;
  }
}

@media only screen and (max-width: 1024px) {
  .connect-wallet {
    bottom: 155px;
    display: none;
  }

  .mobile-connect {
    position: absolute;
    bottom: 335px;
    left: 210px;
    right: auto !important;
  }

  .activeM .web3-info {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .connect-wallet {
    height: 40px;
    line-height: 14px;
    width: 140px;
    font-size: 10px;
    bottom: 155px;
  }
}

@media only screen and (max-width: 375px) {
  .connect-wallet {
    height: 50px;
    font-size: 15px;
    width: 225px;
    left: -180px;
  }
}
</style>
