<template>
  <div class="minting">
    <h2>
      explore the<br /><span class="green robotic">Bithotel ecosystem</span>
    </h2>
    <div class="overview-minting">
      <div class="minting-item" v-motion-slide-visible-once-left>
        <div class="logo-background">
          <img src="@/assets/staking.png" />
        </div>
        <h4>Elaborate Economy</h4>
        <br />
        <p>
          In-game items and in-game currency can be earned by the player through
          completing multiplayer mini-games and challenges. Items in-game will
          have different levels of scarcity and will be freely tradeable through
          an in-game market system.
        </p>
      </div>
      <div class="minting-item" v-motion-slide-visible-right>
        <div class="logo-background">
          <img src="@/assets/fast_transaction.png" />
        </div>
        <h4>Marketplace</h4>
        <br />
        <p>
          Bit Hotel has an open marketplace where players and investors can buy
          and sell the in-game items. These in-game items consists of different
          types with each their own uses: characters, chambers and furniture.
          Every item has their own rarity and supply.
        </p>
      </div>
      <div class="minting-item" v-motion-slide-visible-left>
        <div class="logo-background">
          <img src="@/assets/madness.png" />
        </div>
        <h4>Wagered Matches</h4>
        <br />
        <p>
          Inside of the Bit Hotel you can play some mini-games against your
          friends on a wager! Chip in, queue up and get rewarded big time if you
          win! For the power users that need the adrenaline rush this mode will
          scratch your itch.
        </p>
      </div>
      <div class="minting-item" v-motion-slide-visible-once-right>
        <div class="logo-background">
          <img src="@/assets/mutliplayer.png" />
        </div>
        <h4>Socialize</h4>
        <br />
        <p>
          Bit Hotel consists of public and private rooms where player can
          interact with each other through text and voice based communication.
          Show off to your friends and send them a trade request!
        </p>
      </div>
      <div class="minting-item" v-motion-slide-visible-once-left>
        <div class="logo-background">
          <img src="@/assets/background_circle_minting.png" />
          <img src="@/assets/scoreboard.svg" class="scoreboard" />
        </div>
        <h4>Leaderboard</h4>
        <br />
        <p>
          Bit Hotel has an active leaderboard with different tiers. This
          scoreboard will show the top 100 players (in terms of wins) in the
          different mini-games, and every month the top 20 players get rewarded
          in-game currency and prizes!
        </p>
      </div>
      <div class="minting-item" v-motion-slide-visible-right>
        <div class="logo-background">
          <img src="@/assets/red_background.png" />
          <img src="@/assets/heart.svg" class="image-icon" />
        </div>
        <h4>Friendships</h4>
        <br />
        <p>
          In order to incentivize existing communities to set up gatherings and
          meet up in the Bit Hotel metaverse, players are able to add in-game
          friends to their friendlist! Invite your friends over to your room or
          mini-game lobby for some fun!
        </p>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.minting {
  width: calc(100% - 270px);
  height: 840px;
  float: left;
  position: relative;
  z-index: 1;
  left: 270px;
  text-align: left;

  h2 {
    font-size: 52px;
    line-height: 65px;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    margin-left: 40px;
    font-family: "ProximaNova" !important;
  }
  .green {
    color: #63fca3;
    text-shadow: 0px 4px 25px rgba(113, 218, 180, 0.5);
  }

  .robotic {
    font-family: "Press Start 2P", cursive !important;
    font-size: 40px !important;
    line-height: 55px !important;
    display: inline-block;
    margin-left: 5px;
  }

  .scoreboard {
    width: 57px;
    top: 52px !important;
    left: 46px !important;
    filter: brightness(0) invert(1);
  }

  .overview-minting {
    width: 95%;
    margin-left: 5%;
    height: auto;
    float: left;
    margin-top: 40px;

    .minting-item {
      width: 50%;
      height: 150px;
      float: left;
      margin-bottom: 60px;

      .logo-background {
        width: 150px;
        height: 150px;
        background: linear-gradient(90deg, #2e2e3c 33.58%, #23232f 85.82%);
        box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        position: relative;
        float: left;

        .image-icon {
          position: absolute;
          width: 70px;
          top: 59px;
          left: 38px;
          filter: brightness(0) invert(1);
        }

        img {
          position: absolute;
          top: -23px;
          left: -33px;
        }
      }

      h4 {
        float: left;
        font-family: "Press Start 2P", cursive !important;
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 38px;
        text-transform: uppercase;
        color: #fff;
        margin-left: 20px;
      }

      p {
        color: #fff;
        font-family: "ProximaNova";
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
        margin-top: 25px;
        margin-left: 170px;
        width: 60%;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .minting {
    width: 100%;
    left: 0;
  }
}

@media only screen and (max-width: 800px) {
  .minting {
    height: auto;
  }

  .minting .overview-minting .minting-item {
    width: 100%;
    height: auto;
  }

  .minting .overview-minting .minting-item h4 {
    font-size: 10px;
  }

  .minting p {
    width: 45% !important;
  }

  .minting .robotic {
    font-size: 30px !important;
    line-height: 46px !important;
  }

  .minting h2 {
    margin-left: 20px !important;
  }
}
</style>
