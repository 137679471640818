<template>
  <div class="wallet-address" @click="fallbackCopyTextToClipboard(address)">
    <img
      src="@/assets/wallet-info/wallet-address.svg"
      style="top: 0; margin-right: 5px"
      alt="Contact"
    />
    <span>{{ shortenAddress(address) }}</span>
  </div>
</template>

<script>
import {
  shortenAddress,
  fallbackCopyTextToClipboard,
} from "@/components/mixins/Address";

export default {
  name: "WalletAddress",
  props: ["address"],
  methods: {
    shortenAddress,
    fallbackCopyTextToClipboard,
  },
};
</script>

<style lang="scss" scoped>
.wallet-address {
  z-index: 55;
  color: white;
  cursor: pointer;
  float: left;
  margin-right: 15px;
  margin-top: 20px;

  span {
    margin-left: 5px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 24px;
  }
  span::hover {
    white-space: normal;
  }

  img {
    width: 20px;
    margin-top: 3px;
    float: left;
  }
}

@media only screen and (max-width: 1024px) {
  // .wallet-address {
  //   top: 185px;
  //   display: none;
  // }
}
</style>
