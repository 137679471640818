import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Building from "../views/BuyCharacters.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/buy-characters",
    name: "Buy-Characters",
    component: Building,
    meta: {
      requiresAuth: true,
      title: "Buy Characters | Bit Hotel",
    },
    props: {
      isActive: true,
    },
  },
  {
    path: "/buy-rooms",
    name: "Buy Rooms",
    component: Building,
    meta: {
      requiresAuth: true,
      title: "Building | Bit Hotel",
    },
    props: {
      isActive: false,
    },
  },
  {
    path: "/job-overview",
    name: "Job Overview",
    component: () => import("../views/JobOverview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/job/game-developer",
    name: "Job - Game Developer Overview",
    component: () => import("../views/GameDeveloper.vue"),
    meta: {
      requiresAuth: true,
      title: "Job - Game Developer Overview",
    },
  },
  {
    path: "/job/sc-engineer",
    name: "Job - Smart Contract Engineer",
    component: () => import("../views/SmartContractDeveloper.vue"),
    meta: {
      requiresAuth: true,
      title: "Job - Smart Contract Engineer",
    },
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    component: () => import("../views/Marketplace.vue"),
    meta: {
      requiresAuth: true,
      title: "Marketplace - Bit Hotel",
      metaTags: [
        {
          name: "description",
          content: "Trade your NFTs on the Bit Hotel marketplace.",
        },
        {
          property: "og:description",
          content: "Trade your NFTs on the Bit Hotel marketplace.",
        },
      ],
    },
  },
  {
    path: "/listing/:listingId",
    name: "Listing",
    component: () => import("../views/Listing.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/my-nfts",
    name: "My NFTs",
    component: () => import("../views/Marketplace.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nft/:address",
    name: "NFT",
    component: () => import("../views/NFTsingle.vue"),
    meta: {
      requiresAuth: true,
      title: "NFT Collection | Bit Hotel",
    },
  },
  {
    path: "/nft/room/:id",
    name: "Land",
    component: () => import("../views/RoomSingle.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nft/room/:address/:tokenId",
    name: "LandByToken",
    component: () => import("../views/RoomSingle.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nft/character/:address/:tokenId",
    name: "Character",
    component: () => import("../views/RoomSingle.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nft/:address/:tokenId",
    name: "NFT by Token",
    component: () => import("../views/RoomSingle.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kyc-policy",
    name: "KYC policy",
    component: () => import("../views/KYCPolicy.vue"),
    meta: {
      requiresAuth: true,
      title: "KYC policy | Bit Hotel",
    },
  },
  {
    path: "/terms",
    name: "Terms of Service",
    component: () => import("../views/Terms.vue"),
    meta: {
      requiresAuth: true,
      title: "Terms of Service | Bit Hotel",
    },
  },
  {
    path: "/eula",
    name: "End-User License Agreement",
    component: () => import("../views/EULA.vue"),
    meta: {
      requiresAuth: true,
      title: "End-User License Agreement | Bit Hotel",
    },
  },
  {
    path: "/privacy-policy",
    name: "Privacy policy",
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {
      requiresAuth: true,
      title: "Privacy Policy | Bit Hotel",
    },
  },
  {
    path: "/litepaper",
    name: "Litepaper",
    component: () => import("../views/Whitepaper.vue"),
    meta: {
      requiresAuth: true,
      title: "Litepaper | Bit Hotel",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
    meta: {
      requiresAuth: true,
      title: "Contact | Bit Hotel",
    },
  },
  {
    path: "/tokenomics",
    name: "Tokenomics",
    component: () => import("../views/Tokenomics.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/roadmap",
    name: "Roadmap",
    component: () => import("../views/Roadmap.vue"),
    meta: {
      requiresAuth: true,
      title: "Roadmap | Bit Hotel",
    },
  },
  {
    path: "/floor-range/:id/:level?",
    name: "Floor Range",
    component: () => import("../views/FloorRange.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/floor-range/:id/:level?/:roomId?",
    name: "Room in Floor Range",
    component: () => import("../views/FloorRange.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
    meta: {
      requiresAuth: true,
      title: "404 Not Found",
    },
  },
  {
    path: "/beta",
    name: "referral",
    component: () => import("../views/Referral.vue"),
    meta: {
      requiresAuth: true,
      title: "Referral | Bit Hotel",
    },
  },
  {
    path: "/referral",
    redirect: "/beta",
  },
  {
    path: '/blog',
    beforeEnter() {
      window.location.href = "https://bithotel.io/blog";
    }
  },
  {
    path: '/masteroogway', 
    beforeEnter() {
      window.location.href = "https://sweepwidget.com/c/65100-disv62ua ";
    }
  },
  { path: "/:catchAll(.*)", redirect: "/404" },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
