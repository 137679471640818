<template>
  <div class="team-item" v-motion-pop-visible>
    <img class="bg-image" src="@/assets/homepage/team_photo_2.png" />

    <div class="circle" :class="{ teamCircle: !advisor }">
      <img :src="imgUrl" />
    </div>
    <div class="team-item-info">
      <span class="name">{{ member.name }}</span>
      <span class="title">{{ member.title }}</span>
    </div>
    <div v-if="member.linkedin" class="socials">
      <a
        v-bind:href="'https://www.linkedin.com/in/' + member.linkedin"
        target="_blank"
        class="linkedin"
      >
        <img src="@/assets/homepage/linkedinIcon.png" />
      </a>
    </div>
  </div>
</template>

<script setup>
const { member, advisor } = defineProps({
  member: Object,
  advisor: {
    type: Boolean,
    default: false,
  },
});
const folder = advisor ? "advisors" : "team";
// export default {
//   props: {
//     member: Object,
//     advisor: {
//       type: Boolean,
//       default: false,
//     },
//   },
//   computed: {
//     folder: function () {
//       if (this.advisor) {
//         return "advisors";
//       }
//       return "team";
//     },
//   },
//   // TODO: import.meta.env.NODE_ENV === 'development' of import.meta.env.BASE_URL in the .env file
// };

const imgUrl = new URL(
  `../../assets/homepage/${folder}/${member.image}`,
  import.meta.url
).href;
</script>

<style scoped lang="scss">
.team-item {
  width: 33%;
  height: 350px;
  float: left;
  position: relative;
  text-align: center;
  margin-bottom: 15px;

  animation: scale10Back 400ms ease forwards;
  &:hover {
    animation: scale10 400ms ease forwards;
  }

  .teamCircle {
    width: 210px !important;
    height: 210px !important;
    border-radius: 50% !important;
    top: 37px !important;
  }

  .circle {
    $size: 192px;
    width: $size;
    height: $size;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    z-index: 10;
    top: 55px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 120%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .bg-image {
    position: absolute;
    width: 180px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 19px;
  }

  .title {
    height: 25px;
    font-family: "ProximaNova";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
    // color: linear-gradient(180deg, #2F2F42 0%, #717185 100%);
    color: linear-gradient(180deg, #2f2f42 20%, #717185 80%);

    position: absolute;
    left: 0;
    bottom: 40px;
    width: 100%;
    // color: #63FCA3;
    // text-shadow: 0px 4px 25px rgb(113 218 180 / 50%);
  }

  .name {
    font-family: "ProximaNova";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0 auto;
    width: 100%;
    float: left;
    position: absolute;
    left: 0;
    bottom: 70px;
  }

  .team-item-info {
    width: 100%;
    height: auto;
    float: left;
  }

  .socials {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 10px;

    .linkedin {
      width: 25px;
      height: 25px;
      margin: 0 auto;
      display: block;

      img {
        width: 100%;
      }
    }
  }
}
</style>
