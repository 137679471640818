import { Chain } from "@/chain/chains";
import { EChainId, SignerOrProvider } from "@/types/chain";
import { ethers } from "ethers";
import { defineStore } from "pinia";
import { ref, computed, reactive } from "vue";

const defaultRpcUrl = import.meta.env.VITE_RPC_URL;

export const useWalletStore = defineStore(
  "wallet",
  () => {
    const connectedState = ref<boolean>(false);
    const connected = computed(() => connectedState.value);
    const setConnected = (connected: boolean) => {
      connectedState.value = connected;
    };

    const chainIdState = ref<EChainId>(EChainId.BSC);
    const chainId = computed(() => chainIdState.value);
    const setChainId = (chainId: EChainId) => {
      chainIdState.value = chainId;
    };

    const _wallet = ref<string>();
    const wallet = computed(() => _wallet.value);
    const setWallet = (wallet: string) => {
      _wallet.value = wallet;
    };

    const _provider = reactive<ethers.providers.BaseProvider>(
      ethers.getDefaultProvider(defaultRpcUrl)
    );
    const provider = computed(() => _provider);
    const setProvider = (provider: ethers.providers.BaseProvider) => {
      Object.assign(_provider, provider);
    };

    const _signer = ref<ethers.Signer>();
    const signer = computed(() => _signer.value);
    const setSigner = (signer: ethers.Signer) => {
      _signer.value = signer;
    };

    const useSignerOrProvider = () => {
      if (_signer.value && _signer.value._isSigner) {
        return new SignerOrProvider(_signer.value);
      }
      return new SignerOrProvider(_provider);
    };

    const chain = computed((): Chain => {
      return new Chain(chainId.value);
    });

    const _showChainModal = ref<boolean>(false);
    const showChainModal = computed(() => _showChainModal.value);
    const setShowChainModal = (showChainModal: boolean) => {
      _showChainModal.value = showChainModal;
    };

    const _balance = ref<number>(0);
    const balance = computed(() => _balance.value);
    const setBalance = (balance: number) => {
      _balance.value = balance;
    };

    return {
      connectedState,
      connected,
      setConnected,
      chainIdState,
      chainId,
      setChainId,
      wallet,
      setWallet,
      provider,
      setProvider,
      signer,
      setSigner,
      showChainModal,
      setShowChainModal,
      useSignerOrProvider,
      chain,
      balance,
      setBalance,
    };
  },
  {
    persist: true,
  }
);
