import { CollectionRecord, collections } from "@/constants/Collections";
import contractAddresses from "@/constants/ContractAddresses";
import { useWalletStore } from "@/store/wallet";
import { Chain } from "./chains";

function getChainId(): number {
  const { chainId } = useWalletStore();
  const chain = new Chain(chainId);
  return chain.chainId;
}

export function presaleAddressCharacter() {
  return contractAddresses[getChainId()].presaleAddressCharacter;
}
export function bithotelCharacterCollections(): Array<string> {
  return Object.keys(collections[getChainId()].characters);
}
export function partnerCharacterCollections(): Array<string> {
  return Object.keys(collections[getChainId()].partnershipCharacters);
}
export function characterCollections(): Array<string> {
  return [...bithotelCharacterCollections(), ...partnerCharacterCollections()];
}
export function roomCollections(): Array<string> {
  return Object.keys(collections[getChainId()].rooms);
}

export function presaleAddressRoom(): string {
  return contractAddresses[getChainId()].presaleAddressRoom;
}
export function tokenAddress(): string {
  return contractAddresses[getChainId()].tokenContract;
}
export function lpAddress(): string {
  return contractAddresses[getChainId()]?.lpContract;
}

export function mpAddress(): string {
  return contractAddresses[getChainId()].marketplaceContract;
}
