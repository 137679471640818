export type Partner = {
  type: "Development" | "Investors_Launchpads" | "Media" | "Partners";
  image: string;
};

// add mgsto dev
// chainplay.gg to partners

export const partners: {
  [key: string]: Array<{ file: string; link?: string }>;
} = {
  Development: [
    { file: "Casper.png" },
    { file: "BnbChain.png" },
    { file: "Tezos.png" },
    { file: "Moonbeam.png" },
    { file: "Etherlink.png" },
    { file: "SkyMeta.png" },
  ],
  Investors_Launchpads: [
    { file: "BinanceNFT.png" },
    { file: "EnjinStarter.png" },
    { file: "Excalibur.png" },
    { file: "Ferrum.png" },
    { file: "FishDAO.png" },
    { file: "Forcepad.png" },
    { file: "Galler.png" },
    { file: "Gamestarter.png" },
    { file: "Lithium.png" },
    { file: "Seedify.png" },
    { file: "Superlauncher.png" },
    { file: "Tarantula.png" },
    { file: "TruePNL.svg" },
    { file: "Trustpad.webp" },
  ],
  Media: [
    { file: "AmbCrypto.png" },
    { file: "Bitcoin.png" },
    { file: "CoinCodex.png" },
    { file: "TechTimes.png" },
    { file: "UToday.png" },
    { file: "Yahoo.png" },
  ],
  Partners: [
    { file: "Alpaca.png" },
    { file: "Animalia.png" },
    { file: "Aradena.png" },
    { file: "Formless.png" },
    { file: "Getkicks.png" },
    { file: "Kapital.png" },
    { file: "Kleoverse.png" },
    { file: "Kommunitas.png" },
    { file: "LTO.svg" },
    { file: "Metagods.png" },
    { file: "Metasoccer.png" },
    { file: "Safegram.png" },
    { file: "Wasder.png" },
    { file: "ChainPlay.svg" },
  ],
};
