// we need our modal component
import Modal from "@/components/plugin/Modal.vue";

export default {
  // every plugin for Vue.js needs install method
  // this method will run after Vue.use(<your-plugin-here>) is executed
  install(app) {
    app.component("modal", Modal);

    const showModal = (params) => {
      app.config.globalProperties.emitter.emit("showGlobalModal", {
        visible: true,
        ...params,
      });
    };

    // https://stackabuse.com/how-to-create-a-confirmation-dialogue-in-vue-js/
    // https://stackoverflow.com/questions/66583752/vue-async-await-with-emit
    const showModalAsync = async (params) => {
      console.log("test");
      return new Promise((resolve, reject) => {
        app.config.globalProperties.emitter.emit("showGlobalModal", {
          visible: true,
          ...params,
          callBackFunc: async (confirmed) => {
            if (confirmed) {
              resolve(true);
            } else {
              resolve(false);
              reject(false);
            }
          },
        });
      });
    };

    const hideModal = () => {
      app.config.globalProperties.emitter.emit("showGlobalModal", {
        visible: false,
      });
    };

    app.config.globalProperties.$showModal = showModal;
    app.provide("$showModal", showModal);

    app.config.globalProperties.$showModalAsync = showModalAsync;
    app.provide("$showModalAsync", showModalAsync);

    app.config.globalProperties.$hideModal = hideModal;
    app.provide("$hideModal", hideModal);
  },
};
