<script setup lang="ts">
import { computed } from "vue";

const { width, height } = defineProps({
  width: {
    type: Number,
    default: 50,
  },
  height: {
    type: Number,
    default: 50,
  },
});
const size = computed(() => {
  return {
    width: width + "px",
    height: height + "px",
  };
});
</script>
<template>
  <div class="loading" v-bind:style="size">
    <div></div>
    <div></div>
  </div>
</template>

<style scoped>
.loading {
  display: inline-block;
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.loading div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes loading {
  0% {
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>
