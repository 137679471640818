<script setup lang="ts">
import RarityDots from "../landsale/RarityDots.vue";
import Loading from "../base/Loading.vue";
import { getGif, getImage } from "../../plugins";
import { ref, onMounted, watch } from "vue";
import { Nft } from "@/types";
import { notify } from "@kyvg/vue3-notification";
import { useRouter } from "vue-router";
import { useWalletStore } from "@/store/wallet";
import { PresaleV2 } from "@/chain/presale/v2";
import { EVMToken } from "@/chain/token/evm";
import { storeToRefs } from "pinia";
import { BaseNft } from "@/chain/types/nft";

const { nft } = defineProps<{
  nft: BaseNft;
  quantity: number;
}>();

const releasedText = ref("To be released");
const currency = ref("BTH");
const whitelist = ref<boolean>(true);
const isWhitelisted = ref<boolean>(false);
const loading = ref<boolean>(true);
const presaleAddress = import.meta.env.VITE_CHARACTER_PRESALE_V2;
const busdAddress = import.meta.env.VITE_BUSD_ADDRESS;
let presale = ref<PresaleV2>();
let token = ref<EVMToken>();

const router = useRouter();

const walletStore = useWalletStore();
const { wallet, connected } = storeToRefs(walletStore);

releasedText.value = "Released";
currency.value = "BUSD";

watch(wallet, () => {
  // console.log("initializing contracts...");
  const signerOrProvider = walletStore.useSignerOrProvider();
  const _token = new EVMToken(busdAddress, signerOrProvider);
  token.value = _token;
  const _presale = new PresaleV2(presaleAddress, signerOrProvider);
  presale.value = _presale;
});

onMounted(() => {
  checkWhitelist();
  loading.value = false;
});

function getBtnString() {
  if (!connected.value) return "Connect";
  else return "Buy";
}
function onClick() {
  router.push({
    name: "NFT",
    params: {
      address: nft.address,
    },
  });
}
async function checkWhitelist() {
  if (walletStore.connected && nft.season == "Season 2") {
    whitelist.value = await presale.value.isWhitelistEnabled();
    if (whitelist) {
      isWhitelisted.value = await presale.value.isWhitelisted();
    }
  }
}
async function onNftClick(nft: BaseNft): Promise<void> {
  if (!walletStore.connected) {
    walletStore.setShowChainModal(true);
    return;
  }
  if (nft.season == "Season 2") {
    await buyNft(nft);
  }
}
async function buyNft(nft: BaseNft) {
  try {
    loading.value = true;
    await token.value.approve(presaleAddress, nft.price);
    await presale.value.buy(nft.address, nft.price);
    notify({
      text: "Purchase succeeded.",
      type: "success",
    });
    loading.value = false;
  } catch (e) {
    console.log(e.message);
    notify({
      text: "Payment failed.",
      type: "warn",
    });
  }
  loading.value = false;
}
function onSweepClick() {
  window.open("https://sweepwidget.com/view/57183-et7pvam2", "_blank");
}
</script>

<template>
  <div class="pre-order-item">
    <div class="price">Price</div>
    <div class="price-box">
      {{ nft.price }}
      <span style="font-size: 12px">{{ currency }}</span>
    </div>
    <div class="rarity">{{ nft.rarity }}</div>
    <RarityDots :rarity="nft.rarity" />

    <div class="image-holder" @click="onClick">
      <a class="stats">Check properties</a>
      <img
        class="karakter"
        :class="nft.name.toLowerCase().replaceAll(' ', '')"
        :src="getImage('nfts', nft.name)"
      />
      <img class="flame" :src="getGif('backgrounds', nft.rarity)" />
      <img
        v-if="nft.name == 'Grumpy Nyan Cat'"
        class="grumpy"
        src="@/assets/backgrounds/Sterren_GIF.gif"
      />
    </div>
    <div class="release">
      <div class="holder">
        <div class="release-title">{{ nft.name }}</div>
        <div class="release-date">{{ releasedText }}</div>

        <div class="button-holder" v-if="quantity > 0">
          <div class="whitepaper">
            <button @click="onNftClick(nft)">
              <span v-if="!loading">{{ getBtnString() }}</span>
              <Loading :width="30" :height="30" v-else />
            </button>
            <div class="fade"></div>
          </div>
        </div>
        <div style="opacity: 0.5" class="button-holder">
          <div class="whitepaper">
            <button style="cursor: inherit">Buy</button>
            <div class="fade"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="quantity">Quantity</div>
    <div class="quantity-box">
      <span class="red">{{
        Number.isInteger(quantity) ? quantity : "..."
      }}</span
      >/{{ nft.replicas }}
    </div>
  </div>
</template>

<style lang="scss">
.pre-order-overview {
  margin-left: 40px;
  margin-top: 40px;

  .pre-order-item {
    height: 350px;
    float: left;
    background: linear-gradient(180deg, #2e2e3c 55.66%, #23232f 125.89%);
    box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    position: relative;

    .button-holder {
      height: 60px;
      width: 100px;
      position: absolute;
      right: 20px;
      top: 2px;

      .whitepaper {
        height: 60px;
        width: 100px;
        margin-left: 5%;
        position: relative;
        button:disabled {
          cursor: not-allowed;
        }
        button {
          cursor: pointer;
          display: block;
          text-decoration: none;
          height: 40px;
          width: 100px;
          line-height: 40px;
          margin-left: 1%;
          margin-top: 7px;
          text-transform: uppercase;
          text-align: center;
          font-size: 12px;
          font-weight: 600;
          background: linear-gradient(
            87.46deg,
            #3cc4d7 -3.04%,
            #6df3d3 92.88%,
            #61fea0 172.86%
          );
          border-radius: 10px;
          border: 0;
          color: #fff;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 55;
        }

        .fade {
          height: 50px;
          width: 105px;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(
            358.84deg,
            #3cc4d7 -38.41%,
            rgba(109, 243, 211, 0.5) 17.27%,
            rgba(97, 254, 160, 0) 63.69%
          );
          border-radius: 10px;
        }
      }
    }
  }

  .price,
  .quantity,
  .rarity {
    font-family: "ProximaNova" !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #717185;
    position: absolute;
  }

  .rarity {
    top: 15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }

  .gold {
    background: linear-gradient(
      90deg,
      #fcb819 33.58%,
      #f99124 85.82%
    ) !important;
    // background: #fcb819 !important;
  }

  .rare {
    background: linear-gradient(
      90deg,
      #960de7 33.58%,
      #7418f6 85.82%
    ) !important;
  }

  .common {
    background: linear-gradient(
      90deg,
      #00d1e6 33.58%,
      #00dacb 85.82%
    ) !important;
  }

  .super_rare {
    background: linear-gradient(
      90deg,
      #e15288 33.58%,
      #d941a9 85.82%
    ) !important;
  }

  .epic {
    background: linear-gradient(
      90deg,
      #f3793d 33.58%,
      #e4597b 85.82%
    ) !important;
  }

  .uncommon {
    background: linear-gradient(
      90deg,
      #00e4aa 33.58%,
      #00e999 85.82%
    ) !important;
  }

  .price {
    top: 15px;
    left: 32px;
  }

  .quantity {
    top: 15px;
    right: 25px;
  }

  .price-box,
  .quantity-box {
    width: 95px;
    height: 35px;
    background: linear-gradient(90deg, #2e2e3c 33.58%, #23232f 85.82%);
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 35px;
    font-family: "ProximaNova" !important;
    position: absolute;
  }

  .price-box {
    left: 10px;
    top: 35px;
  }

  .quantity-box {
    right: 15px;
    top: 35px;
    width: 70px;
  }

  .red {
    color: #f16969;
  }

  .rarity-holder {
    width: 78px;
    height: 10px;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .rarity-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: linear-gradient(90deg, #2e2e3c 33.58%, #23232f 85.82%);
    float: left;
    margin-right: 3px;
  }
  .green {
    background: #63fca3;
  }

  img {
    bottom: 40px;
    left: -60px;
    position: absolute;
    width: 400px;
    height: 400px;
  }

  .release {
    height: 60px;
    width: 90%;
    margin-left: 5%;
    background: linear-gradient(90deg, #2e2e3c 33.58%, #23232f 85.82%);
    border-radius: 5px;
    position: absolute;
    bottom: 10px;

    .holder {
      width: 100%;
      height: 60px;
      float: left;
      position: relative;
    }
    .release-title {
      font-family: "ProximaNova";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      color: #fff;
      float: left;
      margin-top: 15px;
      margin-left: 10px;
    }

    .release-date {
      font-family: "ProximaNova";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #717185;
      position: absolute;
      bottom: 10px;
      left: 10px;
      text-transform: uppercase;
    }
  }
}
.karakter {
  width: auto !important;
  height: 300px !important;
  bottom: 0px !important;
  margin-left: auto;
  margin-right: auto;
  left: 0 !important;
  right: 0 !important;
  z-index: 5;
  animation: customZoom infinite;
  animation-duration: 3s;
  top: 70px;
}
.grumpynyancat {
  height: 230px !important;
}

.greyOverlay {
  width: 100%;
  height: 10px;
  background: linear-gradient(180deg, #2e2e3c 55.66%, #23232f 125.89%);
  position: absolute;
  left: 0;
  bottom: 70px;
  z-index: 55;
}

.flame {
  width: 100% !important;
  height: auto !important;
  margin: auto !important;
  position: absolute;
  top: 0 !important;
  bottom: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  z-index: 3;
  opacity: 1;
}

.grumpy {
  position: absolute;
  width: 200px !important;
  left: 0;
  right: -25px;
  margin-left: auto;
  margin-right: auto;
  z-index: 4;
}

.image-holder {
  width: 100% !important;
  height: 190px !important;
  position: relative;
  top: 80px;
  overflow: hidden;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.stats {
  position: absolute;
  top: 10px;
  left: 20px;
  padding: 5px 8px;
  border: 0;
  border-radius: 15px;
  font-size: 12px;
  display: block;
  text-decoration: none;
  color: #fff;
  z-index: 55;
  background: linear-gradient(
      264.06deg,
      #c63b3b -68.99%,
      #d174e0 40.01%,
      #70baff 130.88%
    ),
    linear-gradient(180deg, #c63b3b 0%, #d174e0 54.53%, #70baff 100%);
}

@keyframes customZoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
