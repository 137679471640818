import { CopyToClipboard } from "@/plugins";
import { notify } from "@kyvg/vue3-notification";

export function fallbackCopyTextToClipboard(text: string) {
  CopyToClipboard(text);
  if (text.length > 20) {
    text = text.substring(0, 20) + "...";
  }
  notify({
    text: "Copied to clipboard: " + text,
    type: "success",
  });
}
export function shortenAddress(address: string) {
  return typeof address == "string"
    ? address.slice(0, 5) + "..." + address.slice(-3)
    : address;
}
