<template>
  <div>
    <div class="top-header" v-show="correctRoute">
      <a target="_blank" class="left" href="https://staking.bithotel.io">
        <img src="@/assets/token-design.png" />Stake $BTH
      </a>
      <div class="middle" @click="openModal()">
        <img src="@/assets/ramp-logo-PhotoRoom.png" class="uplink" />
        Top Up Crypto
      </div>
      <a
        class="right"
        target="_blank"
        href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x57Bc18F6177cDafFb34aCE048745bc913a1B1b54"
      >
        <img src="@/assets/pancakeswap.png" />Trade $BTH
      </a>
    </div>
    <chain-options :show="showChainModal" :chains="chains" />
    <router-view />
    <Modal />
    <notifications position="bottom right" width="500" />
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, provide } from "vue";
import { getClickId } from "./api";
import { useRoute } from "vue-router";
import { watchEffect, inject } from "vue";
import { useStore } from "./store";
import ChainOptions from "./components/base/wallet/ChainOptions.vue";
import { useWalletStore } from "./store/wallet";
import Modal from "./components/plugin/Modal.vue";
import { storeToRefs } from "pinia";
import { ENetworkType } from "./types/chain";
import { supportedChains } from "./chain/chains";

const showModal: any = inject("$showModal");
const showModalAsync = inject("$showModalAsync");
const hideModal = inject("$hideModal");
provide("pluginModal", {
  showModal,
  showModalAsync,
  hideModal,
});

const route = useRoute();
const { setClickId } = useStore();
const walletStore = useWalletStore();
const { showChainModal } = storeToRefs(walletStore);

const correctRoute = computed(() => {
  return route.name == "Home" || route.name == "Roadmap";
});

const environment =
  import.meta.env.VITE_ENV == "dev"
    ? ENetworkType.TESTNET
    : ENetworkType.MAINNET;
const chains = supportedChains.filter((chain) => chain.network == environment);

watchEffect(async () => {
  if (route.query.ref !== undefined) {
    const id = await getClickId(route.query.ref[0]);
    setClickId(id);
  }
});

onMounted(() => {
  if (import.meta.env.VITE_ENV == "prod") {
    console.log(
      "%c What are you waiting for? Join us!",
      "color: white; font-size:32px;"
    );
    console.log(
      "%c We're always looking for good developers. Apply now! https://bithotel.io/#/job-overview",
      "font-size:20px;"
    );
  }
});
function openModal() {
  // <iframe src="https://www.fiat2bnb.com?integration=4&embed=1" width="100%" height="100%" frameBorder="0" sandbox="allow-top-navigation allow-same-origin allow-scripts allow-forms" scroll="no" style="overflow: hidden"/>

  showModal({
    title: `Which crypto would you like to top up?`,
    text: `Acquire the BTH token with credit card on Uplink, or use RAMP to get the BNB gas token`,
    rightButtonText: "Buy BTH",
    leftButtonText: "Buy BNB",
    rightButtonFunc: () => {
      window.open("https://fiat2bnb.com/?integration=4&embed=1", "_blank");
    },
    leftButtonFunc: () => {
      window.open(
        "https://buy.ramp.network/?hostAppName=BitHotel&hostApiKey=too44ymzkd5s5syutbzkcsncry2xd4mu34x594af&defaultAsset=BSC_BNB&hostLogoUrl=https://storage.googleapis.com/media.bithotel.io/logo.png",
        "_blank"
      );
    },
    icon: new URL(`assets/elon_on_rocket.png`, import.meta.url).href,
    iconWidth: "300px",
  });
}
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

@font-face {
  font-family: ProximaNova;
  font-weight: 700;
  src: url("./assets/fonts/ProximaNova-Bold.otf") format("opentype");
}

@font-face {
  font-family: ProximaNova;
  font-weight: 900;
  src: url("./assets/fonts/ProximaNova-Extrabold.otf") format("opentype");
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

.vue-notification {
  font-size: 18px !important;
}

/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    #3cc4d7 9.56%,
    #6df3d3 109.69%,
    #61fea0 193.17%
  ) !important;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    90deg,
    #3cc4d7 9.56%,
    #6df3d3 109.69%,
    #61fea0 193.17%
  ) !important;
}

.top-header {
  width: 100%;
  height: 60px;
  background: black;
  font-size: 20px;
  font-family: ProximaNova !important;

  img {
    width: 25px;
    margin: -4px auto;
    margin-right: 5px;
  }

  .left,
  .right,
  .middle {
    width: 33.3%;
    height: 60px;
    float: left;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    line-height: 60px;
    color: #fff;
  }

  .uplink {
    filter: brightness(0) invert(1);
  }

  .middle {
    cursor: pointer;
    background: linear-gradient(
      90.06deg,
      #8b48c3 -68.99%,
      #7d66d7 40.01%,
      #6da4ee 110.88%
    );
  }

  .left {
    background: linear-gradient(
      264.06deg,
      #c63b3b -68.99%,
      #d174e0 40.01%,
      #70baff 130.88%
    );

    box-shadow: 0px 4px 25px rgb(184 113 218 / 50%);

    img {
      margin-right: 6px;
      margin-bottom: -6px;
    }
  }

  .right {
    // box-shadow: 0px 4px 25px rgb(184 113 218 / 50%);
    background: linear-gradient(
      87.46deg,
      #3cc4d7 -3.04%,
      #6df3d3 92.88%,
      #61fea0 172.86%
    );
  }
}

@media only screen and (max-width: 1024px) {
  .top-header {
    height: auto;

    .middle,
    .left,
    .right {
      width: 100%;
    }
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html {
  // background: linear-gradient(180deg, #2F2F42 0%, #717185 100%);
  background: #23232f;
  min-height: 100vh;
  font-family: "Dosis", sans-serif !important;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  max-width: 100%;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100vw;
  max-width: 100%;
  height: auto;

  h2 {
    font-size: 52px;
    line-height: 65px;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    font-family: "ProximaNova" !important;
  }
}

#app {
  overflow-x: hidden;
  max-width: 100%;
  width: 100vw;
  height: auto;
}

@media only screen and (max-width: 800px) {
  h2 {
    font-size: 36px !important;
    line-height: 50px !important;
  }
}

.abs {
  position: absolute;
}

.rel {
  position: relative;
}

.auto-h {
  height: auto;
}

.auto-wh {
  width: auto;
  height: auto;
}

.mauto {
  margin-right: auto;
  margin-left: auto;
}

// Simple button scaling
@keyframes scale10 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes scale10Back {
  0% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

// Scaling on homepage
@keyframes scale05 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.05);
  }
}

@keyframes scale05Back {
  0% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}
</style>
