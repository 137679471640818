import { collections } from "@/constants/Collections";
import { UpdatedBetaCode, BetaCodeEmail } from "@/types";
import {
  EChainId,
  EChainType,
  ENetworkType,
  SignerOrProvider,
} from "@/types/chain";
import { EVMNFT } from "@/chain";
import { ethers } from "ethers";
import { EvmChain, EvmNft } from "@moralisweb3/evm-utils";
import Moralis from "moralis";
import { Chain } from "@/chain/chains";
import { BaseNft } from "@/chain/types/nft";
import { getKeyValue, getTraitValue } from "@/plugins";

const gateway = import.meta.env.VITE_API_ROOMS;
const snooze = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

Moralis.start({
  apiKey: import.meta.env.VITE_MORALIS_API_KEY,
});
/**
 * This function is used to get the character nfts for the Buy NFTs page.
 * it uses the backend enum:
 * enum Category {
    None = 0,
    Character,
    Room,
    Office,
    Furniture,
    Wearable,
    Badge,
    Consumable,
    Background,
  }
 * @returns {Promise<string>}
 */
export async function getCollectionsByCategory(
  category: number = 1,
  chainId: number,
) {
  try {
    //TODO: get metadata per collection, but is not always the same throughout the whole collection
    const result = await fetch(
      `${gateway}/collection/category/${category}?chain-id=${chainId}`,
    );
    return await result.json();
  } catch (e) {
    console.log(e);
    await snooze(500);
    return await getCollectionsByCategory(category, chainId);
  }
}

/**
 *
 * @param {string} address of the collectionAddress
 * @param {int} tokenId of the specific nft otherwise just the first nft
 * @returns Promise<string>
 */
export async function getNftByAddress(
  address: string,
  tokenId: number = 1,
): Promise<any> {
  try {
    const result = await fetch(`${gateway}/nft/address/${address}/${tokenId}`);
    return await result.json();
  } catch (e) {
    throw new Error(e);
  }
}

export async function getRoom(id: string): Promise<any> {
  try {
    const result = await fetch(`${gateway}/room/${id}`);
    return await result.json();
  } catch (e) {
    throw new Error(e);
  }
}

export async function getRoomByToken(
  address: string,
  tokenId: number,
): Promise<any> {
  try {
    const result = await fetch(`${gateway}/room/${address}/${tokenId}`);
    return await result.json();
  } catch (e) {
    throw new Error(e);
  }
}

export async function postRoomImage(
  body: object,
  endpoint: string,
): Promise<any> {
  try {
    const url = `${gateway}/upload/${endpoint}`;
    delete body["currentImageHash"];
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (res.status == 200 || res.status == 201) {
      return true;
    } else {
      return await res.json();
    }
  } catch (e) {
    throw new Error(e);
  }
}

export async function updateRoomImage(
  body: object,
  imageId: string,
  endpoint: string,
): Promise<any> {
  try {
    const url = `${gateway}/upload/${endpoint}/${imageId}`;
    const res = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (res.status == 200 || res.status == 201) {
      return true;
    } else {
      return await res.json();
    }
  } catch (e) {
    throw new Error(e);
  }
}

export async function deleteRoomImage(
  body: object,
  imageId: string,
  endpoint: string,
) {
  try {
    const url = `${gateway}/upload/${endpoint}/${imageId}`;
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (res.status == 200 || res.status == 201) {
      return true;
    } else {
      return await res.json();
    }
  } catch (e) {
    throw new Error(e);
  }
}

export async function getFloor(id: string): Promise<any> {
  try {
    const result = await fetch(`${gateway}/floor/${id}`);
    return await result.json();
  } catch (e) {
    throw new Error(e);
  }
}

export async function getFloorRange(floorRangeId: string): Promise<any> {
  try {
    const result = await fetch(`${gateway}/floor-range/${floorRangeId}`);
    return await result.json();
  } catch (e) {
    throw new Error(e);
  }
}

export async function getBuilding(): Promise<any> {
  try {
    const result = await fetch(`${gateway}/building/name/bithotel`);
    return await result.json();
  } catch (e) {
    console.log(e);
  }
}

export async function getListings(
  filters: object = {},
  pageNumber: number = 0,
  chain: Chain,
): Promise<any> {
  if (chain.type == EChainType.CASPER) {
    return await getCasperListings();
  }
  let retries = 0,
    listings;
  while (retries < 5) {
    try {
      listings = await (
        await fetch(
          `${gateway}/listing/page/${pageNumber}?chain-id=${chain.chainId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(filters),
          },
        )
      ).json();
      break;
    } catch (e) {
      console.log(e + " in getListings in api/index.js");
      await snooze(10000);
      retries++;
    }
  }
  return listings;
}

export async function getMyListings(
  wallet: string,
  chainId: number,
): Promise<any> {
  try {
    const result = await fetch(
      `${gateway}/listing/seller/${wallet}?chain-id=${chainId}`,
    );
    return await result.json();
  } catch (e) {
    throw new Error(e);
  }
}

export async function getListingPageCount(
  filters: object = {},
  chainId: number,
): Promise<any> {
  let retries = 0,
    listings;
  while (retries < 5) {
    try {
      listings = await (
        await fetch(`${gateway}/listing/page-count?chain-id=${chainId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(filters),
        })
      ).json();
      break;
    } catch (e) {
      console.log(e + " in getListings in api/index.js");
      await snooze(10000);
      retries++;
    }
  }
  return listings;
}

export async function getListing(
  listingId: number,
  chainId: number,
): Promise<any> {
  try {
    const result = await fetch(
      `${gateway}/listing/${listingId}?chain-id=${chainId}`,
    );
    return await result.json();
  } catch (e) {
    throw new Error(e);
  }
}

export async function getListingsBySeller(
  seller: string,
  chainId: number,
): Promise<any> {
  try {
    if (!seller) {
      throw new Error("Please enter a valid seller address");
    }

    const result = await fetch(
      `${gateway}/listing/seller/${seller}?chain-id=${chainId}`,
    );

    //DEV purposes
    // const result = await fetch(
    //   `https://apigateway.bithotel.io/listing/seller/${seller}?chain-id=56`
    // );

    return await result.json();
  } catch (e) {
    console.log(e.message, " In getListingsBySeller function");
    throw new Error(e);
  }
}

export async function getExListingsByNft(
  nftAddress: string,
  tokenId: number | string,
  chainId: number,
  page?: number,
): Promise<any> {
  let result: any;
  try {
    if (!nftAddress || !tokenId) {
      throw new Error("Please enter a the required arguments");
    }

    if (page) {
      result = await fetch(
        `${gateway}/listing/executed/${nftAddress}/${tokenId}?chain-id=${chainId}&page=${page}`,
      );
    } else {
      result = await fetch(
        `${gateway}/listing/executed/${nftAddress}/${tokenId}?chain-id=${chainId}`,
      );
    }

    return await result.json();
  } catch (e) {
    console.log(e.message, " In getExListingsByNFT function");
    throw new Error(e);
  }
}

// TODO: set all NFTs in vuex store and change myNFTs getter to return based on chainId
// currently, we have to call this function each time we switch from chain
export async function getMyNFTs(
  provider: ethers.providers.Provider,
  wallet: string,
  chainId: EChainId,
): Promise<Array<BaseNft>> {
  const signerOrProvider = new SignerOrProvider(provider);
  const chain = new Chain(chainId);
  const networkType =
    import.meta.env.VITE_ENV == "dev"
      ? ENetworkType.TESTNET
      : ENetworkType.MAINNET;
  if (chain.id == EChainId.BSC) {
    // BSC and BSC Testnet
    let chainParam = EvmChain.BSC;

    if (networkType == ENetworkType.TESTNET) {
      chainParam = EvmChain.BSC_TESTNET;
    }

    try {
      let unfilteredNfts: Array<EvmNft> = [];
      const cursor = "";
      let response = await Moralis.EvmApi.nft.getWalletNFTs({
        address: wallet,
        chain: chainParam,
        cursor,
        format: "decimal",
      });

      unfilteredNfts = response.result;

      const unfilteredBaseNfts = unfilteredNfts.map(
        (nft): Omit<BaseNft, "type"> => {
          // @ts-ignore
          if (!nft.metadata?.attributes) {
            return null;
          }
          // @ts-ignore
          const attributes = nft.metadata.attributes;
          return {
            address: nft.tokenAddress.lowercase,
            tokenId: +nft.tokenId,
            description: "temp",
            owner: nft.ownerOf.lowercase,
            season: getTraitValue(attributes, "Drop"),
            image: nft.tokenUri,
            name: nft.name,
            replicas: getTraitValue(attributes, "Replicas"),
            rarity: getTraitValue(attributes, "Rarity"),
          };
        },
      );
      return filterNfts(
        unfilteredBaseNfts.filter((i) => !!i),
        chain,
      );
    } catch (e) {
      throw new Error(e);
    }
  } else if (chain.type == EChainType.EVM) {
    const chainCollections = collections[chainId][networkType];
    // Other Chains - Without block explorer API
    // No blockexplorer, get all NFTs by contract call
    const rooms = Object.keys(chainCollections.rooms);
    const characters = Object.keys(chainCollections.characters);
    const partnershipCharacters = Object.keys(
      chainCollections.partnershipCharacters,
    );

    // Request how many NFT user owns
    const collectionList = [...rooms, ...characters, ...partnershipCharacters];
    const myCharacters = await EVMNFT.getNftBalances(
      collectionList,
      wallet,
      signerOrProvider,
    );

    const tempNfts = [];
    let _collections = [];

    for (const nft of myCharacters) {
      let ipfsUri;

      if (rooms[nft.address]) {
        ipfsUri = rooms[nft.address];
      } else if (characters[nft.address]) {
        ipfsUri = characters[nft.address];
      } else {
        ipfsUri = partnershipCharacters[nft.address];
      }

      _collections.push(createCollectionObject(nft, ipfsUri));
    }

    _collections = await Promise.all(_collections);
    for (const collection of _collections) {
      for (let i = 0; i < collection.amountOwned; i++) {
        const nft = new EVMNFT(collection.address, signerOrProvider);
        // Make this concurrent!!
        const tokenId = await nft.getTokenIdByIndex(collection.address, i);

        tempNfts.push({
          token_address: collection.address,
          metadata: collection.metadata,
          token_id: tokenId,
        });
      }
    }

    return tempNfts;
  } else if (chain.type == EChainType.CASPER) {
    const chainCollections = collections[chainId][networkType];
    const rooms = Object.keys(chainCollections.rooms);
    const characters = Object.keys(chainCollections.characters);
    const partnershipCharacters = Object.keys(
      chainCollections.partnershipCharacters,
    );

    const collectionList = [...rooms, ...characters, ...partnershipCharacters];

    const url = `${
      import.meta.env.VITE_CSPR_API
    }/accounts/0c8e963f9430b70086632e099e7fa1f0780da089171fc533fa38f1bca26cf4e9/nft-tokens?page=1&limit=100`;
    const response = await (await fetch(url)).json();
    const myCsprNfts = response.data;
    const typedNfts = myCsprNfts.map((nft): BaseNft => {
      if (!nft.onchain_metadata.attributes) {
        return null;
      }
      return {
        address: nft.contract_package_hash,
        tokenId: nft.token_id,
        description: "temp",
        image: nft.onchain_metadata.image,
        name: nft.onchain_metadata.name,
        rarity: getTraitValue(nft.onchain_metadata.attributes, "Rarity"),
        replicas: 123,
        season: "Season 2",
      };
    });
    return filterNfts(
      typedNfts.filter((i) => !!i),
      chain,
    );
  }
  throw new Error(`Invalid chain`);
}

const filterNfts = async function (
  _nfts: Array<Omit<BaseNft, "type">>,
  chain: Chain,
): Promise<Array<BaseNft>> {
  let collections;
  // this is dirty
  if (chain.id == EChainId.CSPR) {
    collections = [
      {
        address:
          "fcb663f602b65f729070cf663742050403e2b1a1c2778ed408bae739856e177c",
        category: {
          name: "character",
        },
      },
    ];
  } else {
    collections = await getCollections(chain.chainId);
  }
  let nfts: Array<BaseNft> = [];
  for (let nft of _nfts) {
    let collection = collections.find(
      (c) => c.address.toLowerCase() == nft.address,
    );
    if (collection) {
      nfts.push({ ...nft, type: collection.category.name });
    }
  }
  return nfts;
};

async function createCollectionObject(element: any, ipfsUri: string) {
  const address = element.address;
  const collection = {
    metadata: await getMetadata(ipfsUri),
    ipfsUri,
    address,
    amountOwned: element["amount"],
  };
  return collection;
}

async function getMetadata(uri: string) {
  if (uri.includes("infura")) {
    uri = uri.replace("ipfs.infura.io/ipfs", "apigateway.bithotel.io/metadata");
  }
  let result = await fetch(uri);
  result = await result.json();
  return JSON.stringify(result);
}

export async function getCollections(chainId: number) {
  let collections;
  try {
    collections = await fetch(`${gateway}/collection/?chain-id=${chainId}`);
    return await collections.json();
  } catch (e) {
    console.log(e);
    await snooze(1000);
    return await getCollections(chainId);
  }
}

export async function collectMail(email: string) {
  let result;
  try {
    result = await fetch(`${gateway}/mail`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    return result;
  } catch (e) {
    console.log(e);
  }
}

export async function getBetaCode(
  code: string,
): Promise<{ [key: string]: string } | null> {
  try {
    // const gateway = "http://localhost:3000/api";
    let data = await fetch(`${gateway}/betaCode/${code}`);
    let betaCode = await data.json();

    if (betaCode?.statusCode == 400) {
      data = await fetch(`${gateway}/infinite-betacodes/${code}`);
      betaCode = await data.json();
    }
    return betaCode;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function useBetaCode(
  code: string,
): Promise<UpdatedBetaCode | null> {
  try {
    if (!code) {
      throw new Error("Please enter a beta code");
    }
    // const gateway = "http://localhost:3000/api";
    const resp = await fetch(`${gateway}/betaCode/${code}`, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "api-key": import.meta.env.VITE_REFERRAL_API_KEY,
      },
    });

    const data: UpdatedBetaCode = await resp.json();
    console.log(data);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function sendBetaConfirmation(
  mail: BetaCodeEmail,
): Promise<boolean> {
  const body = {
    to: mail.email,
    from: "no-reply@bithotel.io",
    referralCode: mail.referralCode,
    username: mail.username,
    password: `${mail.password}`,
  };

  try {
    if (!body.to || !body.referralCode || !body.password || !body.username) {
      throw new Error("Please enter all the required fields");
    }

    const res = await fetch(`${gateway}/mail/betacode`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": window.origin,
      },
      body: JSON.stringify(body),
    });
    const { succes } = await res.json();
    return succes;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function buyBTHpackage(
  userId: string,
  packageSize: string,
  clickId: string = "",
) {
  let url =
    import.meta.env.VITE_API_ROOMS +
    `/payment/createTransaction?userId=${userId}&bthPackage=${packageSize}`;
  if (clickId != "") {
    url += `&clickId=${clickId}`;
  }

  const res = await fetch(url, {
    method: "GET",
  });

  const resObject = await res.json();

  window.open(resObject.paymentURL, "_blank");
}

export async function getClickId(referralCode: string): Promise<string> {
  const url = `https://api.tapfiliate.com/1.6/clicks/`;

  const body = {
    referral_code: referralCode,
  };

  const res = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": "075e6f8081bfccbaf92325401142538bb20883ce",
    },
    body: JSON.stringify(body),
  });

  const resObject = await res.json();

  return resObject.id;

  // const url1 = `https://api.tapfiliate.com/1.6/conversions/`;

  // const body1 = {
  //   click_id: resObject.id,
  //   external_id: "bnalkjbnasbdfkljs",
  //   amount: 10,
  // };

  // const res1 = await fetch(url1, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     "Api-Key": "075e6f8081bfccbaf92325401142538bb20883ce",
  //   },
  //   body: JSON.stringify(body1),
  // });

  // const resObject1 = await res1.json();
  // console.log(resObject1);
}

export async function createUser(args: {
  username?: string;
  email: string;
  password?: string;
  betaCode?: string;
  coords?: { lat?: number; lon?: number };
}): Promise<any> {
  try {
    const { email } = args;

    if (!email) {
      throw new Error("Please enter email");
    }

    const headers = {
      "api-key": import.meta.env.VITE_REFERRAL_API_KEY,
      "Access-Control-Allow-Origin": window.origin,
      "Content-Type": "application/json",
    };

    const body = {};

    for (const [key, value] of Object.entries(args)) {
      if (value) {
        body[key] = value;
      }
    }

    // const gateway = "http://localhost:3000/api";
    const url = new URL(`${gateway}/betaCode/user/create`);
    const req = new Request(url, {
      method: "POST",
      headers,
      mode: "cors",
      body: JSON.stringify(body),
    });

    const createdUser = await fetch(req);

    const user: any = await createdUser.json();
    return user;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getCasperListings() {
  const res = await (
    await fetch(
      "https://us-central1-abiding-arch-334410.cloudfunctions.net/getListings",
    )
  ).json();
  const listings = res.map(
    (listing: {
      id: number;
      owner: string;
      tokenId: number;
      price: number;
      status: string;
      collection: { hash: string };
      nft: {
        id: string;
        tokenId: number;
        metadata: string;
      };
    }) => {
      const metadata = JSON.parse(listing.nft.metadata);
      return {
        listingId: listing.id,
        seller: listing.owner,
        price: listing.price,
        timeAdded: new Date(),
        timeExecuted: new Date(),
        timeCancelled: new Date(),
        buyer: "",
        status: listing.status,
        nftId: 1,
        chainId: 1,
        nft: {
          address: listing.collection.hash,
          metadata: {
            id: 22,
            data: metadata,
            url: metadata.external_link,
          },
          tokenId: listing.tokenId,
          collection: {
            address: listing.collection.hash,
            category: {
              id: 22,
              name: "character",
            },
          },
        },
      };
    },
  );
  return {
    listings,
    count: listings.length,
  };
}
