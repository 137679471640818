<template>
  <div class="rarity-holder">
    <div
      v-for="index in numberDots"
      :key="index"
      class="rarity-circle"
      :class="getClassName(index, rarity)"
    ></div>
  </div>
</template>

<script lang="ts">
export default {
  props: ["rarity"],
  computed: {
    numberDots: function () {
      return this.rarity == "Legendary" ? 6 : 5;
    },
  },
  methods: {
    getClassName(index: number, rarity: string) {
      rarity = rarity.replace(" ", "_").toLowerCase();
      const rank = [
        "common",
        "uncommon",
        "rare",
        "super_rare",
        "epic",
        "legendary",
      ].indexOf(rarity);
      index = index - 1;
      if (index > rank) return " ";
      return rarity.toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.rarity-holder {
  width: 65px;
  height: 10px;
  margin-left: auto !important;
  margin-right: auto;
  margin-top: 2px;
  margin-left: 5px;
}

.rarity-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: linear-gradient(90deg, #2e2e3c 33.58%, #23232f 85.82%);
  float: left;
  margin-right: 3px;
}

.legendary {
  background: linear-gradient(90deg, #fcb819 33.58%, #f99124 85.82%) !important;
  // background: #fcb819 !important;
}

.rare {
  background: linear-gradient(90deg, #960de7 33.58%, #7418f6 85.82%) !important;
}

.common {
  background: linear-gradient(90deg, #00d1e6 33.58%, #00dacb 85.82%) !important;
}

.super_rare {
  background: linear-gradient(90deg, #e15288 33.58%, #d941a9 85.82%) !important;
}

.epic {
  background: linear-gradient(90deg, #f3793d 33.58%, #e4597b 85.82%) !important;
}

.uncommon {
  background: linear-gradient(90deg, #00e4aa 33.58%, #00e999 85.82%) !important;
}
</style>
