import { PresaleV2Abi, PresaleV2Abi__factory } from "@/types";
import { SignerOrProvider } from "@/types/chain";
import { toRaw } from "vue";
import { BaseEvm } from "../evm";

export class PresaleV2 extends BaseEvm<PresaleV2Abi> {
  constructor(presale: string, signerOrProvider: SignerOrProvider) {
    const contract = PresaleV2Abi__factory.connect(
      presale,
      toRaw(signerOrProvider.value)
    );
    super(contract);
  }

  async buy(collection: string, price: number): Promise<void> {
    const signer = super.getSigner();
    const signerAddress = await signer.getAddress();
    this._ethersUtils.checkAddresses([collection, signerAddress]);
    const priceInWei = this._ethersUtils.toWei(price);
    const tx = await this._contract.buy(signerAddress, priceInWei, collection);
    await tx.wait(1);
    return;
  }

  async isWhitelistEnabled(): Promise<boolean> {
    return await this._contract.isWhitelistEnabled();
  }

  async isWhitelisted(wallet?: string): Promise<boolean> {
    if (!wallet && !this._contract.signer._isSigner) {
      throw new Error(`No wallet available`);
    }
    if (!wallet) {
      wallet = await this._contract.signer.getAddress();
    }
    const whitelistedRole = await this._contract.WHITELISTED_ROLE();
    return await this._contract.hasRole(whitelistedRole, wallet);
  }
}
