export const advisors = [
  {
    image: "faouzi.jpg",
    name: "Faouzi",
    title: "Owner DutchGameStudio",
  },
  {
    image: "zowie.jpg",
    name: "Zowie",
    title: "CTO Bux Crypto",
  },
  {
    image: "ian_ferrum.jpeg",
    name: "Ian",
    title: "Ferrum Co-Founder & COO",
  },
  {
    image: "Shashwat.jpeg",
    name: "Shashwat",
    title: "Owner AltcoinBuzz & Partner Enjinstarter",
  },
  {
    image: "anthony.jpg",
    name: "Anthony",
    title: "CMO Vaiot",
  },
  {
    image: "prakash.jpeg",
    name: "Prakash",
    title: "Founder Enjinstarter",
  },
  {
    image: "sandesh.png",
    name: "Sandesh",
    title: "Founder Long-Term Ventures",
  },
];

export const teammembers = [
  {
    image: "leon.png",
    name: "Leon",
    title: "Operational Lead",
    linkedin: "leon-lanen-41929980",
  },
  {
    image: "bram.png",
    name: "Bram",
    title: "Tech Lead",
    linkedin: "bram-hoogenkamp-a51a40b5",
  },
  {
    image: "jesse.png",
    name: "Jesse",
    title: "Full stack developer",
  },
  {
    image: "lars.png",
    name: "Lars",
    title: "Community support",
  },
  {
    image: "huub.png",
    name: "Huub",
    title: "Content Marketeer",
  },
  {
    image: "aykut.png",
    name: "Aykut",
    title: "UX/UI Designer",
  },
  {
    image: "ben.png",
    name: "Ben",
    title: "Marketeer",
  },
  {
    image: "bengt.png",
    name: "Bengt",
    title: "Lead Marketeer",
  },
  {
    image: "reniet.png",
    name: "Reniet",
    title: "Designer",
  },
  {
    image: "kaspars.png",
    name: "Kaspars",
    title: "Full stack Developer",
  },
  {
    image: "dominic.png",
    name: "Dominic",
    title: "Financial Support",
  },
  {
    image: "thomas.png",
    name: "Thomas",
    title: "Full stack developer",
  },
  {
    image: "kimberley.png",
    name: "Kimberley",
    title: "Growth Hacker",
  },
  {
    image: "ron.png",
    name: "Ron",
    title: "Lead Game developer",
  },
];
