import { ethers, utils } from "ethers";
import {
  EChainId,
  EChainType,
  ENetworkType,
  TChain,
  TChaincurrency,
} from "@/types/chain";

export class Chain {
  private _chain: TChain;
  constructor(chainIdentifier: EChainId) {
    const networkType: ENetworkType =
      import.meta.env.VITE_ENV == "dev"
        ? ENetworkType.TESTNET
        : ENetworkType.MAINNET;
    const chain = supportedChains.filter(
      (chain: TChain) =>
        chain.id == chainIdentifier && chain.network == networkType
    )[0];
    if (!chain) {
      throw new Error(`No chain found: ${EChainId[chainIdentifier]}`);
    }
    this._chain = chain;
  }

  getBalance(key: string): Promise<number> {
    throw new Error(`Not implemented`);
  }

  connect(...args: any): void {
    throw new Error(`Not implemented`);
  }

  get id(): EChainId {
    return this._chain.id;
  }

  get type(): EChainType {
    return this._chain.type;
  }

  get chainId(): number {
    return this._chain.chainId;
  }

  get rpcUrl(): string {
    return this._chain.rpcUrl;
  }

  get name(): string {
    return this._chain.name;
  }

  get explorerUrl(): string {
    return this._chain.explorerUrl;
  }

  get currency(): TChaincurrency {
    return this._chain.nativeCurrency;
  }

  get icon(): string {
    return new URL(
      `../assets/chains/${this._chain.nativeCurrency.symbol}.png`,
      import.meta.url
    ).href;
  }

  get marketplaceCurrency(): string {
    return this._chain.marketplaceCurrency;
  }
}

export class EvmChain extends Chain {
  constructor(chainIdentifier: EChainId) {
    super(chainIdentifier);
    // this._web3modal = new Web3Modal({
    //   network: EChainType[this.type],
    //   theme: "dark",
    // });
    if (super.type !== EChainType.EVM) {
      throw new Error(`Non EVM chain given`);
    }
    if (!super.chainId) {
      throw new Error(`chainId should be specified for EVM chain`);
    }
  }

  async getBalance(wallet: string): Promise<number> {
    if (!ethers.utils.isAddress(wallet)) {
      throw new Error(`No valid wallet given`);
    }
    const provider = ethers.providers.getDefaultProvider(this.rpcUrl);
    const balance = await provider.getBalance(wallet);
    return +utils.formatEther(balance);
  }
}

export const supportedChains: Array<TChain> = [
  {
    name: "Binance Smart Chain",
    id: EChainId.BSC,
    network: ENetworkType.MAINNET,
    type: EChainType.EVM,
    chainId: 56,
    rpcUrl: "https://bsc-dataseed1.defibit.io/",
    explorerUrl: "https://bscscan.com",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    marketplaceCurrency: "BTH",
  },
  {
    name: "Binance Smart Chain Testnet",
    id: EChainId.BSC,
    network: ENetworkType.TESTNET,
    type: EChainType.EVM,
    chainId: 97,
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
    explorerUrl: "https://testnet.bscscan.com",
    nativeCurrency: {
      name: "TBNB",
      symbol: "TBNB",
      decimals: 18,
    },
    marketplaceCurrency: "BTH",
  },
  {
    name: "Moonbeam",
    id: EChainId.GLMR,
    network: ENetworkType.MAINNET,
    type: EChainType.EVM,
    chainId: 1284,
    rpcUrl: "https://rpc.api.moonbeam.network",
    explorerUrl: "https://moonscan.io",
    nativeCurrency: {
      name: "Moonbeam",
      symbol: "GLMR",
      decimals: 18,
    },
    marketplaceCurrency: "wGLMR",
  },
  {
    name: "Casper Testnet",
    id: EChainId.CSPR,
    network: ENetworkType.TESTNET,
    type: EChainType.CASPER,
    rpcUrl:
      "https://us-central1-abiding-arch-334410.cloudfunctions.net/cspr_testnet",
    explorerUrl: "https://testnet.cspr.live",
    nativeCurrency: {
      name: "Casper",
      symbol: "CSPR",
      decimals: 9,
    },
    marketplaceCurrency: "CSPR",
  },
  {
    name: "Casper",
    id: EChainId.CSPR,
    network: ENetworkType.MAINNET,
    type: EChainType.CASPER,
    rpcUrl: "https://us-central1-abiding-arch-334410.cloudfunctions.net/cspr",
    explorerUrl: "https://cspr.live",
    nativeCurrency: {
      name: "Casper",
      symbol: "CSPR",
      decimals: 9,
    },
    marketplaceCurrency: "CSPR",
  },
];
