<template>
  <div class="team">
    <h2>The <br /><span class="green robotic">team</span></h2>
    <div class="team-overview team-pictures">
      <TeamMember
        v-for="member in teammembers"
        :key="member.name"
        :member="member"
      />
    </div>
    <h2 style="margin-top: 150px">
      The <br /><span class="green robotic">Advisors & Trustees</span>
    </h2>
    <div class="team-overview">
      <TeamMember
        v-for="member in advisors"
        :key="member.name"
        :member="member"
        :advisor="true"
      />
    </div>
  </div>
</template>
<script>
import TeamMember from "../../components/home/TeamMember.vue";
import {
  teammembers,
  advisors as advisorsConst,
} from "../../constants/Team.ts";

export default {
  mounted() {
    this.teammembers = teammembers;
  },
  data: () => ({
    teammembers: [],
    advisors: advisorsConst,
  }),
  components: {
    TeamMember,
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.team {
  width: calc(100% - 270px);
  height: auto;
  float: left;
  position: relative;
  z-index: 1;
  left: 270px;
  text-align: center;
  padding-top: 75px;

  h2 {
    font-size: 52px;
    line-height: 65px;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    margin-left: 40px;
    font-family: "ProximaNova" !important;
    text-align: center;
  }
  .green {
    color: #63fca3;
    text-shadow: 0px 4px 25px rgba(113, 218, 180, 0.5);
  }

  .robotic {
    font-family: "Press Start 2P", cursive !important;
    font-size: 40px !important;
    line-height: 55px !important;
    display: inline-block;
    margin-left: 5px;
  }

  .team-overview {
    width: 96%;
    margin-left: 4%;
    margin-top: 40px;
    height: auto;
    float: left;

    .circle {
      width: 162px;
      height: 162px;
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      z-index: 10;
      top: 74px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .team-item {
      width: 33%;
      height: 350px;
      float: left;
      position: relative;
      text-align: center;
      margin-bottom: 15px;

      .bg-image {
        position: absolute;
        width: 180px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        top: 19px;
      }

      .title {
        font-family: "ProximaNova";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;
        // color: linear-gradient(180deg, #2F2F42 0%, #717185 100%);
        color: linear-gradient(180deg, #2f2f42 20%, #717185 80%);

        position: absolute;
        left: 0;
        bottom: 40px;
        width: 100%;
        // color: #63FCA3;
        // text-shadow: 0px 4px 25px rgb(113 218 180 / 50%);
      }

      .name {
        font-family: "ProximaNova";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        margin: 0 auto;
        width: 100%;
        float: left;
        position: absolute;
        left: 0;
        bottom: 70px;
      }

      .team-item-info {
        width: 100%;
        height: auto;
        float: left;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .team {
    width: 100%;
    left: 0;
  }
  .team .team-overview {
    width: 100%;
    margin-left: 0;
    margin-left: -20px;
  }

  .team h2 {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 800px) {
  .team {
    margin-top: 100px;
  }

  .team-item {
    width: 100% !important;
  }

  .team,
  .team-overview {
    height: auto !important;
  }

  .team .team-overview {
    margin-left: 0px;
  }
}
</style>
