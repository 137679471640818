<template>
  <div class="chain-holder">
    <div
      v-for="(chain, index) in chains"
      :key="index"
      class="chain"
      @click="handleChainSwitch(chain.id)"
    >
      <img
        :src="getImage('chains', chain.nativeCurrency.symbol)"
        class="chainLogo"
        :class="{ inActive: chainId != chain.id }"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { supportedChains } from "@/chain/chains";
import { onConnect } from "@/chain/wallet";
import { getImage } from "@/plugins";
import { useWalletStore } from "@/store/wallet";
import { EChainId, ENetworkType } from "@/types/chain";
import { storeToRefs } from "pinia";

const walletStore = useWalletStore();
const { chainId } = storeToRefs(walletStore);

const environment =
  import.meta.env.VITE_ENV == "dev"
    ? ENetworkType.TESTNET
    : ENetworkType.MAINNET;
const chains = supportedChains.filter((chain) => chain.network == environment);

function handleChainSwitch(chainId: EChainId) {
  onConnect(chainId);
}
</script>
<style lang="scss" scoped>
.chain-holder {
  height: 60px;
  width: 60px;
  float: left;

  .inActive {
    opacity: 0.5;
    cursor: pointer;
  }
}

.chain {
  height: 30px;
  width: 30px;
  float: left;
  position: relative;

  .chainLogo {
    width: 20px;
    position: absolute;
    top: 5px;
    left: 10px;
    // top: 50%;
    // left: calc(50% + 5px);
    // -ms-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
  }
}
</style>
