<script setup lang="ts">
import { ref } from "vue";
const modalOpen = ref(false);
const screenWidth = window.innerWidth;
</script>

<template>
  <div>
    <div class="grey-overlay" v-if="modalOpen">
      <div class="white-overlay">
        <div class="cross" @click="modalOpen = false"></div>
        <div
          class="wistia_responsive_padding"
          style="padding: 56.25% 0 0 0; position: relative"
        >
          <div
            class="wistia_responsive_wrapper"
            style="
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;
            "
          >
            <div
              class="wistia_embed wistia_async_z9a7b0juy0 videoFoam=true"
              style="height: 100%; position: relative; width: 100%"
            >
              <div
                class="wistia_swatch"
                style="
                  height: 100%;
                  left: 0;
                  opacity: 0;
                  overflow: hidden;
                  position: absolute;
                  top: 0;
                  transition: opacity 200ms;
                  width: 100%;
                "
              >
                <img
                  src="https://fast.wistia.com/embed/medias/z9a7b0juy0/swatch"
                  style="
                    filter: blur(5px);
                    height: 100%;
                    object-fit: contain;
                    width: 100%;
                  "
                  alt=""
                  aria-hidden="true"
                  onload="this.parentNode.style.opacity=1;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="explore">
      <div class="left">
        <h2>
          Explore the
          <span class="robotic"
            >Pixelart
            <span class="green">Hotel!</span>
          </span>
        </h2>
        <p v-if="screenWidth > 800">
          Bit Hotel is a social-first pixel-art game, in which users can hang
          out, compete for leaderboard rewards and earn income. All in-game
          items, such as characters, rooms and furniture,are freely tradeable on
          the web and all have their own unique perks. Sign up now:
        </p>
        <p v-else>
          Bit Hotel is a social-first pixel-art game, in which users can hang
          out, compete for leaderboard rewards and earn income. All in-game
          items, such as characters, rooms and furniture,are freely tradeable on
          the web and all have their own unique perks. Sign up now:
        </p>
        <a
          href="https://play.bithotel.io"
          target="_blank"
          class="buy-button bg-green"
          ><img src="@/assets/pancakeswap.png" />Play Game</a
        >
        <button @click="modalOpen = true" class="bg-purple">
          Watch Trailer
        </button>
      </div>
      <div class="right">
        <div class="circle-holder">
          <router-link to="/buy-characters">
            <div class="big-circle"></div>
            <div class="big-circle-fade"></div>
            <div class="circle-1"></div>
            <div class="circle-2"></div>
            <div class="circle-3"></div>
            <img
              class="crypto_punk"
              style="width: 180px"
              src="@/assets/homepage/Crypto_Punkhead_Homepage.png"
            />
          </router-link>
        </div>
        <!-- <img class="circle-background" src="@/assets/background-pattern.png" />
        <img class="karakter" src="@/assets/karakter.png" /> -->
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes scale05 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

@keyframes scale05Back {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

cssanimation .modalShow {
  display: block !important;
}

.buy-button {
  img {
    width: 20px;
    margin: -4px auto;
    margin-right: 5px;
    filter: brightness(0) invert(1);
  }
}

.grey-overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 555;
  background: rgba(0, 0, 0, 0.25);
  display: block;

  .white-overlay {
    width: 50%;
    height: auto;
    background: #fff;
    border-radius: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5555;
    padding: 25px;

    .cross {
      position: absolute;
      right: 5px;
      top: -10px;
    }

    .cross:after {
      cursor: pointer;
      font-size: 43px;
      display: inline-block;
      content: "\00d7"; /* This will render the 'X' */
    }
  }
}

.explore {
  width: calc(100% - 270px);
  height: 820px;
  float: left;
  position: relative;
  z-index: 1;
  left: 270px;
  top: 0;

  button {
    cursor: pointer;
  }

  .left {
    width: 55%;
    padding-top: 250px;
  }

  .right {
    width: 45%;
  }

  .left,
  .right {
    height: auto;
    float: left;
    text-align: left;

    .crypto_punk {
      width: 280px !important;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      margin-left: 80px;
      margin-top: -118px;
      border-bottom-left-radius: 200px;
      border-bottom-right-radius: 75px;
    }

    .circle-background {
      position: absolute;
      top: 230px;
      right: 10px;
      width: 550px;
    }

    .karakter {
      width: 300px;
      margin-right: -20px;
      position: absolute;
      z-index: 55;
      top: 200px;
      right: 150px;
    }

    button,
    a {
      display: block;
      text-decoration: none;
      float: left;
      width: 200px;
      height: 50px;
      border-radius: 10px;
      font-size: 17px;
      font-family: "ProximaNova" !important;
      text-align: center;
      line-height: 50px;
      color: #fff;
      border: 0;
      margin-top: 30px;
      font-weight: 900;

      animation: scale05Back 400ms ease forwards;

      &:hover {
        animation: scale05 400ms ease forwards;
      }
    }

    .bg-green {
      background: linear-gradient(
        87.46deg,
        #3cc4d7 -3.04%,
        #6df3d3 92.88%,
        #61fea0 172.86%
      );
      box-shadow: 0px 4px 25px rgba(113, 218, 180, 0.5);
      margin-left: 40px;
    }

    .bg-purple {
      background: linear-gradient(
        264.06deg,
        #c63b3b -68.99%,
        #d174e0 40.01%,
        #70baff 130.88%
      );
      box-shadow: 0px 4px 25px rgba(184, 113, 218, 0.5);
      margin-left: 40px;

      animation: scale10Back 400ms ease forwards;

      &:hover {
        animation: scale10 400ms ease forwards;
      }
    }

    h2 {
      font-size: 48px;
      line-height: 65px;
      font-weight: 900;
      color: #fff;
      text-transform: uppercase;
      margin-left: 40px;
      font-family: "ProximaNova" !important;
    }

    .green {
      display: inline-block;
      margin-left: -25px;
      color: #63fca3;
      text-shadow: 0px 4px 25px rgba(113, 218, 180, 0.5);
      text-transform: uppercase;
    }

    .robotic {
      font-family: "Press Start 2P", cursive !important;
      font-size: 40px !important;
      line-height: 30px !important;
      display: inline-block;
      margin-left: 5px;
    }

    p {
      margin-top: 20px;
      font-style: normal;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      color: #fff;
      margin-left: 40px;
      font-family: "ProximaNova" !important;
      width: 70%;
    }
  }

  .circle-holder {
    width: auto;
    height: auto;
    margin-top: 300px;
    margin-left: 80px;
    position: relative;

    .big-circle {
      width: 336px;
      height: 336px;
      border-radius: 50%;
      display: inline-block;
      background: linear-gradient(
        180deg,
        #c63b3b 0%,
        #d174e0 54.53%,
        #70baff 100%
      );
      position: relative;
      z-index: 5;
      overflow: hidden;
    }

    .big-circle-fade {
      width: 432px;
      height: 432px;
      border-radius: 50%;
      display: inline-block;
      background: linear-gradient(
        180deg,
        #c63b3b 0%,
        #d174e0 54.53%,
        #70baff 100%
      );
      opacity: 0.5;
      filter: blur(200px);
      position: absolute;
      top: -20px;
      left: -20px;
      animation: lightup infinite;
      animation-duration: 4s;
    }

    .circle-1 {
      position: absolute;
      top: -80px;
      margin-left: 220px;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      background: linear-gradient(
        180deg,
        #c63b3b 0%,
        #d174e0 54.53%,
        #70baff 100%
      );
    }

    .circle-2 {
      position: absolute;
      top: -20px;
      margin-left: 300px;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background: linear-gradient(
        180deg,
        #c63b3b 0%,
        #d174e0 54.53%,
        #70baff 100%
      );
      filter: blur(10px);
    }

    .circle-3 {
      position: absolute;
      top: 70px;
      margin-left: 360px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: linear-gradient(
        180deg,
        #c63b3b 0%,
        #d174e0 54.53%,
        #70baff 100%
      );
      filter: blur(15px);
    }

    img {
      top: 0;
      left: 0;
      position: absolute;
      z-index: 5;
    }
  }

  @keyframes lightup {
    0% {
      filter: blur(200px);
    }
    50% {
      filter: blur(100px);
      opacity: 0.8;
    }
    100% {
      filter: blur(200px);
    }
  }
}

@media only screen and (max-width: 1024px) {
  .explore {
    width: 98%;
    left: 0;
  }

  .explore .left {
    padding-top: 170px;
  }

  .explore {
    margin-top: 20px;
  }

  // .explore .right {
  //   height: auto !important;
  // }
}

@media only screen and (max-width: 800px) {
  .white-overlay {
    width: 87% !important;
  }

  .explore {
    height: auto !important;
  }

  .explore .left {
    width: 100%;
  }

  .explore .right {
    width: 100%;
    height: auto !important;
  }

  .explore .circle-holder {
    margin-top: 120px;
  }

  .explore .left {
    padding-top: 85px !important;
  }

  .explore .left .robotic {
    font-size: 30px !important;
    line-height: 46px !important;
  }

  .green {
    margin-left: 0px !important;
  }
}
</style>
