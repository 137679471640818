import { EChainId, ENetworkType } from "@/types/chain";

export type CollectionRecord = { [key: string]: string };

export type Collections = {
  [chainId in EChainId]: {
    [networkType in ENetworkType]: {
      characters: CollectionRecord;
      partnershipCharacters: CollectionRecord;
      rooms: CollectionRecord;
    };
  };
};

export const collections: Collections = {
  [EChainId.BSC]: {
    [ENetworkType.MAINNET]: {
      characters: {
        "0x851Fea1272Bf2C47c6b2253B17fb83A40Acca92e": "",
        "0x9F07ca343dee11519522b84Dc97E6271163dC465": "",
        "0xc03358513051dece7441B9c358CAEfdfeE25CDb4": "",
        "0x30A96e1313d7eaAFac2F29E18128F8FF1695e384": "",
        "0xE27CbD36F6F4138a42071341AD00aA7f5cb2a73c": "",
        "0xb95f3d56a3d5E44202DC06647E196460E5E3E644": "",
        "0x9c6f6c6dBb9919aE976ADE3697f229CEcF79c3A5": "",
        "0x3de112038A25c5F29217513C3aAff5F1847e6c9f": "",
        "0x2bEAc18cAabb176C2488F16ee5601ae18bD71144": "",
        "0x1336f49D554A456AdD6bf9Ef2cd8c16Cfc976Ecc": "",
        "0x855eC76e3d9305fE449eD0f116BAa4Cfff8D2bd3": "",
        "0xA4011E3C0D80E152721E477AE387338E6a6C1a98": "",
        "0xd40E468F8860c40577F9F8886bb5cCdB962f75Cd": "",
      },
      partnershipCharacters: {
        "0x90BF5e3622e1597Cde0193daB61b7171be0Fa63F": "",
        "0xb95F99cD13abc6c996B2564A98Ff1DAbFA7a529F": "",
        "0xDB424433FCea817DE4f0Fe13A1f9A4Eb30b9824B": "",
        "0x298fe9972ed99Ca001B7B55A968357773088f71D": "",
        "0xf3E41b0981A317C981fd1e8EaB83E0bAE2e2F9c5": "",
        "0xb5A1C1E28048e6383ff7013eAcD32b5954593EDD": "",
        "0x3116c327aadb1dcfa59f6f8c1d37b9b1a00bf3f4": "",
      },
      rooms: {
        "0xfBF126db4C6232C8255C7cCd3950F4A697275540": "",
        "0xaf70A2557AbEA98Af0Afe8Ed42F9619711B51DDA": "",
        "0xa06C989e4aD52c0039606FA051FDACf17580591F": "",
        "0x69a5f8434DeF56b1f752Ab1680079D1d42379dF0": "",
        "0x536148e5b6caf1D0B5C321CDcD5819054d479c50": "",
        "0x8BFaB7b2031f5ad1D7d4e3b74C1CAB7A476600a0": "",
        "0x34460e3195b61a221a616F904900d48396493aA6": "",
        "0xDa19E9B3Bdd270B4585976c2701eD9Bec490F951": "",
        "0x0336c2A938948C7Ebe8cCbB14328EFDe0663EED5": "",
        "0x211c219E3285CAe380f3827ec543a388B44F1610": "",
        "0xd53dC5198b43F547A6b8e18CF1412859FA7fa975": "",
        "0x38f22551ab0bBDc8602101a34621EDD430e0A2b8": "",
        "0x6aBF540461046130600e660A172708c5FF7a2556": "",
        "0xBbEdaA8b87ABfd054B30E8f5429d00315880E8d9": "",
      },
    },
    [ENetworkType.TESTNET]: {
      characters: {
        "0xc610aF3D83afDF9FDF8dF2a342e551BEc52a8E65": "",
        "0x51656D36E6E43e797eAB0BeC815e0D4cd776034d": "",
        "0xC65a1cb4891fcc058f3fB088a7566c8A07700a87": "",
        "0x7B00e4507a0c053E4ea369e5AeA889c6eed8c066": "",
        "0x187176419701a7F1f343B0DBE9d8a0931603a88d": "",
        "0xd936982c4b9D26D67fe37EFA9cfcC7f347529F28": "",
        "0x6Ca84adD607717F54d3dfa74927dA76908260425": "",
        "0x0e422DF817c39DD3aB7164f0781992Af1a51a045": "",
        "0xbB499417A2246a854e7C931d4d471aD3A5945685": "",
        "0xb69464d7B9EbE1abdB26C66D88DbcEA8f3b18dD9": "",
        "0xcf8EA64EAa19C9EAC788c3186c8c1Db3435789E1": "",
        "0xe5BF8DEdE17bD8EdFB3719A6754f41A85dEE03f3": "",
      },
      partnershipCharacters: {
        "0x90BF5e3622e1597Cde0193daB61b7171be0Fa63F": "",
        "0xb95F99cD13abc6c996B2564A98Ff1DAbFA7a529F": "",
        "0xDB424433FCea817DE4f0Fe13A1f9A4Eb30b9824B": "",
        "0x298fe9972ed99Ca001B7B55A968357773088f71D": "",
        "0xf3E41b0981A317C981fd1e8EaB83E0bAE2e2F9c5": "",
        "0xb5A1C1E28048e6383ff7013eAcD32b5954593EDD": "",
        "0x3116c327aadb1dcfa59f6f8c1d37b9b1a00bf3f4": "",
      },
      rooms: {
        "0x90BF5e3622e1597Cde0193daB61b7171be0Fa63F": "",
        "0xb95F99cD13abc6c996B2564A98Ff1DAbFA7a529F": "",
        "0xDB424433FCea817DE4f0Fe13A1f9A4Eb30b9824B": "",
        "0x298fe9972ed99Ca001B7B55A968357773088f71D": "",
        "0xf3E41b0981A317C981fd1e8EaB83E0bAE2e2F9c5": "",
        "0xb5A1C1E28048e6383ff7013eAcD32b5954593EDD": "",
        "0x3116c327aadb1dcfa59f6f8c1d37b9b1a00bf3f4": "",
      },
    },
  },
  [EChainId.GLMR]: {
    [ENetworkType.TESTNET]: {
      characters: {},
      partnershipCharacters: {},
      rooms: {},
    },
    [ENetworkType.MAINNET]: {
      characters: {
        "0xA315986d8AfFbcBdE04025a5461Dc2da85Ffca0c":
          "https://ipfs.infura.io/ipfs/QmRKH1oFhc4iA7jARvvTSwf5ee6QezLbYkqSrLhyLYSBYy",
      },
      partnershipCharacters: {},
      rooms: {},
    },
  },
  [EChainId.CSPR]: {
    [ENetworkType.TESTNET]: {
      characters: {
        "998af6825d77da15485baf4bb89aeef3f1dfb4a78841d149574b0be694ce4821": "",
      },
      partnershipCharacters: {},
      rooms: {},
    },
    [ENetworkType.MAINNET]: {
      characters: {},
      partnershipCharacters: {},
      rooms: {},
    },
  },
};
