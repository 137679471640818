<template>
  <div class="roadmap-holder">
    <h2 v-if="position == 'page'">Roadmap</h2>
    <div v-if="position == 'home'" class="home">
      <h2>
        Our<br />
        <span class="green robotic">roadmap</span>
      </h2>
    </div>
    <div id="roadmap" ref="roadmap">
      <div class="content-field">
        <div class="v13 horizontal"></div>
        <div class="v14 horizontal"></div>
        <div class="v15 horizontal"></div>
        <div class="dot1 dot"><img src="@/assets/bit-hotel-icon.png" /></div>
        <div class="dot2 dot"><img src="@/assets/bit-hotel-icon.png" /></div>
        <div class="dot3 dot"><img src="@/assets/bit-hotel-icon.png" /></div>
        <div class="dot4 dot"><img src="@/assets/bit-hotel-icon.png" /></div>
        <div class="dot5 dot"><img src="@/assets/bit-hotel-icon.png" /></div>
        <div class="dot6 dot"><img src="@/assets/bit-hotel-icon.png" /></div>
        <div class="dot7 dot"><img src="@/assets/bit-hotel-icon.png" /></div>
        <div class="dot8 dot"><img src="@/assets/bit-hotel-icon.png" /></div>
        <div class="line1 c-line"></div>
        <div class="line2 c-line"></div>
        <div class="line3 c-line"></div>
        <div class="line4 c-line"></div>
        <div class="line5 c-line"></div>
        <div class="line6 c-line"></div>
        <div class="line7 c-line"></div>
        <div class="line8 c-line"></div>
        <div class="center">
          <div class="box1 box">
            <span class="roadmap-title">Q3 2023</span>
            <ul>
              <li>Leveling System</li>
              <li>New Daily Challenges</li>
              <li>Achievements System</li>
            </ul>
          </div>
          <div class="box2 box">
            <span class="roadmap-title">Q4 2023</span>
            <ul>
              <li>Alpha of Gamebuilder</li>
              <li>Governance proposals</li>
              <li>New Mini-games</li>
            </ul>
          </div>
          <div class="box3 box">
            <span class="roadmap-title">Q1 2024</span>
            <ul>
              <li>New L1 Integration</li>
              <li>P2E Guild Onboarding</li>
              <li>Large Tournaments</li>
            </ul>
          </div>
          <div class="box4 box">
            <span class="roadmap-title">Q2 2024</span>
            <ul>
              <li>In-Game voice-chat</li>
              <li>Bit Hotel Hackathons</li>
              <li>Interoperable Triple A Games</li>
            </ul>
          </div>
          <div class="box5 box">
            <span class="roadmap-title">Q3 2024</span>
            <ul>
              <li>Bit Hotel Appchain Live</li>
              <li>More Business-owned Rooms</li>
              <li>Bit Hotel Builder Wars</li>
            </ul>
          </div>

          <div class="box6 box">
            <span class="roadmap-title">Q4 2024</span>
            <ul>
              <li>Bit Hotel at 1M DAU</li>
              <li>Tier 1 CEX Listing</li>
              <li>Private Servers</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: String,
  },
  mounted() {
    if (window.width > 768) {
      this.$refs.roadmap.scroll(1500, 0);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
img {
  display: block;
  margin-left: -25%;
  margin-top: -50%;
  max-width: 150%;
  height: auto;
}
.roadmap-holder {
  width: 100%;
  height: auto;
  float: left;
  position: relative;

  h2 {
    width: calc(100% - 270px);
    float: left;
    margin-top: 80px;
    margin-left: 300px;
    text-align: left;
    font-size: 50px;
    line-height: 65px;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    font-family: "ProximaNova" !important;
  }

  .green {
    color: #63fca3;
    text-shadow: 0px 4px 25px rgba(113, 218, 180, 0.5);
  }

  .robotic {
    font-family: "Press Start 2P", cursive !important;
    font-size: 42px !important;
    line-height: 50px !important;
    display: inline-block;
    margin-left: 5px;
    margin-bottom: -5px;
  }
  .home {
    h2 {
      width: calc(100% - 270px);
      float: left;
      margin-left: 270px;
      text-align: center;
      font-size: 50px;
      line-height: 65px;
      font-weight: 900;
      color: #fff;
      text-transform: uppercase;
      font-family: "ProximaNova" !important;
    }

    .green {
      color: #63fca3;
      text-shadow: 0px 4px 25px rgba(113, 218, 180, 0.5);
    }

    .robotic {
      font-family: "Press Start 2P", cursive !important;
      font-size: 42px !important;
      line-height: 50px !important;
      display: inline-block;
      margin-left: 5px;
      margin-bottom: -5px;
    }
  }
}

#roadmap {
  width: 100%;
  height: 650px;
  margin-top: -50px;
  float: left;
  position: relative;
  z-index: 1;
  text-align: center;
  overflow-x: scroll;

  .content-field {
    width: 100%;
    // width: calc(100% - 270px);
    height: auto;
    float: left;
    margin-left: 270px;
  }
}

.v13 {
  top: 450px;
  left: 0px;
  height: 3px;
  background: linear-gradient(270deg, #2f2f42 43.4%, #717185 100%);
}

.v14 {
  top: 180px;
  left: 0;
  height: 3px;
  background: #262631;
}

.v15 {
  top: 320px;
  left: 0;
  height: 3px;
  background: #262631;
}

.dot {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #cacaca 0%,
    #cacaca 10.42%,
    rgba(92, 92, 112, 0) 88.54%
  );
  position: absolute;
  z-index: 5;
}

.dot1 {
  left: 350px;
  top: 303px;
}

.dot2 {
  left: 650px;
  top: 303px;
}

.dot3 {
  left: 950px;
  top: 303px;
}

.dot4 {
  left: 1250px;
  top: 303px;
}

.dot5 {
  left: 1550px;
  top: 303px;
}

.dot6 {
  left: 1850px;
  top: 303px;
}

.dot7 {
  left: 2150px;
  top: 303px;
}

.dot8 {
  left: 2450px;
  top: 303px;
}

.c-line {
  height: 3px;
  width: 60px;
  background: linear-gradient(270deg, #2f2f42 43.4%, #717185 100%);
  position: absolute;
  transform: rotate(-90deg);
}

.line1 {
  left: 338px;
  top: 290px;
}

.line2 {
  left: 638px;
  top: 350px;
  transform: rotate(90deg);
}

.line3 {
  left: 938px;
  top: 290px;
}

.line4 {
  left: 1238px;
  top: 350px;
  transform: rotate(90deg);
}

.line5 {
  left: 1538px;
  top: 290px;
}
.line6 {
  left: 1838px;
  top: 350px;
}
.line7 {
  left: 2138px;
  top: 290px;
}
.line8 {
  left: 2438px;
  top: 350px;
}
.box {
  width: 220px;
  height: 145px;
  position: absolute;
  background: linear-gradient(90deg, #2e2e3c 33.58%, #23232f 85.82%);
  box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-align: left;

  .roadmap-title {
    font-family: "ProximaNova";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    color: #ffffff;
    display: block;
    float: left;
    margin-top: 20px;
    margin-left: 15px;
  }

  ul {
    width: 100%;
    float: left;
    margin-top: 10px;
    margin-left: 30px;
    color: #fff;

    li {
      margin-top: 10px;
    }
  }

  ul li::before {
    // content: "\2022";
    color: #6df3d3;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

.box1 {
  left: 280px;
  top: 120px;
}

.box2 {
  left: 580px;
  top: 380px;
}

.box3 {
  left: 880px;
  top: 120px;
}

.box4 {
  left: 1180px;
  top: 380px;
  height: 160px;
}

.box5 {
  left: 1480px;
  top: 120px;
}

.box6 {
  left: 1780px;
  top: 380px;
  height: 160px;
}

.box7 {
  left: 2080px;
  top: 120px;
}

.box8 {
  left: 2330px;
  top: 380px;
  height: 160px;
}

.horizontal {
  height: 1px;
  width: 2550px;
  position: absolute;
  left: 0;
  background: #717185;
}

@media only screen and (max-width: 1200px) {
  .box {
    position: relative;
    float: left;
    top: inherit;
    left: inherit;
    margin-top: 40px;
    width: 300px;
  }

  .box1,
  .box3,
  .box5,
  .box7 {
    margin-left: 40px;
  }

  .box2,
  .box4,
  .box6,
  .box8 {
    margin-left: 20px;
  }

  .dot,
  .c-line {
    display: none;
  }

  .center {
    width: 700px;
    height: auto;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1024px) {
  #roadmap .content-field {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .roadmap-holder h2 {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 800px) {
  #roadmap {
    height: auto;
    min-height: 550px;
    margin-bottom: 80px;
    margin-top: 40px;
  }
  .roadmap-holder .home h2 {
    width: 100%;
    margin-left: 0;
  }
  .roadmap-holder h2 {
    margin-left: 40px;
    margin-top: 40px;
  }

  .horizontal,
  .center {
    width: 100%;
  }
}
</style>
