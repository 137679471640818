import { Erc20Abi, Erc20Abi__factory } from "@/types";
import { SignerOrProvider } from "@/types/chain";
import { utils } from "ethers";
import { toRaw } from "vue";
import { Token } from ".";
import { BaseEvm } from "../evm";

export class EVMToken extends BaseEvm<Erc20Abi> implements Token {
  constructor(token: string, signerOrProvider: SignerOrProvider) {
    const contract = Erc20Abi__factory.connect(
      token,
      toRaw(signerOrProvider.value)
    );
    super(contract);
  }

  async getAllowence(spender: string, wallet?: string): Promise<number> {
    if (!wallet && !this.getSigner()._isSigner) {
      throw new Error(`No address available`);
    }
    let owner: string;
    if (wallet) {
      owner = wallet;
    } else {
      owner = await this.getSigner().getAddress();
    }
    this._ethersUtils.checkAddresses([owner, spender]);
    const approvedAmount = await this._contract.allowance(owner, spender);
    return +utils.formatEther(approvedAmount);
  }

  async approve(spender: string, amount: number): Promise<void> {
    if (amount <= 0) {
      throw new Error(`Amount should be positive`);
    }
    const signer = super.getSigner();
    const signerAddress = await signer.getAddress();
    this._ethersUtils.checkAddresses([signerAddress, spender]);
    const weiAmount = this._ethersUtils.toWei(amount);
    const approvedWei = await this._contract.allowance(signerAddress, spender);
    if (approvedWei.gte(weiAmount)) return;
    const tx = await this._contract.approve(spender, weiAmount);
    await tx.wait(1);
    return;
  }

  async getBalance(owner?: string): Promise<number> {
    if (!owner && !super.getSigner()._isSigner) {
      throw new Error(`No signer available`);
    }
    if (owner) {
      return +(await this._contract.balanceOf(owner));
    }
    const signerAddress = await super.getSigner().getAddress();
    const balanceInWei = await this._contract.balanceOf(signerAddress);
    return this._ethersUtils.fromWei(balanceInWei);
  }
}
