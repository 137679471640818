<template>
  <div class="home">
    <Navigation class="navigation" />
    <ConnectWallet />
    <HomeContentOne />
    <HomeContentTwo />
    <Partners />
    <Minting />
    <Roadmap position="home" id="road-map" name="road-map"/>
    <Team />
    <Footer />

    <!-- Upper lines -->
    <div class="v1 vertical"></div>
    <div class="v2 vertical"></div>
    <div class="v3 vertical"></div>
    <div class="v4 horizontal"></div>
    <div class="v5 horizontal"></div>
    <div class="v6 horizontal"></div>
    <div class="v7 horizontal"></div>
    <div class="v8 horizontal"></div>
    <div class="v9 horizontal"></div>
    <div class="v10 vertical"></div>
    <div class="v11 vertical"></div>
    <div class="v12 vertical"></div>

    <!-- Roadmap -->
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/base/Navigation.vue";
import ConnectWallet from "@/components/base/ConnectWallet.vue";
import HomeContentOne from "@/components/home/HomeContentOne.vue";
import HomeContentTwo from "@/components/home/HomeContentTwo.vue";
import Partners from "@/components/home/Partners.vue";
import Minting from "@/components/home/Minting.vue";
import Roadmap from "@/components/Roadmap.vue";
import Team from "@/components/home/Team.vue";
import Footer from "@/components/base/Footer.vue";

export default {
  name: "Home",
  components: {
    Navigation,
    ConnectWallet,
    HomeContentOne,
    HomeContentTwo,
    Partners,
    Minting,
    Roadmap,
    Team,
    Footer,
  },
};
</script>

<style lang="scss">
.home {
  overflow-x: hidden !important;
  overflow-y: hidden;
  height: auto;
  width: 100vw;
  position: relative;
}

.vertical {
  height: 1700px;
  width: 1px;
  position: absolute;
  top: 0;
  background: #717185;
  opacity: 0.3;
}

.v1 {
  left: 920px;
}

.v2 {
  left: 1020px;
}

.v3 {
  left: 1120px;
}

.horizontal {
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  background: #717185;
  opacity: 0.3;
}

.v4 {
  top: 420px;
}

.v5 {
  top: 520px;
}

.v6 {
  top: 620px;
}

.v7 {
  top: 920px;
}

.v8 {
  top: 1020px;
}

.v9 {
  top: 1120px;
}

.v10 {
  top: 920px;
  left: 400px;
  height: 780px;
}

.v11 {
  top: 920px;
  left: 500px;
  height: 780px;
}

.v12 {
  top: 920px;
  left: 600px;
  height: 780px;
}
</style>
