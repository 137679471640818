import { toRaw } from "vue";
import { BaseNFT } from ".";
import { BithotelNftAbi, BithotelNftAbi__factory } from "@/types";
import { SignerOrProvider } from "@/types/chain";
import { EthersUtils } from "..";
import { BaseEvm } from "../evm";

export class EVMNFT extends BaseEvm<BithotelNftAbi> implements BaseNFT {
  constructor(nft: string, signerOrProvider: SignerOrProvider) {
    const contract = BithotelNftAbi__factory.connect(
      nft,
      toRaw(signerOrProvider.value),
    );

    super(contract);
  }

  async transfer(tokenId: number, recipient: string): Promise<string> {
    if (tokenId < 0) {
      throw new Error(`tokenId cannot be negative`);
    }
    if (!this.getSigner()._isSigner) {
      throw new Error(`No signer provided`);
    }
    const ownerAddress = await this.getSigner().getAddress();
    this._ethersUtils.checkAddresses([ownerAddress, recipient]);
    const tx = await this._contract[
      "safeTransferFrom(address,address,uint256)"
    ](ownerAddress, recipient, tokenId);
    await tx.wait(1);
    return tx.hash;
  }

  async getTotalSupply(): Promise<number> {
    return +(await this._contract.totalSupply());
  }

  async balanceOf(wallet: string): Promise<number> {
    return +(await this._contract.balanceOf(wallet));
  }

  async exists(tokenId: number): Promise<boolean> {
    return await this._contract.exists(tokenId);
  }

  async ownerOf(tokenId: number): Promise<string> {
    const exists = this.exists(tokenId);
    if (!exists) {
      throw new Error(`tokenId does not exist`);
    }
    return await this._contract.ownerOf(tokenId);
  }

  async tokenURI(tokenId: number): Promise<string> {
    return await this._contract.tokenURI(tokenId);
  }

  async getTokenIdByIndex(wallet: string, index: number): Promise<number> {
    return +(await this._contract.tokenOfOwnerByIndex(wallet, index));
  }

  async getApproved(tokenId: number): Promise<string> {
    return await this._contract.getApproved(tokenId);
  }

  async approve(tokenId: number, spender: string): Promise<string> {
    const tx = await this._contract.approve(spender, tokenId);
    await tx.wait(1);
    return tx.hash;
  }

  static async getNftBalances(
    collections: Array<string>,
    wallet: string,
    signerOrProvider: SignerOrProvider,
  ) {
    const _ethersUtils = new EthersUtils();
    _ethersUtils.checkAddresses(collections);
    const balances: Array<{
      address: string;
      amount: number;
    }> = [];
    const amountPromises: Array<Promise<number>> = [];
    for (let i = 0; i < collections.length; i++) {
      const collectionAddress = collections[i];
      const collectionContract = new EVMNFT(
        collectionAddress,
        signerOrProvider,
      );
      amountPromises.push(collectionContract.balanceOf(wallet));
    }
    const amounts = await Promise.all(amountPromises);

    for (let i = 0; i < collections.length; i++) {
      balances.push({ address: collections[i], amount: amounts[i] });
    }
    return balances;
  }
}
