import { defineStore } from "pinia";
import { reactive, ref } from "vue";

export const useStore = defineStore(
  "store",
  () => {
    const auth = ref<boolean>(true);
    const getAuth = () => auth;
    const submittedMail = ref<boolean>(true);
    const getSubmittedEmail = () => submittedMail;
    const currentRoom = reactive<any>({ id: null });
    const clickId = ref<string>("");
    const getClickId = () => clickId;

    const login = () => (payload) => {
      auth.value = payload.psw;
    };
    const setCurrentRoom = (_room) => {
      if (currentRoom.id == _room.id) {
        Object.assign(currentRoom, { id: "" });
      } else {
        Object.assign(currentRoom, _room);
      }
    };
    const setSubmittedMail = (_mailEntered: boolean) => {
      submittedMail.value = _mailEntered;
    };
    const setClickId = (_clickId: string) => {
      clickId.value = _clickId;
    };

    return {
      getAuth,
      login,
      currentRoom,
      getClickId,
      getSubmittedEmail,
      setClickId,
      setCurrentRoom,
      setSubmittedMail,
    };
  },
  {
    persist: true,
  }
);
